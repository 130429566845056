import { useTranslation } from "react-i18next";
import { useUser } from "../../context/UserContext/UserContext";
import { UserRelated } from "../../context/UserContext/UserTypes";
import { AgreementSection } from "../../navigation/pages/LandingPage.wrapper";
import { FirstName } from "../../utils/SplitName";
import Icon, { IconEnum } from "../Icon";
import Mailto from "../Mailto";

interface AgreementProps {
  childMainUser: UserRelated;
}

const MissingConsent = ({ childMainUser }: AgreementProps) => {
  const [t] = useTranslation("common");

  const {parent} = useUser();

  return (
    <AgreementSection>
      <Icon
        iconName={IconEnum.bell}
        className="homeAuth__icon"
        withIconClass={false}
      />
      <h2 className="homeAuth__missingAgreementTitle">
        {t("missingConsentTitle")}
      </h2>
      <p className="homeAuth__p homeAuth__p--spacing">
        {t("missingConsentDesc")}
      </p>
      <Mailto
        withIcon={false}
        subject={t("childSendEmailSubject")}
        body={t("childSendEmailBody", {
          childName: FirstName(childMainUser.name),
          parentName: FirstName(parent?.name as string),
          url: window.location.origin,
        })}
      >
        {<p className="landingPage__link ">{t("homeResendInstructions")}</p>}
      </Mailto>
    </AgreementSection>
  );
};

export default MissingConsent;
