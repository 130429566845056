import {
  ABOUT,
  CHILD_ASSENT_PARENT_VIEW,
  CHILD_ASSENT_CHILD_VIEW,
  CHILD_FLOW_INTRO,
  CHILD_LEARN,
  CHOOSECHILD,
  FLOW_EXPLAINED,
  FLOW_INTRO,
  FLOW_NEEDED,
  PARENT_CONSENT_PARENT_VIEW,
  PARENT_LEARN,
  PARENT_STOP,
  PDF,
  PHN_PARENT_VIEW,
  PHN_PARENT,
  PARENT_CONSENT_CHILD_VIEW,
  PHN_CHILD,
  PHN_CHILD_VIEW,
  REVIEW_AGREEMENTS_ASSENT,
  REVIEW_AGREEMENTS_CONSENT,
  REVIEW_AGREEMENTS_PHN,
  CHOOSE_PARENT,
  FLOW_EXPLAINED_ADULT_AND_BABY,
  FLOW_NEEDED_ADULT,
  ADULT_CONSENT,
  REVIEW_AGREEMENTS_ADULT_CONSENT,
  PHN_ADULT,
  REVIEW_AGREEMENTS_PHN_ADULT_INFO,
  REVIEW_AGREEMENTS_PHN_PARENT_INFO,
  REVIEW_AGREEMENTS_PHN_CHILD_INFO,
} from "../navigation/utils/RouteConstants";
import flow_intro from "../images/flow/flow_intro.png";
import flow_needed from "../images/flow/flow_needed.png";
import flow_explained from "../images/flow/flow_explained.png";
import child_assent from "../images/flow/child_assent.png";
import parent_consent from "../images/flow/parent_consent.png";
import phn from "../images/flow/phn.png";

// Take in Pathname, and return a Image URL
const HeaderImageByPath = (pathname: string) => {
  let pathToImage;

  switch (pathname) {
    case CHOOSECHILD:
      pathToImage = flow_intro;
      break;
    case FLOW_INTRO:
      pathToImage = flow_intro;
      break;
    case CHILD_FLOW_INTRO:
      pathToImage = flow_intro;
      break;
    case FLOW_EXPLAINED:
      pathToImage = flow_explained;
      break;
    case FLOW_EXPLAINED_ADULT_AND_BABY:
      pathToImage = flow_explained;
      break;
    case FLOW_NEEDED:
      pathToImage = flow_needed;
      break;
    case FLOW_NEEDED_ADULT:
      pathToImage = flow_needed;
      break;
    case CHILD_ASSENT_PARENT_VIEW:
      pathToImage = child_assent;
      break;
    case CHILD_ASSENT_CHILD_VIEW:
      pathToImage = child_assent;
      break;
    case PARENT_CONSENT_PARENT_VIEW:
      pathToImage = parent_consent;
      break;
    case PARENT_CONSENT_CHILD_VIEW:
      pathToImage = parent_consent;
      break;
    case ADULT_CONSENT:
      pathToImage = parent_consent;
      break;
    case PHN_PARENT:
      pathToImage = phn;
      break;
    case PHN_ADULT:
      pathToImage = phn;
      break;
    case PHN_CHILD:
      pathToImage = phn;
      break;
    case PHN_PARENT_VIEW:
      pathToImage = phn;
      break;
    case PHN_CHILD_VIEW:
      pathToImage = phn;
      break;
    case PARENT_LEARN:
      pathToImage = flow_explained;
      break;
    case CHILD_LEARN:
      pathToImage = flow_explained;
      break;
    case ABOUT:
      pathToImage = flow_intro;
      break;
    case PARENT_STOP:
      pathToImage = flow_intro;
      break;
    case PDF:
      pathToImage = parent_consent;
      break;
    case REVIEW_AGREEMENTS_ASSENT:
      pathToImage = flow_intro;
      break;
    case REVIEW_AGREEMENTS_CONSENT:
      pathToImage = parent_consent;
      break;
    case REVIEW_AGREEMENTS_ADULT_CONSENT:
      pathToImage = parent_consent;
      break;
    case REVIEW_AGREEMENTS_PHN:
      pathToImage = phn;
      break;
    case REVIEW_AGREEMENTS_PHN_ADULT_INFO:
      pathToImage = phn;
      break;
    case REVIEW_AGREEMENTS_PHN_PARENT_INFO:
      pathToImage = phn;
      break;
    case REVIEW_AGREEMENTS_PHN_CHILD_INFO:
      pathToImage = phn;
      break;
    case CHOOSE_PARENT:
      pathToImage = flow_intro;
      break;
  }
  
  return pathToImage;
};

export default HeaderImageByPath;
