import { useTranslation } from "react-i18next";
import Icon, { IconEnum } from "../components/Icon";
import Modal, { BaseModalProps } from "./Modal";
import Button from "../components/Button";

interface AssentPendingProps extends BaseModalProps {
  onClickHandler: () => void;
}

const AssentPending = ({ open, setOpen, onClickHandler }: AssentPendingProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(false);
    onClickHandler();
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Content>
        <Icon iconName={IconEnum.hands} />
        <Modal.Title>{t("consentPendingTitle")}</Modal.Title>
        <Modal.Desc>
          <p className="flowPage__p">{t("assentPendingDesc")}</p>
        </Modal.Desc>
      </Modal.Content>
      <Modal.BtnContainer>
        <Button onClick={() => handleClick()} className="btn">
          {t("continue")}
        </Button>
      </Modal.BtnContainer>
    </Modal>
  );
};

export default AssentPending;
