import React from "react";
import beakerSVG from "../images/icons/icon-beaker.svg";
import handsSVG from "../images/icons/icon-hands.svg";
import documentSVG from "../images/icons/icon-document.svg";
import phoneSVG from "../images/icons/icon-phone.svg";
import researchSVG from "../images/icons/icon-research.svg";
import bellSVG from "../images/icons/icon-bell.svg";
import closeSVG from "../images/icons/icon-close.svg";
import checkSVG from "../images/icons/icon-check.svg";
import mailSVG from "../images/icons/icon-mail.svg";
import downloadSVG from "../images/icons/icon-download.svg";
import logoutSVG from "../images/icons/icon-logout.svg";
import SVG from "react-inlinesvg";

export enum IconEnum {
  hands = "hands",
  beaker = "beaker",
  phone = "phone",
  document = "document",
  research = "research",
  bell = "bell",
  close = "close",
  check = "check",
  empty = "empty",
  mail = "mail",
  download = "download",
  logout = "logout",
}

interface IconProps {
  iconName: IconEnum;
  withIconClass?: boolean;
  withSizeConstraint?: boolean;
  hideIcon?: boolean;
  className?: string;
}

const Icon = ({
  iconName,
  withIconClass = true,
  withSizeConstraint = false,
  hideIcon = false,
  className = ""
}: IconProps) => {
  let SVGHolder = <SVG src={bellSVG} />;

  switch (iconName) {
    case IconEnum.hands:
      SVGHolder = <SVG src={handsSVG} />;
      break;
    case IconEnum.beaker:
      SVGHolder = <SVG src={beakerSVG} />;
      break;
    case IconEnum.document:
      SVGHolder = <SVG src={documentSVG} />;
      break;
    case IconEnum.phone:
      SVGHolder = <SVG src={phoneSVG} />;
      break;
    case IconEnum.research:
      SVGHolder = <SVG src={researchSVG} />;
      break;
    case IconEnum.bell:
      SVGHolder = <SVG src={bellSVG} />;
      break;
    case IconEnum.close:
      SVGHolder = <SVG src={closeSVG} />;
      break;
    case IconEnum.check:
      SVGHolder = <SVG src={checkSVG} />;
      break;
    case IconEnum.mail:
      SVGHolder = <SVG src={mailSVG} />;
      break;
    case IconEnum.download:
      SVGHolder = <SVG src={downloadSVG} />;
      break;
    case IconEnum.logout:
      SVGHolder = <SVG src={logoutSVG} />;
      break;
  }

  return (
    <span
      className={`${className} ${withIconClass ? "icon" : ""} 
      ${withSizeConstraint ? "icon--sizeConstraint" : ""}
      ${hideIcon ? "icon--hidden" : ""}  `}
    >
      {SVGHolder}
    </span>
  );
};

export default Icon;
