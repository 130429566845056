import { Page, Text, View, Image } from "@react-pdf/renderer";
import { TFunction, Trans, useTranslation } from "react-i18next";

import header_phn from "../../images/flow/phn.png";
import { ContactUs } from "./PDFDocument";
import { styles } from "./Styles";

interface BulletItemProps {
  children: string;
  t: TFunction<"common">;
}

const BulletItem = ({ children, t }: BulletItemProps) => (
  <View style={styles.listItem}>
    <Text>{t("bullet")}</Text>
    <Text style={[styles.bulletItem, styles.pPolicy]}>
      <Trans
        components={{
          strong: <Text style={styles.bold} />,
        }}
      >
        {children}
      </Trans>
    </Text>
  </View>
);

const PhnPage = () => {
  const { t } = useTranslation();
  return (
    <Page wrap={false} size="LETTER" style={styles.page}>
    <View>
      <Image src={header_phn} style={styles.headerImg} />
    </View>
    <View style={styles.body}>
      {/* Title */}
      <Text style={styles.title}>{t("phnAdultTitle")}</Text>
      {/* Policy Block */}
  
      <Text style={styles.pPolicy}>{t("phnAdultDesc1")}</Text>
      <Text style={styles.pPolicy}>{t("phnAdultDesc2")}</Text>
      <Text style={styles.pPolicy}>{t("phnParentDesc3")}</Text>
      <Text style={styles.pPolicy}>{t("phnAdultDesc4")}</Text>
      <Text style={styles.pPolicy}>
        <Trans
          components={{
            strong: <Text style={styles.bold} />,
          }}
        >
          {t("phnParentDesc5")}
        </Trans>
      </Text>
      <BulletItem t={t}>{t("phnAdultDesc6")}</BulletItem>
      <BulletItem t={t}>{t("phnAdultDesc7")}</BulletItem>
    </View>
    <ContactUs t={t} />
  </Page>
  );
};

export default PhnPage;
