import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import Mailto from "../../../components/Mailto";
import { useUser } from "../../../context/UserContext/UserContext";
import { getAllRelatedParents } from "../../../services/FilterParent";
import { caIntroGoToChooseParent, caIntroGoToMailRoute, caIntroGoToTogetherRoute } from "../../routing/Flow/childIntro.logic";
import FlowPage, { Body, BtnContainer, Desc, Header, Title } from "../FlowPage.wrapper";


const ConsentAssentIntroChild = () => {
  const { t } = useTranslation();
  const { setMailRefer, parent, children, userDetails } = useUser();

  const history = useHistory();

  function handleNext() {
    setMailRefer(false);

    const parentCount = getAllRelatedParents(userDetails).length;

    if (parentCount > 1) {
      caIntroGoToChooseParent(history);
    } else {
      caIntroGoToTogetherRoute(history);
    }
  }

  const mailChildren = () => {
    setMailRefer(true);

    caIntroGoToMailRoute(history);
  };

  useEffect(() => {
    setMailRefer(false);
  }, [setMailRefer]);

  // Child And Parent name

  const childName = children.length && children[0].name;
  const parentName = parent?.name;

  return (
    <FlowPage pageName={ConsentAssentIntroChild.name}>
      <Header />
      <Body>
        <Title>{t("caIntroTitle")}</Title>
        <Desc>
          <p className="flowPage__p">{t("caIntroChildDesc1")}</p>
          <p className="flowPage__p">
            <Trans>{t("caIntroChildDesc2")}</Trans>
          </p>
        </Desc>
        <BtnContainer>
          <Button className="btn" onClick={handleNext}>
            {t("togetherWithFamily")}
          </Button>
          <Mailto
            onClick={mailChildren}
            className="btn btn--primary"
            subject={t("childSendEmailSubject")}
            body={t("childSendEmailBody", { parentName, childName, url: window.location.origin })}
            withCenter={true}
          >
            {t("mailToParent")}
          </Mailto>
        </BtnContainer>
      </Body>
    </FlowPage>
  );
};

export default ConsentAssentIntroChild;
