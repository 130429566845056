import { useContext, createContext, useMemo, useCallback } from "react";

import { useActor, useInterpret } from "@xstate/react";

import { createMachine, DoneInvokeEvent } from "xstate";
import { hasAgreementExpired } from "../../../../utils/formatTime";
import { UserTypes } from "../../../../context/UserContext/UserTypes";

type AppInitializeEvent = { type: "INITIALIZE_CONDITIONS"; user: UserTypes };
type StopParticipatingEvent = { type: "RESET" };

type AppEvent =
  | AppInitializeEvent
  | StopParticipatingEvent
  | DoneInvokeEvent<any>;

export const adultPatientDashboardMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEEIFcA2AXAIgQ1gAsAjAezwCcIBhUgOywtIwzAoDoBLOzrTvDJwBeYAMQBJAHLiAKuOQAZcQC0AogH1qAeUk5Z4nQGVEoAA6lYvTvRMgAHogC0AJgAsAdnYBGAGw-XrgCsrgDMPgAc4SEADO4ANCAAnk6+PuzRzl4hkQCcztERzj4hAL4lCaiYuAQk5FS0DEwsbOzUlBCwogBKqoaqMrbmlnw2SPYpXjnsOa4R7oHZISHu0VkJyQiOrgXe7nmBbv577qVlCXSkEHC2ldj4RGTtDYzMrBzcVgLCYIMWVqOgBybEI5TyzaKBHzOfLQnxedzxJJOELOdiuZzuZzhQKBGY41bYs4gW7VB51Gj0F7NDhtKiwdgAMUwGGQUAoYDAAFswAxfsNrHRbECvJD2JjAr5AuEodFoijnOsnPlwmiMr4cgdQc4wkSSfdak9KU03q12vSALKcWCWOhQVnsrk8rB8-6CsbCwLRNFYryuHKy2LhVyKzbQ9iZbHRbE+HLhaIBdy69B3GqPepG14tWkddiqOymTjsiD2jnc3ljIauoWILza8M5PLa8IrZy4qMhrJedgLUExbE5YoeRPlYnJ0kG9ONTM0s0ukZuwGIBv1xvZFtt8IhlzhLvbTIxzWYnUjvWp8nPY1sOcC6ubdG7nwQqEworwxEbLZ7Xb7Q4eXtlMogA */
  createMachine(
    {
  tsTypes: {} as import("./AdultPatientCardController.typegen").Typegen0,
  schema: {
    context: {},
    events: {} as AppEvent,
    actions: {} as
      | AppInitializeEvent
      | StopParticipatingEvent
      | DoneInvokeEvent<UserTypes>,
  },
  id: "AdultDashboardController",
  initial: "initialize",
  states: {
    initialize: {
      on: {
        INITIALIZE_CONDITIONS: [
          {
            cond: "FULL_AGREEMENT",
            target: "#AdultDashboardController.Cards.FullAgreement",
          },
          {
            cond: "EXPIRED_AGREEMENT",
            target: "#AdultDashboardController.Cards.ExpiredAgreement",
          },
          {
            target: "#AdultDashboardController.Cards.MissingAgreement",
          },
        ],
      },
    },
    Cards: {
      initial: "FullAgreement",
      states: {
        FullAgreement: {},
        MissingAgreement: {},
        ExpiredAgreement: {},
      },
      on: {
        RESET: {
          target: "#AdultDashboardController.initialize",
        },
      },
    },
  },
},
    {
      guards: {
        FULL_AGREEMENT: (_, event: AppInitializeEvent) => {
          console.log(event.user.agreementDate, "AGREEMENT_DATE");
          return (
            event.user.consent &&
            !hasAgreementExpired(event.user.agreementDate, event.user.name)
          );
        },
        EXPIRED_AGREEMENT: (_, event: AppInitializeEvent) =>
          (event.user.consent &&
            hasAgreementExpired(
              event.user.agreementDate,
              event.user.name
            )) as any,
      },
    }
  );

interface AdultPatientCardControllerContextDefaults {
  isFullAgreement: boolean | undefined;
  isExpiredAgreement: boolean | undefined;
  isMissingAgreement: boolean | undefined;
  initializeAdultPatientCards: (user: UserTypes) => void;
  resetState: () => void;
}

export const appCtxDefaultValue: AdultPatientCardControllerContextDefaults = {
  isFullAgreement: undefined,
  isExpiredAgreement: undefined,
  isMissingAgreement: undefined,
  initializeAdultPatientCards: (user: UserTypes) => {},
  resetState: () => {},
};

const AdultPatientContext = createContext<AdultPatientCardControllerContextDefaults>(appCtxDefaultValue);

function useAdultPatientCardController() {
  return useContext(AdultPatientContext);
}

interface UserProviderProps {
  children: React.ReactNode;
}

function AdultPatientCardProvider(props: UserProviderProps) {
  const service = useInterpret(adultPatientDashboardMachine, { devTools: true});

  const [state, send] = useActor(service);

  const initializeAdultPatientCards = useCallback(
    (user: UserTypes) =>   send({
      type: "INITIALIZE_CONDITIONS",
      user
    }),
    [send]
  );

  const resetState = useCallback(
    () =>   send({
      type: "RESET"
    }),
    [send]
  );

  // Memoized state management, used to reduce unnecessary state updates
  const providerValue = useMemo(
    () => ({
      initializeAdultPatientCards,
      resetState,
      isFullAgreement: state.matches("Cards.FullAgreement"),
      isExpiredAgreement: state.matches("Cards.ExpiredAgreement"),
      isMissingAgreement: state.matches("Cards.MissingAgreement")
    }),
    [initializeAdultPatientCards, resetState, state]
  );

  return <AdultPatientContext.Provider value={providerValue} {...props} />;
}
export { AdultPatientCardProvider, useAdultPatientCardController };
