import { RouteComponentProps } from "react-router";
import { RouteTypes } from "../../types/RouteTypes";
import { HOME, PHN_CHILD_VIEW, PHN_PARENT_VIEW } from "../../utils/RouteConstants";

export const phnParentController = (
  types: RouteTypes,
  history: RouteComponentProps["history"]
) => {
  if(types.d_child) history.push(PHN_PARENT_VIEW);
  else if (types.i_child) history.push(PHN_CHILD_VIEW);
  else if (types.d_baby) history.push(PHN_PARENT_VIEW);
  else if (types.d_missingConsent) history.push(PHN_PARENT_VIEW);
  else if (types.d_mailChild) history.push(PHN_PARENT_VIEW);
  else if(types.i_adult) history.push(PHN_CHILD_VIEW);

  else history.push(HOME)


};
