import { useEffect } from "react";


// Context
import { useUser } from "../context/UserContext/UserContext";

//Routing Logic
import { UserRole, UserTypes } from "../context/UserContext/UserTypes";
import { CookieStorage } from "../components/services/Storage";
import { getConsentedParent, getFirstFoundParent } from "../services/FilterParent";
import { getAllChildren, getFirstRelatedChild } from "../services/FilterChildren";
import { useErrorHandler } from "react-error-boundary";
import { checkWithBaby, isAdultPatient, isBaby } from "../utils/handleChildAge";
import queryString from "query-string";

// Machines
import { useFetchUser } from "../context/UserContext/UserFetchContext";
import useRouteConditions from "./useRouteConditions";
import { useLocation } from "react-router-dom";

const useUserService = () => {
  const handleError = useErrorHandler();

  const { hash } = useLocation();
  const { token: hashToken } = queryString.parse(hash);

  if (typeof hashToken === "string") {
    CookieStorage.set("token", hashToken);
  }

  const token = hashToken ? hashToken : CookieStorage.get("token");

  const [routeConditions] = useRouteConditions();

  const { setUserDetails, setChildren, setParent, userDetails, setUserLoaded, setPhnAgreement } = useUser();

  const { error, fetchUserDetails, isFetchError, fetchedUser, fetchedPhn, isFetchSuccessful, isUserSettingState } = useFetchUser();

  const isDoingFlow = routeConditions?.isAdultDoingFlow || routeConditions?.isChildDoingFlow || routeConditions?.isParentDoingFlow

  useEffect(() => {
    fetchUserDetails(token as string);
  }, [fetchUserDetails, token]);

  useEffect(() => {
    if (isFetchError && error) {
      handleError(error);
    }

    if (fetchedUser === undefined) {
      return;
    }

    if (isBaby(fetchedUser.age) && fetchedUser.role === UserRole.PATIENT) {
      handleError({message: "Error: Baby user not handled through app"})
    }

    if (isFetchSuccessful) {
      setUserDetails(fetchedUser);
      setPhnAgreement(fetchedPhn);
      setChildren(getAllChildren(fetchedUser));
      setUserLoaded(true);
    }
  }, [
    setUserDetails,
    setPhnAgreement,
    setUserLoaded,
    setChildren,
    handleError,
    error,
    fetchedUser,
    fetchedPhn,
    isFetchError,
    isFetchSuccessful,
  ]);


  const isFullAgreement = (user: UserTypes) => {
    const childRelatedUser = getFirstRelatedChild(user);
    let fullAgreement;

    if (user.role === UserRole.PARENT) {
      fullAgreement = childRelatedUser.consent && checkWithBaby(childRelatedUser.assent, childRelatedUser.age);
    } else {
      fullAgreement = user.consent && checkWithBaby(user.assent, user.age);
    }

    return fullAgreement;
  };

  const isRejectedAgreement = (user: UserTypes) => {
    const childRelatedUser = getFirstRelatedChild(user);
    let rejectedAgreement;

    if (user.role === UserRole.PARENT) {
      rejectedAgreement = !childRelatedUser.consent || !checkWithBaby(childRelatedUser.assent, childRelatedUser.age);
    } else {
      rejectedAgreement = !user.consent || !checkWithBaby(user.assent, user.age);
    }

    return rejectedAgreement;
  };

  // Initialize Parent
  useEffect(() => {
    if (isUserSettingState) {
      // might have to re-write this.

      // ! Dashboard
      // ? Skip setParent if adult-patient
      if (isAdultPatient(userDetails.age, userDetails.role)) {
        setParent(undefined);
      }

      // ? 1. Parent & Child full agreement on dashboard should send consentedParent
      else if (!isDoingFlow && isFullAgreement(userDetails)) {
        setParent(getConsentedParent(userDetails));
      }

      // ? 2. Parent & Child rejected agreement on dashboard should now be firstFoundParent - consent, assent: false
      else if (!isDoingFlow && isRejectedAgreement(userDetails)) {
        setParent(getFirstFoundParent(userDetails));
      }

      // ---- Partial Agreements
      // ? 3. When Child finishes alone and is on dashboard. Should send getFirstFoundParent

      // ? 4. When Parent finishes alone and i ons dashboard. Send back getFirstFoundParent
      // ! Flow ... else ->?
      // ? 5. Parent user is DoingFlow getFirstFoundParent.
      // ? 5. Child User is DoingFlow get getFirstFoundParent.
      else if (isDoingFlow) {
        setParent(getFirstFoundParent(userDetails));
      } else {
        handleError({ message: "Parent missing" });
        // console.log(parent, "parent");
      }
    }
  }, [userDetails, setParent, isDoingFlow, handleError, isUserSettingState]);

};

export default useUserService;
