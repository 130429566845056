import { RouteComponentProps } from "react-router";

// Controllers

// Constants
import { routeLogicByPath } from "./routeLogicByPath";

/*
RouteControl Hook
This is where all the routing actions are being executed.
We use the unique routeScenario set from the routeScenario hooks to establish which route to hit next
dependant on the current path the user is in.

routeLogicByPath is a function that takes in the current path and the routeScenario that is used in
the specific page. Since routeScenario constantly changes as you traverse from page component to other
page components, we must get that specific routeScenario in time.

The RoutingLogic can be found in every page component inside their Controller folder. 
*/

export enum routeScenarioEnum {
  d_child = "d_child",
  d_mailChild = "d_mailChild",
  d_baby = "d_baby",
  d_missingConsent = "d_missingConsent",
  i_child = "i_child",
  i_missingAssent = "i_missingAssent",
  i_mailParent = "i_mailParent",
  i_adult = "i_adult",

  none = "",
}

const useRouteControl = (history: RouteComponentProps["history"]) => {
  let handleRoute;

  const routeTypes = (routeScenario: routeScenarioEnum) => ({
    d_child: routeScenario === routeScenarioEnum.d_child, // parent together with child
    d_mailChild: routeScenario === routeScenarioEnum.d_mailChild, // parent mail child
    d_baby: routeScenario === routeScenarioEnum.d_baby, // parent together with baby
    d_missingConsent: routeScenario === routeScenarioEnum.d_missingConsent, // parent needs to finish missing Consent
    i_child: routeScenario === routeScenarioEnum.i_child, // child together with parent
    i_mailParent: routeScenario === routeScenarioEnum.i_mailParent, // child mail parent
    i_missingAssent: routeScenario === routeScenarioEnum.i_missingAssent, // child needs to finish missing Assent
    i_adult: routeScenario === routeScenarioEnum.i_adult, // child needs to finish missing Assent
  });

  /**
   * Call handleRoute to control the screen
   * Pushes a page to another depending on the routeScenario set by the routeConditions
   * payload is anything you need to carry from the page into its controller.
   * i.e. children count data
   */
  handleRoute = (routeScenario: routeScenarioEnum) => routeLogicByPath(history.location.pathname, routeScenario, routeTypes, history);

  return [handleRoute];
};

export default useRouteControl;
