import { useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  updateAgreedBefore,
  updatePhnAgreement,
} from "../../../../API/Endpoints";
import Button from "../../../../components/Button";
import { CookieStorage } from "../../../../components/services/Storage";
import { useRoute } from "../../../../context/RouteContext/RouteContext";
import { useUser } from "../../../../context/UserContext/UserContext";
import { PhnConsentState } from "../../../../context/UserContext/UserTypes";
import useRouteControl from "../../../../hooks/RouteController/useRouteController";
import { useLeadingDebounce } from "../../../../hooks/utils/useDebounce";
import PhnNotLinked from "../../../../modals/PhnNotLinked";
import { DASHBOARD } from "../../../utils/RouteConstants";
import FlowPage, {
  Body,
  Title,
  Desc,
  AlertContainer,
  EmailAlert,
  BtnContainer,
  Header,
  ContactUs,
} from "../../FlowPage.wrapper";

const PhnAdult = () => {
  const [dontLinkPhnsModal, setDontLinkPhnsModal] = useState(false);
  const [phnRequestLoading, setPhnRequestLoading] = useState(false);

  const { t } = useTranslation();
  const { routeScenario } = useRoute();
  const { userDetails } = useUser();
  const history = useHistory();
  const handleError = useErrorHandler();

  const [handleRoute] = useRouteControl(history);

  // Button Fns

  const handleOnClickAgree = () => {
    handleRoute(routeScenario);
  };

  const handleOnClickDisagree = () => {
    setPhnRequestLoading(true);

    // Call endpoints
    updateAgreedBefore(
      true,
      CookieStorage.get("token") as string,
      userDetails
    );

    updatePhnAgreement(CookieStorage.get("token") as string, {
      performers: [userDetails.identifier],
      phn_consent_state: PhnConsentState.REJECTED,
      phn: null,
    }).then(() => {
      setPhnRequestLoading(false);

      setDontLinkPhnsModal(true);
    }).catch((error) => {
      handleError(error);
    })
  };

  // Modal Fns

  const handleDisagreeNextPage = () => {
    // done doing flow, redirect to dashboard
    history.push(DASHBOARD);
  };

  return (
    <FlowPage loading={phnRequestLoading} anchorButtonGroup={false} pageName={PhnAdult.name}>
      <Header />
      <Body>
        <Title>{t("phnAdultTitle")}</Title>
        <Desc>
          <p className="flowPage__p">{t("phnAdultDesc1")}</p>
          <p className="flowPage__p">{t("phnAdultDesc2")}</p>
          <p className="flowPage__p">{t("phnParentDesc3")}</p>
          <p className="flowPage__p">{t("phnAdultDesc4")}</p>
          <p className="flowPage__p">
            <strong><Trans>{t("phnAdultDesc8")}</Trans></strong>
          </p>
          <ul>
            <li className="flowPage__p flowPage__bulletItems">{t("phnAdultDesc6")}</li>
            <li className="flowPage__p flowPage__bulletItems">{t("phnAdultDesc7")}</li>
         </ul>
          <ContactUs textLeft />
          <AlertContainer>{t("phnAdultAlert")}</AlertContainer>
          <EmailAlert />
        </Desc>
        <BtnContainer reverse>
          <Button className="btn" onClick={useLeadingDebounce(handleOnClickAgree)}>
            {t("phnParentAgree")}
          </Button>
          <Button className="btn" onClick={useLeadingDebounce(handleOnClickDisagree)}>
            {t("phnParentDisagree")}
          </Button>
        </BtnContainer>
      </Body>
      <PhnNotLinked
        open={dontLinkPhnsModal}
        setOpen={setDontLinkPhnsModal}
        onClickHandler={handleDisagreeNextPage}
      />
    </FlowPage>
  );
};

export default PhnAdult;
