import {
    useContext,
    createContext,
    useMemo,
    useCallback,
  } from "react";
  import { PhnAgreementType, UserTypes } from "./UserTypes";
  
  import {  useActor, useInterpret } from "@xstate/react";
  import { userMachine } from "../../machines/FetchUserMachine";
import { AppError } from "../../components/ErrorUI/types/ErrorType";
  
interface UserFetchContextDefaults {
    isUserReady: boolean | undefined;
    isUserSettingState: boolean | undefined;
    isFetchSuccessful: boolean | undefined;
    isFetchError: boolean | undefined;
    error: AppError | undefined;
    fetchedUser: UserTypes | undefined;
    fetchedPhn: PhnAgreementType | undefined;
    fetchUserDetails: (token: string) => void;
    refreshUserDetails: (token: string) => void;
  }

  export const appCtxDefaultValue: UserFetchContextDefaults = {
    isUserReady: undefined,
    isUserSettingState: undefined,
    isFetchSuccessful: undefined,
    isFetchError: undefined,
    error: undefined,
    fetchedUser: undefined,
    fetchedPhn: undefined,
    fetchUserDetails: (token: string) => {},
    refreshUserDetails: (token: string) => {}
  };
  

  const UserContext = createContext<UserFetchContextDefaults>(appCtxDefaultValue);
  
  function useFetchUser() {
    return useContext(UserContext);
  }
  
  interface UserProviderProps {
    children: React.ReactNode;
  }
  
  function UserFetchProvider(props: UserProviderProps) {  
    const service = useInterpret(userMachine, { devTools: true});

    const [state, send] = useActor(service)
  
    const fetchUserDetails = useCallback((token: string) => {
      send({
        type: "FETCH",
        token
      });
    }, [send]);

    const refreshUserDetails = useCallback((token: string) => {
      send({
        type: "REFRESH_USER",
        token
      });
    }, [send]);
  
    // Memoized state management, used to reduce unnecessary state updates
    const providerValue = useMemo(
      () => ({
        fetchUserDetails,
        refreshUserDetails,
        isUserReady: state.matches("success.ready"),
        isUserSettingState: state.matches("success.settingData"),
        isFetchSuccessful: state.matches("success"),
        isFetchError: state.matches("failure"),
        error: state.context.error?.response?.data || {message: "No ErrorCode. Uncaught UI Error"},
        fetchedUser: state.context.user,
        fetchedPhn: state.context.phn,
      }),
      [
        fetchUserDetails,
        refreshUserDetails,
        state
      ]
    );
  
    return <UserContext.Provider value={providerValue} {...props} />;
  }
  export { UserFetchProvider, useFetchUser };
  