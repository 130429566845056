import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import { IconEnum } from "../../../components/Icon";
import { useRoute } from "../../../context/RouteContext/RouteContext";
import useRouteControl, {
  routeScenarioEnum,
} from "../../../hooks/RouteController/useRouteController";
import FlowPage, {
  Header,
  Body,
  Title,
  Desc,
  BtnContainer,
  ProgressBar,
  CaptionContainer,
} from "../FlowPage.wrapper";

const ConsentAssentExplained = () => {
  const { t } = useTranslation();
  const { routeScenario } = useRoute();
  const history = useHistory();

  const [handleRoute] = useRouteControl(history);

  const handleNext = () => {
    handleRoute(routeScenario as routeScenarioEnum);
  };

  const goBack = () => {
    history.goBack();
  }

  return (
    <FlowPage pageName={ConsentAssentExplained.name}>
    <Header />
    <ProgressBar level={1} description={t("progressBar1")} icon={IconEnum.beaker} />
      <Body>
        <Title>{t("caExplainedTitle")}</Title>
        <Desc>
          <p className="flowPage__p">{t("caExplainedDesc1")}</p>
          <p className="flowPage__p">
            <Trans>{t("caExplainedDesc2")}</Trans>
          </p>
          <p className="flowPage__p">{t("caExplainedDesc3")}</p>
        </Desc>
        <CaptionContainer>
          <p className="flowPage__caption">{t("caExplainedCaption")}</p>
        </CaptionContainer>
        <BtnContainer reverse>
          <Button 
            className="btn" 
            onClick={handleNext}
          >{t("next")}
          </Button>
          <Button 
            className="btn btn--secondary"
            onClick={goBack}
          >{t("back")}
          </Button>
        </BtnContainer>
      </Body>
    </FlowPage>
  );
};

export default ConsentAssentExplained;
