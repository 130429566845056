import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button";
import { IconEnum } from "../../../../components/Icon";
import { useRoute } from "../../../../context/RouteContext/RouteContext";
import useRouteControl, { routeScenarioEnum } from "../../../../hooks/RouteController/useRouteController";
import FlowPage, { ProgressBar, Body, Title, Desc, CaptionContainer, BtnContainer, Header } from "../../FlowPage.wrapper";
import { DASHBOARD } from '../../../utils/RouteConstants'

const ConsentAssentExplainedAdult = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { routeScenario } = useRoute();
  const [handleRoute] = useRouteControl(history);


  const handleNext = () => {
    handleRoute(routeScenario);
  };


  const goBack = () => {
    history.push(DASHBOARD)
  }

  const Desc1 = () => {
    switch(routeScenario){
      case routeScenarioEnum.d_baby:
        return t("caExplainedDesc1")
      default:
        return t("caExplainedDesc1-adult");
    }
  }
  const _Title = () => {
    switch(routeScenario){
      case routeScenarioEnum.d_baby:
        return t("caExplainedTitle")
      default:
        return t("caExplainedTitle-adult");
    }
  }

  return (
    <FlowPage pageName={ConsentAssentExplainedAdult.name}>
    <Header />
    <ProgressBar level={1} description={t("progressBar1")} icon={IconEnum.beaker} />
      <Body>
        <Title>{_Title()}</Title>
        <Desc>
          <p className="flowPage__p">{Desc1()}</p>
          <p className="flowPage__p">
            <Trans>{t("caExplainedDesc2")}</Trans>
          </p>
          <p className="flowPage__p">{t("caExplainedDesc3")}</p>
        </Desc>
        <CaptionContainer>
          <p className="flowPage__caption">{t("caExplainedCaption")}</p>
        </CaptionContainer>
        <BtnContainer reverse>
          <Button 
            className="btn" 
            onClick={handleNext}
          >{t("next")}
          </Button>

          <Button 
            className="btn btn--secondary"
            onClick={goBack}
          >{t("cancel")}
          </Button>
        </BtnContainer>
      </Body>
    </FlowPage>
  );
};

export default ConsentAssentExplainedAdult;
