import { useHistory } from "react-router-dom";
import { updateAgreement } from "../../../../API/Endpoints";
import { CookieStorage } from "../../../../components/services/Storage";
import { useRoute } from "../../../../context/RouteContext/RouteContext";
import { useUser } from "../../../../context/UserContext/UserContext";
import {
  PhnConsentState,
  UserTypes,
} from "../../../../context/UserContext/UserTypes";
import useRouteControl, {
  routeScenarioEnum,
} from "../../../../hooks/RouteController/useRouteController";
import { isBaby } from "../../../../utils/handleChildAge";
import { handleChildrenParticipation } from "../../../../utils/handleParticipation";
import { isAllChildrenAssentTrue } from "../../../../utils/hasAllChildrenAssented";
import { getDateNow } from "../../../../utils/TimeStampInSeconds";
import { DASHBOARD } from "../../../utils/RouteConstants";
import ParentConsent from "./ParentConsent";

const ParentConsentParentView = () => {
  const { routeScenario } = useRoute();
  const history = useHistory();
  const {
    children,
    setChildren,
    userDetails,
    setUserDetails,
    parent,
    phnAgreement,
  } = useUser();

  const [handleRoute] = useRouteControl(history);

  // Main Fns

  const handleOnClickAgree = () => {
    updateAgreedUponTimeForParent(userDetails);
    return updateConsentPerChild(true);
  };

  const handleAgreeNextPage = () => {
    UserDoneConsentCheckNextRoute();
  };

  const handleOnClickDisagree = () => {
    const { promise } = handleChildrenParticipation(
      children,
      userDetails,
      children,
      userDetails.identifier
    );
    return promise;
  };

  const handleDisagreeNextPage = () => {
    history.push(DASHBOARD);
  };

  // Helper Fns
  const UserDoneConsentCheckNextRoute = () => {
    switch (routeScenario) {
      case routeScenarioEnum.d_child:
        return checkBabyOrChildrenNoAssent();
      case routeScenarioEnum.d_missingConsent:
        return handleRejectedPhnState();
      default:
        return handleRoute(routeScenario);
    }
  };

  const handleRejectedPhnState = () => {
    if (phnAgreement.phn_consent_state === PhnConsentState.REJECTED) {
      history.push(DASHBOARD);
    } else {
      handleRoute(routeScenario);
    }
  };

  const checkBabyOrChildrenNoAssent = () => {
    // TODO: Have to re-factor this logic for baby later
    const isThisChildaBaby = children.some((child) => isBaby(child.age));

    if (isThisChildaBaby) {
      handleRoute(routeScenario);
    } else if (!isAllChildrenAssentTrue(children)) {
      history.push(DASHBOARD);
    } else {
      handleRoute(routeScenario);
    }
  };

  // TODO: Must remake this and check against phn-agreement obj
  // const checkPhnOnChildrenThenRoute = () => {
  //   const doAnyChildNotHavePHN = children.some((child) => child.phn === "" || child.phn === null);

  //   handleRoute(routeScenario);
  // };

  const updateConsentPerChild = (consent: boolean) => {
    const childrenHolder = children;
    childrenHolder.forEach((child) => {
      child.consent = consent;

      if (parent) {
        child.consentBy = [parent.identifier];
      }
    });
    setChildren(childrenHolder);
    return updateAgreement(
      CookieStorage.get("token") as string,
      userDetails,
      childrenHolder
    );
    // return useUpdateConsentQuery(
    //   SessionStorage.get("token") as string,
    //   userDetails,
    //   childrenHolder
    // );
  };

  const updateAgreedUponTimeForParent = (parent: UserTypes) => {
    const parentHolder = parent;
    parentHolder.hasAgreedBefore = true;
    parentHolder.agreementDate = getDateNow();

    setUserDetails(parentHolder);
  };

  return (
    <ParentConsent
      handleOnClickAgree={handleOnClickAgree}
      handleOnClickDisagree={handleOnClickDisagree}
      handleAgreeNextPage={handleAgreeNextPage}
      handleDisagreeNextPage={handleDisagreeNextPage}
    />
  );
};

export default ParentConsentParentView;
