import { UserRelated, UserRole, UserTypes } from "../context/UserContext/UserTypes";
import { getAllRelatedChildren } from "./FilterChildren";

/**
 * Returns first found Parent given a userDetails payload
 */
export const getFirstFoundParent = (user: UserTypes) => {
  if (user.role === UserRole.PARENT) {
    return user;
  } else {
    // if this parent is not a main user
    // returns back first parent found in related array
    return user.related.filter((userRelated) => userRelated.role === UserRole.PARENT)[0];
  }
};

/**
 * Returns a consented parent if the user hasAgreed(true) before.
 * Falls back if the user hasn't agreed before (false).
 */
export const getConsentedParent = (user: UserTypes) => {
  // need these conditions to only to target dashboard
  if (user.role === UserRole.PATIENT) {
    return getParentByConsentedByID(user.consentBy[0], user);
  } else{
    // return the parent who consented
    // need to find child who has consentBy id on
    const children = getAllRelatedChildren(user);

    // find first child with consent true
    const child = children.find((child) => child.consent);

    const consentByID = child?.consentBy[0];
    return getParentByConsentedByID(consentByID as string, user);
  }
};

/**
 * Returns all parent users in related array.
 */
export const getAllRelatedParents = (user: UserTypes) => {
  return user.related.filter((userRelated) => userRelated.role === UserRole.PARENT);
};

/**
 * getParent by ConsentedBy Id
 */
export const getParentByConsentedByID = (consentedByIdentifier: string, user: UserTypes) => {
  if (user.role === UserRole.PATIENT) {
    return user.related.filter(
      (userRelated) => userRelated.role === UserRole.PARENT && userRelated.identifier === consentedByIdentifier
    )[0];
  } else if (user.role === UserRole.PARENT) {
    if (user.identifier === consentedByIdentifier) {
      return user;
    } else {
      return user.related.filter(
        (userRelated) => userRelated.role === UserRole.PARENT && userRelated.identifier === consentedByIdentifier
      )[0];
    }
  } else {
    return undefined;
  }
};

/**
 * This method assumes you know which parent has consented
 * @param user is the userDetails of the parent who loggedIn
 * @param consentedParent is the parent state of the consentedParent (use parent/setParent)
 * @returns 
 */
export const isLoggedInParentConsenter = (user: UserTypes, consentedParent: UserTypes | UserRelated | undefined) => {
  if (consentedParent === undefined) {
    return undefined;
  }

  if (user.role === UserRole.PATIENT){
    return true;
  }

  return user.identifier === consentedParent.identifier;
};
