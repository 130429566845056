import React, { Suspense, useEffect } from "react";
import "./styles/App.scss";
import App from "./App";
import Spinner from "./components/Spinner";
import { UserProvider } from "./context/UserContext/UserContext";
import { RouteProvider } from "./context/RouteContext/RouteContext";
import { UtilProvider } from "./context/UtilContext/UtilContext";
import { BrowserRouter as Router } from "react-router-dom";

//internationalization
import "./i18n/i18n";
import { ErrorBoundary } from "react-error-boundary";
import { UserFetchProvider } from "./context/UserContext/UserFetchContext";
import ErrorHandler from "./components/ErrorUI/ErrorHandler";
// import { inspect } from "@xstate/inspect";

interface SetupProps {
  children?: React.ReactNode;
}

declare global {
  interface Window {
    config: any;
  }
}

// adds a custom height for css to process, fixes button box whitespace issues
const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)

// disables all console.logs in production
process.env.NODE_ENV === "production" && (console.log = () => {});

const Setup = ({ children }: SetupProps) => {

  useEffect(() => {
    appHeight();
  }, [])

  return (
    <Suspense fallback={<Spinner />}>
      <UtilProvider>
        <UserFetchProvider>
          <UserProvider>
            <RouteProvider>
              <Router>
                <ErrorBoundary FallbackComponent={({error}) => <ErrorHandler error={error} />}>
                  <App />
                  {children}
                </ErrorBoundary>
              </Router>
            </RouteProvider>
          </UserProvider>
        </UserFetchProvider>
      </UtilProvider>
    </Suspense>
  );
};

export default Setup;
