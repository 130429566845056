import { useTranslation } from "react-i18next";
import Modal, { BaseModalProps } from "./Modal";
import Button from "../components/Button";

interface ParentStopModalProps extends BaseModalProps {
  onClickHandler: () => void;
  childName: string;
}

const ChildStop = ({ open, setOpen, onClickHandler, childName }: ParentStopModalProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(false);
    onClickHandler();
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Content>
        <Modal.Title>{t("ChildStopModalTitle")}</Modal.Title>
        <Modal.Desc>
          <p className="flowPage__p">{t("ChildStopModalDesc1")}</p>
          <p className="flowPage__p">{t("ChildStopModalDesc2")}</p>
        </Modal.Desc>
      </Modal.Content>
      <Modal.BtnContainer reverse>
        <Button onClick={() => handleClick()} className="btn">
          {t("ParentStopModalBtn-Primary")}
        </Button>
        <Button onClick={() => setOpen(false)} className="btn btn--secondary">
          {t("ParentStopModalBtn-Secondary")}
        </Button>
      </Modal.BtnContainer>
    </Modal>
  );
};

export default ChildStop;
