import CircularProgress from "@material-ui/core/CircularProgress";

interface SpinnerImageProps {
  loading?: boolean;
}

const SpinnerImage = ({ loading = false }: SpinnerImageProps) => {
  if(loading) return null;

  return (
      <CircularProgress className="spinner__img" size={50} />
  );
};

export default SpinnerImage;
