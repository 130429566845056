import { Link } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types/style";
import React from "react";
import { useUtil } from "../context/UtilContext/UtilContext";
import TrackAnalytics from "./Analytics/TrackAnalytics";
import Icon, { IconEnum } from "./Icon";

interface MailTypes {
  email?: string;
  subject: string;
  body: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  children?: React.ReactNode;
  withCenter?: boolean;
  withIcon?: boolean;
  forPDF?: boolean;
  PDFStyle?: Style | Style[];
}

const Mailto = ({
  email = "",
  subject,
  body,
  className = "",
  onClick,
  children,
  withCenter = false,
  withIcon = true,
  forPDF = false,
  PDFStyle = undefined
}: MailTypes) => {
  const { pageName } = useUtil();

  let params = "?"
  params += `subject=${encodeURIComponent(subject)}`;
  params += `&body=${encodeURIComponent(body)}`;

  const overrideOnClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (onClick) {
      onClick(e);
    }

    TrackAnalytics("click", `${children}`, pageName.current);

    window.open(`mailto:${params}`)
  };


  if (forPDF) {
    return <Link style={PDFStyle} src={`mailto:${email}${params}`}>{children}</Link>;
  } else {
    return (
      <a
        data-testid="mailto"
        className={`${withCenter ? "mail" : ""} ${className} `}
        onClick={overrideOnClick}
        href={`mailto:${email}${params}`}
      >
        {children}
        {withIcon && (
          <Icon
            className={`${withCenter ? "mail-icon" : ""}`}
            iconName={IconEnum.mail}
          />
        )}
      </a>
    );
  }
};

export default Mailto;
