import { useContext, useState, createContext, useMemo } from "react";
import { routeScenarioEnum } from "../../hooks/RouteController/useRouteController";

export interface RouteContextDefaults {
  routeScenario: routeScenarioEnum;
  setRouteScenario: (routeScenario: routeScenarioEnum) => void;
  skipFlow: undefined | boolean;
  setSkipFlow: (skipFlow: boolean) => void;
  isRouteScenariosReady: boolean;
  setIsRouteScenariosReady: (isRouteScenariosReady: boolean) => void;
  isRouteConditionsReady: boolean;
  setIsRouteConditionsReady: (isRouteConditionsReady: boolean) => void;
}

const appCtxDefaultValue: RouteContextDefaults = {
  routeScenario: routeScenarioEnum.none,
  setRouteScenario: (routeScenario: routeScenarioEnum) => {
    // This is intentional, using fn body to build type
  },
  skipFlow: undefined,
  setSkipFlow: (skipFlow: boolean) => {},
  isRouteScenariosReady: false,
  setIsRouteScenariosReady: (isRouteConditionsReady: boolean) => {
    // This is intentional, using fn body to build type
  },
  isRouteConditionsReady: false,
  setIsRouteConditionsReady: (isRouteConditionsReady: boolean) => {
    // This is intentional, using fn body to build type
  },
};

const RouteContext = createContext(appCtxDefaultValue);

function useRoute() {
  return useContext(RouteContext);
}

interface RouteProviderProps {
  children: React.ReactNode;
}

function RouteProvider(props: RouteProviderProps) {
  const [routeScenario, setRouteScenario] = useState(appCtxDefaultValue.routeScenario);
  const [skipFlow, setSkipFlow] = useState<boolean | undefined>(undefined);
  const [isRouteScenariosReady, setIsRouteScenariosReady] = useState(false);
  const [isRouteConditionsReady, setIsRouteConditionsReady] = useState(appCtxDefaultValue.isRouteConditionsReady);

  // Memoized state management, used to reduce unnecessary state updates
  const providerValue = useMemo(
    () => ({
      routeScenario,
      setRouteScenario,
      skipFlow,
      setSkipFlow,
      isRouteScenariosReady,
      setIsRouteScenariosReady,
      isRouteConditionsReady,
      setIsRouteConditionsReady,
    }),
    [
      routeScenario,
      setRouteScenario,
      skipFlow,
      setSkipFlow,
      isRouteConditionsReady,
      setIsRouteConditionsReady,
      isRouteScenariosReady,
      setIsRouteScenariosReady,
    ]
  );

  return <RouteContext.Provider value={providerValue} {...props} />;
}
export { RouteProvider, useRoute };
