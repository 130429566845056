import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button";
import { REVIEW_AGREEMENTS_PHN } from "../../../utils/RouteConstants";
import FlowPage, {
  Body,
  Title,
  Desc,
  AlertContainer,
  AcceptAlertContainer,
  BtnContainer,
  Header,
  ContactUs,
} from "../../FlowPage.wrapper";

const ReviewAgreementsPhnParentInfo = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleNext = () => {
    history.push(REVIEW_AGREEMENTS_PHN);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <FlowPage pageName={ReviewAgreementsPhnParentInfo.name}>
      <Header />
      <Body>
        <AcceptAlertContainer>
          {t("reviewAgreementAlertText")}
        </AcceptAlertContainer>
        <Desc>
          <Title>{t("phnParentTitle")}</Title>
          <p className="flowPage__p">{t("phnParentDesc1")}</p>
          <p className="flowPage__p">{t("phnParentDesc2")}</p>
          <p className="flowPage__p">{t("phnParentDesc3")}</p>
          <p className="flowPage__p">{t("phnParentDesc4")}</p>
          <p className="flowPage__p">
            <Trans>{t("phnParentDesc5")}</Trans>
          </p>
          <ul>
            <li className="flowPage__p flowPage__bulletItems">{t("phnParentDesc6")}</li>
            <li className="flowPage__p flowPage__bulletItems">{t("phnParentDesc7")}</li>
         </ul>
          <ContactUs textLeft />
          <AlertContainer>{t("phnParentAlert")}</AlertContainer>
        </Desc>
        <BtnContainer reverse>
          <Button className="btn" onClick={() => handleNext()}>
            {t("reviewAgreementBtnPrimary")}
          </Button>
          <Button className="btn" onClick={() => handleGoBack()}>
            {t("reviewAgreementBtnSecondary")}
          </Button>
        </BtnContainer>
      </Body>
    </FlowPage>
  );
};

export default ReviewAgreementsPhnParentInfo;
