import React, { Dispatch, SetStateAction, SyntheticEvent } from "react";
import MaterialModal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Icon, { IconEnum } from "../components/Icon";

export interface BaseModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface ModalWrapperProps extends BaseModalProps {
  children?: React.ReactNode;
  hideBackdrop?: boolean;
}

const Modal = ({ open, setOpen, children, hideBackdrop = false }: ModalWrapperProps) => {
  return (
    <div>
      <MaterialModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={open}
        closeAfterTransition
        hideBackdrop={hideBackdrop ? true : false}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modal__inner">{children}</div>
        </Fade>
      </MaterialModal>
    </div>
  );
};

interface ModalAttributeProps {
  children: React.ReactNode;
  className?: string;
  reverse?: boolean;
  textLeft?: boolean;
}

interface ContentProps extends ModalAttributeProps {
  scrollbox?: boolean;
}

Modal.Content = ({ children, scrollbox = false, textLeft = true }: ContentProps) => {
  const scrollBoxClassName = scrollbox ? "scrollbox" : "";
  const textLeftClassName = textLeft ? "modal__content--left" : "";

  return <div className={`modal__content ${scrollBoxClassName} ${textLeftClassName} translatedLineBreak`}>{children}</div>;
};

Modal.Title = ({ children }: ModalAttributeProps) => <h2 className={`modal__title`}>{children}</h2>;

Modal.Desc = ({ children, textLeft = true }: ModalAttributeProps) => (
  <div className={`modal__desc ${textLeft ? "modal__desc--left" : ""} translatedLineBreak`}>{children}</div>
);

/**
 * Button group sub component that handles how a button should behave when grouped with others.
 * @children ReactNode, place button components inside here.
 * @returns JSX.Element
 */
Modal.BtnContainer = ({ children, reverse, scrollbox }: ContentProps) => {
  const childrenArray = children as React.ReactNode[];
  const BtnCount = childrenArray.length;
  const singleBtnClass = BtnCount === 2 ? "" : "modal__btnContainer--singleBtn ";
  const extraSpacingTop = scrollbox ? "modal__btnContainer--spacingTop" : "";

  return (
    <div
      className={` modal__btnContainer ${reverse ? "modal__btnContainer--reverse" : ""}
      ${singleBtnClass}
      ${extraSpacingTop}`}
    >
      {children}
    </div>
  );
};

interface ModalCloseProps {
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
}

Modal.Close = ({ onClick }: ModalCloseProps) => (
  <div className={`btn__closeContainer`}>
    {
      <button onClick={onClick} className="btn--close icon-icon_close" aria-label="close dialog">
        <Icon iconName={IconEnum.close} />
      </button>
    }
  </div>
);

export default Modal;
