import { UserRole, UserTypes } from "../context/UserContext/UserTypes";

/**
 * Returns first found Parent given a userDetails payload
 */
export const getAllChildren = (user: UserTypes) => {
  if (user.role === UserRole.PARENT) {
    // return the related array filtered to find children
    return getAllRelatedChildren(user);
  } else {
    // if the patient is a main user
    // return the array of UserTypes, and filter inside the related array to get each children there.

    const relatedArray = getAllRelatedChildren(user);

    return [user, ...relatedArray]
  }
};

/**
 * Returns all children patients found in the related array of the UserTypes payload
 */
export const getAllRelatedChildren = (user: UserTypes) => {
  return user.related.filter((relatedUser) => relatedUser.role === UserRole.PATIENT);
};

/**
 * Returns first child patient found in the related array of the UserTypes payload
 */
export const getFirstRelatedChild = (user: UserTypes) => {
  return getAllRelatedChildren(user)[0];
}