import Cookies from "js-cookie";

// cookie expires attrib takes in fractional values
const EXPIRE_IN_ONE_HOUR = 7;

const CookieStorage = {
  set: function (key: string, value: string) {
    Cookies.set(key, value, {
      path: "/",
      sameSite: "Strict",
      expires: EXPIRE_IN_ONE_HOUR,
      secure: true,
    });
  },
  get: function (key: string) {
   return Cookies.get(key);
  },
  remove: function (key: string) {
    Cookies.remove(key, { path: "/" });
  },
};

const SessionStorage = {
  set: function (key: string, value: string) {
    sessionStorage.setItem(key, value);
  },
  get: function (key: string) {
    return sessionStorage.getItem(key);
  },
  remove: function (key: string) {
    sessionStorage.removeItem(key);
  },
};





export { CookieStorage, SessionStorage };
