import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { updatePhnAgreement } from "../../../API/Endpoints";
import Button from "../../../components/Button";
import { CookieStorage } from "../../../components/services/Storage";
import { useRoute } from "../../../context/RouteContext/RouteContext";
import { useUser } from "../../../context/UserContext/UserContext";
import { PhnConsentState } from "../../../context/UserContext/UserTypes";
import useRouteControl, {
  routeScenarioEnum,
} from "../../../hooks/RouteController/useRouteController";
import { useLeadingDebounce } from "../../../hooks/utils/useDebounce";
import useRequestSpinner from "../../../hooks/utils/useRequestSpinner";
import PhnLinked from "../../../modals/PhnLinked";
import PhnLinkedPending from "../../../modals/PhnLinkedPending";
import PhnNotLinked from "../../../modals/PhnNotLinked";
import { DASHBOARD } from "../../utils/RouteConstants";
import FlowPage, {
  Header,
  Body,
  Title,
  Desc,
  BtnContainer,
  AlertContainer,
  ContactUs,
} from "../FlowPage.wrapper";

const PhnChild = () => {
  const [dontLinkPhnsModal, setDontLinkPhnsModal] = useState(false);
  const [linkPhnsModal, setLinkPhnsModal] = useState(false);
  const [linkPendingPhnsModal, setLinkPendingPhnsModal] = useState(false);

  const { t } = useTranslation();
  const { routeScenario} = useRoute();
  const { userDetails, children } = useUser();
  const history = useHistory();

  const [handleRoute] = useRouteControl(history);
  const { handleRequestWithSpinner, requestLoading } = useRequestSpinner();

  // Button Fns

  const handleOnClickAgree = () => {
    const promise = handlePhnAgreementState();

    if (promise === undefined) {
      handleRoute(routeScenario as routeScenarioEnum);
    } else {
      handleRequestWithSpinner(promise, () => {
        switch (routeScenario) {
          case routeScenarioEnum.i_mailParent:
            setLinkPendingPhnsModal(true);
            return;
          case routeScenarioEnum.i_missingAssent:
            setLinkPhnsModal(true);
            return;
        }
      });
    }
  };

  const handleOnClickDisagree = () => {

    const promise = updatePhnAgreement(CookieStorage.get("token") as string, {
      performers: [children[0].identifier],
      phn_consent_state: PhnConsentState.REJECTED,
      phn: null,
    });

    handleRequestWithSpinner(promise, () => {
      setDontLinkPhnsModal(true);
    });
  };

  // Modal Fns
  const handleAgreeNextPage = () => {
    // only get triggered on i_missingAssent & i_mailParent route
   history.push(DASHBOARD)
  };
  const handleDisagreeNextPage = () => {
    // done doing flow, redirect to dashboard
    history.push(DASHBOARD)
  };


  // Helper Fns

  const handlePhnAgreementState = () => {
    let phn_consent_state: PhnConsentState;
    let performers: Array<string>;

    switch (routeScenario) {
      case routeScenarioEnum.i_mailParent:
        phn_consent_state = PhnConsentState.ASSENT;
        performers = [userDetails.identifier];
        break;
      case routeScenarioEnum.i_missingAssent:
        phn_consent_state = PhnConsentState.CONSENT_ASSENT;
        performers = [userDetails.identifier];
        break;
      default:
        // do nothing if any other RouteScenario
        return;
    }

    return updatePhnAgreement(CookieStorage.get("token") as string, {
      phn: userDetails.phn as string,
      performers,
      phn_consent_state,
    });
  };

  return (
    <FlowPage loading={requestLoading} anchorButtonGroup={false} pageName={PhnChild.name}>
      <Header />
      <Body>
        <Title>{t("phnAdultTitle")}</Title>
        <Desc>
          <p className="flowPage__p">{t("phnChildDesc1")}</p>
          <p className="flowPage__p">
            <Trans>{t("phnChildDesc2")}</Trans>
          </p>
          <ContactUs textLeft />
          <AlertContainer>{t("phnChildAlert")}</AlertContainer>
        </Desc>
        <BtnContainer reverse>
          <Button
            className="btn"
            onClick={useLeadingDebounce(handleOnClickAgree)}
          >
            {t("phnParentAgree")}
          </Button>
          <Button
            className="btn"
            onClick={useLeadingDebounce(handleOnClickDisagree)}
          >
            {t("phnParentDisagree")}
          </Button>
        </BtnContainer>
      </Body>
      <PhnLinked
        open={linkPhnsModal}
        setOpen={setLinkPhnsModal}
        onClickHandler={handleAgreeNextPage}
      />
      <PhnNotLinked
        open={dontLinkPhnsModal}
        setOpen={setDontLinkPhnsModal}
        onClickHandler={handleDisagreeNextPage}
      />
      <PhnLinkedPending
        open={linkPendingPhnsModal}
        setOpen={setLinkPendingPhnsModal}
        onClickHandler={handleAgreeNextPage}
      />
    </FlowPage>
  );
};

export default PhnChild;
