import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Button from "../../../components/Button";
import ChooseChildItem from "../../../components/ChooseChildItem";
import { useUser } from "../../../context/UserContext/UserContext";
import { UserRelated } from "../../../context/UserContext/UserTypes";
import { useUtil } from "../../../context/UtilContext/UtilContext";

import { handleChildrenParticipation } from "../../../utils/handleParticipation";
import { DASHBOARD } from "../../utils/RouteConstants";

import FlowPage, {
  Body,
  BtnContainer,
  ChooseChildContainer,
  Desc,
  Header,
  Title,
} from "../FlowPage.wrapper";

const ParentStop = () => {
  const { t } = useTranslation();
  const [filteredChildren, setFilteredChildren] = useState<Array<UserRelated>>(
    []
  );
  const [isChooseArrayEmpty, setIsChooseArrayEmpty] = useState(false);
  const { userDetails, children, setChildren } = useUser();
  const { fullAgreement, setFullAgreement } = useUtil();
  const history = useHistory();


  const handleNext = async () => {
    const {children: newChildren} = handleChildrenParticipation(
      fullAgreement,
      userDetails,
      children,
      userDetails.identifier
    );

    setChildren(newChildren);
    history.push(DASHBOARD);
  };

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    // Must filter out all children who didn't assent
    // because the child prefered not to participate in the study
    const array = children.filter((child) => {
      return child.consent === true;
    });

    setFilteredChildren(array);
  }, [children]);

  useEffect(() => {
    setFullAgreement([]);
  }, [setFullAgreement]);

  useEffect(() => {
    if (fullAgreement.length === 0) {
      setIsChooseArrayEmpty(true);
    } else if (fullAgreement.length > 0) {
      setIsChooseArrayEmpty(false);
    }
  }, [children, setIsChooseArrayEmpty, fullAgreement.length]);

  return (
    <>
      <FlowPage pageName={ParentStop.name}>
        <Header />
        <Body>
          <Title>{t("parentStopTitle")}</Title>
          <Trans>
            <Desc>
              <p className="flowPage__p--last">{t("parentStopDesc")}</p>
            </Desc>
          </Trans>
          <ChooseChildContainer>
            {filteredChildren.map((relatedPerson) => {
              return (
                <ChooseChildItem
                  key={relatedPerson.name}
                  child={relatedPerson}
                  setFilteredChildren={setFullAgreement}
                  filteredChildren={fullAgreement}
                />
              );
            })}
          </ChooseChildContainer>

          <BtnContainer reverse>
            <Button
              disabled={isChooseArrayEmpty}
              className={`btn ${isChooseArrayEmpty ? "btn--disabled" : ""}`}
              onClick={handleNext}
            >
              {t("parentStopBtn-Primary")}
            </Button>
            <Button className="btn btn--secondary" onClick={handleBack}>
              {t("parentStopBtn-Secondary")}
            </Button>
          </BtnContainer>
        </Body>
      </FlowPage>
    </>
  );
};

export default ParentStop;
