import { useEffect } from "react";
import { useUser } from "../../../../context/UserContext/UserContext";
import ExpiredAgreement from "../../ExpiredAgreement";
import FullAgreement from "../../FullAgreement";
import MissingAgreement from "../../MissingAgreement";

import { useAdultPatientCardController } from "./AdultPatientCardController";

const AdultPatientView = () => {
  const { userDetails } = useUser();

  const {
    initializeAdultPatientCards,
    isFullAgreement,
    isMissingAgreement,
    resetState,
  } = useAdultPatientCardController();

  useEffect(() => {
    resetState();
    initializeAdultPatientCards(userDetails);
  }, [userDetails, initializeAdultPatientCards, resetState]);

  const renderComponents = () => {
    if (isFullAgreement) {
      return <FullAgreement adultUser={userDetails} />;
    } else if (isMissingAgreement) {
      return <MissingAgreement />;
    } else {
      return <ExpiredAgreement />;
    }
  };

  return (
    <div>
      <>{renderComponents()}</>
    </div>
  );
};

export default AdultPatientView;