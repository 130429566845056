import { useTranslation } from "react-i18next";
import Modal, { BaseModalProps } from "./Modal";
import Button from "../components/Button";

interface NoAssentModalProps extends BaseModalProps {
  onClickHandler: () => void;
}

const NoConsentAssent = ({ open, setOpen, onClickHandler }: NoAssentModalProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(false);
    onClickHandler();
  };



  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Content>
      <Modal.Title>
        {t("noConsentAssentTitle")}
      </Modal.Title>
      <Modal.Desc textLeft>
        <p className="flowPage__p">
          {t("noConsentAssentDesc1")}
        </p>
        <p className="flowPage__p">{t("noConsentAssentDesc2")}</p>
      </Modal.Desc>
      </Modal.Content>
      <Modal.BtnContainer>
        <Button onClick={() => handleClick()} className="btn">
          {t("continue")}
        </Button>
      </Modal.BtnContainer>
    </Modal>
  );
};

export default NoConsentAssent;
