import axios, { AxiosError } from "axios";
import { createMachine, assign, DoneInvokeEvent } from "xstate";
import { ApiFailureError } from "../components/ErrorUI/types/ErrorType";
import { BASE_URL } from "../App";
import { PhnAgreementType, UserTypes } from "../context/UserContext/UserTypes";

async function getUserDetails(context: AppContext) {
   const res = await axios({
    url: `${BASE_URL}/user`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Md4r-Token": `${context.token}`,
    },
    withCredentials: true,
  });

  return res;
}

async function getPhnDetails(context: AppContext) {
  const res = await axios({
    url: `${BASE_URL}/user/phn`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Md4r-Token": `${context.token}`,
    },
    withCredentials: true,
  });

  return res;
}

async function getAllDetails(context: AppContext) {
  return Promise.all([getUserDetails(context), getPhnDetails(context)]).then(
    (values) => {
      return values;
    }
  );
}

interface AppContext {
  token: string | undefined;
  user: UserTypes | undefined;
  phn: PhnAgreementType | undefined;
  error: AxiosError<ApiFailureError> | undefined;
}

type AppEvent =
  | { type: "FETCH"; token: string }
  | { type: "RESET" }
  | { type: "REFRESH_USER"}
  | DoneInvokeEvent<any>;

export const userMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFdZgE4DoCWEA2YAxAGICiAKgMIASioADgPazYAu2jAdnSAB6IBaACwAGAEyYhAZikBGKQFYA7ENkBOBQDZZAGhABPRDNmZZ2rWJGq1ZpQF87e1Bkx5GAQwjZOUQhC5gOJwAbowA1oEwrACCeHgAImCs7th48EggTCzsXDz8CNKYGtJimqWyCgpCABx6hghSQkqY1WKtokJqilJiQpoOTmhYbp7evhjojFj0eO6sAGZTALaYUbEJSSlpPFlsHNwZ+YXFPWXmVbUGgvJCpkqySlJqliIiXZr2jiDOWLDIAMb-OCwTBoVjsHzxObuQi8WDJViBdzzRHoAAUCleAEpCD9QQCgbAQWCIVAockdsw9rlDohtNVMAoxM81EoFFJqjZpHUjJzMD1Wm8xFIymJZKUBt8hvjAcDMOgwJ59IQAEqkYhqgDK1AA+gBVTWkFWU7L7PKCEVSUxWESaZ6yaoiarsqQ8hACdRWqRKapNGoyXpKMoOL6cRgQOA8PG4Agm6kHUD5ARVESYYVyX0PUrKJRumS3VqtZRyMRKJQiWSSvEjLw+OM5BN8QSlhlWLSParVRpqES5q4NdSmcyiDuKGpV6V-WVE0FJUnk9z1s20gqYzD3TTVB5qLelX15rv8tpiKpVGrbicuKeEkEKpVLmmJxBBq3VTQfNSfzvekQKA9e49TyEc8lDUS9fgJYEH0bI5SnXMwt1A3dNyEPNZFTAVhUQrcpBEKRwMweYtmQBVMAAOUYABZOZ-gAC2g80Ck7NN3y3TQnVkB4HTdD1cLuWxmUxC4wK+PEiNSEjAlIXh6GwBUIE1YFlwYKkG0YvpmlZXCtE0EpRE0HjtH4u0mlAnsnSsAjxLwSTyMYRSiWUzJVKco5Kngzdt2Q-d+yDFpj3MbQnnZaorOI0iKIABUmeZUjABiVxqBlWi6MxhSZVo+3qD1P34hR7TUXSFBuMKJNIyguDQThWAomqwEgSAEqfVdUw3RCd3FFC3WaQs2hkHsVCUNpQtE6VrMkpqmwKOC2q8zqfOy0sJC49jnU0OQnnAyaky0W50wdVQhs0HMeMaBRjN6FlWnffCQyAA */
  createMachine(
    {
  context: {
    token: undefined,
    user: undefined,
    phn: undefined,
    error: undefined,
  },
  tsTypes: {} as import("./FetchUserMachine.typegen").Typegen0,
  schema: {
    context: {} as AppContext,
    events: {} as AppEvent,
    actions: {} as
      | { type: "FETCH"; token: string }
      | DoneInvokeEvent<UserTypes>,
  },
  id: "user",
  initial: "idle",
  states: {
    idle: {
      on: {
        FETCH: {
          actions: "setToken",
          target: "loading",
        },
      },
    },
    loading: {
      invoke: {
        src: "getAllDetails",
        id: "getAllDetails",
        onDone: [
          {
            actions: "setUser",
            target: "success",
          },
        ],
        onError: [
          {
            actions: "setError",
            cond: "BAD_AUTHORIZATION",
            target: "#user.failure.NoSession",
          },
          {
            actions: "setError",
            cond: "FRONTEND_SESSION_EXPIRED",
            target: "#user.failure.ExpiredSession",
          },
          {
            actions: "setError",
            cond: "PROFILE_NOT_FOUND",
            target: "#user.failure.NoProfile",
          },
          {
            actions: "setError",
            cond: "CONSENT_NOT_NEEDED",
            target: "#user.failure.ConsentNotNeeded",
          },
          {
            target: "#user.failure.NoMatch",
          },
        ],
      },
    },
    success: {
      initial: "settingData",
      states: {
        settingData: {
          after: {
            "500": {
              target: "ready",
            },
          },
        },
        ready: {
          on: {
            REFRESH_USER: {
              actions: "resetUser",
              target: "#user.loading",
            },
          },
        },
      },
    },
    failure: {
      initial: "NoMatch",
      states: {
        NoMatch: {},
        ExpiredSession: {},
        NoSession: {},
        NoProfile: {},
        ConsentNotNeeded: {},
      },
    },
  },
},
    {
      actions: {
        setError: assign({
          error: (context: any, event: any) => {
            return event.data as AxiosError;
          },
        }) as any,
        setUser: assign({
          user: (context: any, event: any) => event.data[0].data,
          phn: (context: any, event: any) => event.data[1].data
        }) as any,
        setToken: assign<AppContext, any>({
          token: (_, event: any) => event.token,
        }) as any,
        resetUser: assign({ user: () => undefined }) as any,
      },
      services: {
        getAllDetails,
      },
      guards: {
        BAD_AUTHORIZATION: (context: any, event: any) =>
          event.data.response.data.errorCode === "BAD_AUTHORIZATION",
        FRONTEND_SESSION_EXPIRED: (context: any, event: any) =>
          event.data.response.data.errorCode === "FRONTEND_SESSION_EXPIRED",
        PROFILE_NOT_FOUND: (context: any, event: any) =>
          event.data.response.data.errorCode === "PROFILE_NOT_FOUND",
        CONSENT_NOT_NEEDED: (context: any, event: any) =>
          event.data.response.data.errorCode === "CONSENT_NOT_NEEDED",
      },
    }
  );
