import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button";
import { useUser } from "../../../../context/UserContext/UserContext";
import { DASHBOARD } from "../../../utils/RouteConstants";
import FlowPage, { Header, Body, Title, BtnContainer, AcceptAlertContainer } from "../../FlowPage.wrapper";
import ReviewAgreementsPhnAdultInfo from "./ReviewAgreementsPhnAdultInfo";

const ReviewAgreementsPhnInfo = () => {
  const { children } = useUser();
  const history = useHistory();
  const { t } = useTranslation();

  const handleGoBack = () => {
    history.goBack();
  };

  const handleReturnHome = () => {
    history.push(DASHBOARD);
  };

  const renderChildrenPhn = () => {
    return children?.length
      ? children.map((child) => (
          <div key={child.identifier} data-testid="FlowPage-childPhn" className="flowPage__childPhnContainer">
            <strong>{child.name}</strong>
            <p className="phn__hidden">{t("phnHidden")}</p>
          </div>
        ))
      : "";
  };

  return (
    <FlowPage pageName={ReviewAgreementsPhnAdultInfo.name}>
      <Header />
      <Body>
        <AcceptAlertContainer>{t("reviewAgreementAlertText")}</AcceptAlertContainer>
        <Title>{t("phnTitle")}</Title>

        {renderChildrenPhn()}

        <BtnContainer reverse>
          <Button className="btn" onClick={() => handleReturnHome()}>
            {t("reviewAgreementBtnSecondaryReturnHome")}
          </Button>
          <Button className="btn" onClick={() => handleGoBack()}>
            {t("reviewAgreementBtnSecondary")}
          </Button>
        </BtnContainer>
      </Body>
    </FlowPage>
  );
};

export default ReviewAgreementsPhnInfo;
