import { useTranslation } from "react-i18next";
import FlowPage, { Body, BtnContainer, Title } from "../../navigation/pages/FlowPage.wrapper";

import errorSearchImage from "../../images/error-search-user.svg";
import { LOGIN } from "../../navigation/utils/RouteConstants";
import { BASE_URL } from "../../App";
import Button from "../Button";


export const NoSessionErrorPage = () => {
  const { t } = useTranslation();

  const next = () => {
    window.location.href = `${BASE_URL}${LOGIN}`
  };

  return (
    <FlowPage pageName={NoSessionErrorPage.name}>
      <Body>
        <div data-testid="FlowPage-Header" className={` flowPage__headerImgError`}>
          <img src={errorSearchImage} alt="header" />
        </div>

        <>
          <Title textLeft={false}>
            {t("errorNoSessionTitle")}
          </Title>

          <BtnContainer>
            <Button className="btn btn--primary" onClick={next}>
              {t("errorNoSessionBtn")}
            </Button>
          </BtnContainer>
        </>
        
      </Body>
    </FlowPage>
  );
};
