import { useEffect, useState } from "react";
import { useUser } from "../context/UserContext/UserContext";
import { UserRelated } from "../context/UserContext/UserTypes";
import Icon, { IconEnum } from "./Icon";

interface ChooseParentItemProps {
  mappedParent: UserRelated;
}

const ChooseParentItem = ({
  mappedParent,
}: ChooseParentItemProps) => {

  const { parent, setParent } = useUser();
  
  const [parentClicked, setParentClicked] = useState(
    parent?.identifier === mappedParent.identifier 
      ? true 
      : false
    );

  const handleClick = () => {
    setParentClicked(!parentClicked);

    if (!parentClicked) {
      setParent(mappedParent);
    }
  };

  useEffect(() => {
    const found = parent?.identifier !== mappedParent.identifier;

    if (found) {
      setParentClicked(false);
    }
  }, [mappedParent, parent?.identifier]);

  useEffect(() => {
    if (!parentClicked && mappedParent.identifier === parent?.identifier) {
      setParent(undefined);
    }
  }, [parentClicked, setParent, mappedParent.identifier, parent?.identifier]);

  return (
    <div
      onClick={() => handleClick()}
      data-testid="FlowPage-ChooseChildItem"
      className={`flowPage__ChooseChildItem ${
        parentClicked ? "flowPage__ChooseChildItem--on" : ""
      }`}
    >
      <div className="ChooseChild">
        <p
          className={`ChooseChild__name ${
            parentClicked ? "ChooseChild__name--on" : ""
          }`}
        >
          {mappedParent.name}
        </p>
        {parentClicked ? (
          <Icon
            iconName={IconEnum.check}
            withIconClass={false}
            withSizeConstraint={true}
          />
        ) : (
          <Icon
            iconName={IconEnum.check}
            withIconClass={false}
            withSizeConstraint={true}
            hideIcon={true}
          />
        )}
      </div>
    </div>
  );
};

export default ChooseParentItem;
