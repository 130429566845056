import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { updateAgreement } from "../../../../API/Endpoints";
import { CookieStorage } from "../../../../components/services/Storage";
import { useRoute } from "../../../../context/RouteContext/RouteContext";
import { useUser } from "../../../../context/UserContext/UserContext";
import useRouteControl, {
  routeScenarioEnum,
} from "../../../../hooks/RouteController/useRouteController";

import { isBaby } from "../../../../utils/handleChildAge";
import { handleChildrenParticipation } from "../../../../utils/handleParticipation";
import { getDateNow } from "../../../../utils/TimeStampInSeconds";
import { DASHBOARD } from "../../../utils/RouteConstants";
import ChildAssent from "./ChildAssent";

const ChildAssentParentView = () => {
  const { routeScenario } = useRoute();
  const { children, setChildren, userDetails } = useUser();
  const history = useHistory();
  const [childCounter, setChildCounter] = useState(0);

  const [handleRoute] = useRouteControl(history);

  // Main Fns - Control onClick Buttons & onModalBtnClick Routing
  const handleOnClickAgree = () => {
    console.log(
      `CurrentChild: ${children[childCounter].name}, assent: ${children[childCounter].assent}, childCounter: ${childCounter}, children: ${children.length}`
    );
    const promise = updateAgreeByChild(childCounter, true);
    // change this childCounter to childCounter + 1 for multi-children
    setChildCounter(childCounter);
    return promise;
  };

  const handleAgreeNextPage = () => {
    HandleRouteFn();
  };

  const handleOnClickDisagree = () => {
    const { promise } = handleChildrenParticipation(
      children,
      userDetails,
      children,
      children[0].identifier
    );
    return promise;
  };

  const handleDisagreeNextPage = () => {
    history.push(DASHBOARD);
  };

  // Helper Fns
  const HandleRouteFn = () => {
    if (childCounter >= children.length - 1) {
      setChildCounter(0);
      handleRoute(routeScenario as routeScenarioEnum);
    }
  };

  const updateAgreeByChild = (childIndex: number, assent: boolean) => {
    const childrenHolder = children;
    childrenHolder[childIndex].assent = assent;
    childrenHolder[childIndex].agreementDate = getDateNow();
    childrenHolder[childIndex].hasAgreedBefore = true;
    setChildren(childrenHolder);
    return updateAgreement(
      CookieStorage.get("token") as string,
      userDetails,
      childrenHolder
    );
  };

  useEffect(() => {
    let mounted = true;

    if (isBaby(children[childCounter].age) && mounted) {
      setChildCounter(childCounter + 1);
      children[childCounter].agreementDate = getDateNow();
      children[childCounter].hasAgreedBefore = true;
      if (childCounter >= children.length - 1) {
        setChildCounter(0);
        handleRoute(routeScenario as routeScenarioEnum);
      }
    }

    return () => {
      mounted = false;
    };
  }, [childCounter, children, handleRoute, routeScenario]);

  return (
    <ChildAssent
      childName={children[childCounter].name}
      handleOnClickAgree={handleOnClickAgree}
      handleOnClickDisagree={handleOnClickDisagree}
      handleAgreeNextPage={handleAgreeNextPage}
      handleDisagreeNextPage={handleDisagreeNextPage}
      routeScenario={routeScenario}
    />
  );
};

export default ChildAssentParentView;
