import React from "react";
import { useUtil } from "../context/UtilContext/UtilContext";
import TrackAnalytics from "./Analytics/TrackAnalytics";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
}

/**
 * Custom button that powers analytics under the hood
 * @param children anything
 * @returns
 */
const Button = ({ children, ...props }: ButtonProps) => {
  const { pageName } = useUtil();

  const overrideOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // button children are always used as strings
    TrackAnalytics("click", `${children}`, pageName.current);

    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <button {...props} onClick={overrideOnClick}>
      {children}
    </button>
  );
};

export default Button;
