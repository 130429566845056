import { useErrorHandler } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import { updateAgreementUserChild } from "../../../../API/Endpoints";
import { CookieStorage } from "../../../../components/services/Storage";
import { useRoute } from "../../../../context/RouteContext/RouteContext";
import { useUser } from "../../../../context/UserContext/UserContext";
import useRouteControl from "../../../../hooks/RouteController/useRouteController";
import { handleMainUserChildParticipation } from "../../../../utils/handleParticipation";
import { getDateNow } from "../../../../utils/TimeStampInSeconds";
import { DASHBOARD } from "../../../utils/RouteConstants";
import ParentConsent from "./ParentConsent";

const ParentConsentChildView = () => {
  const { routeScenario } = useRoute();
  const history = useHistory();
  const { userDetails, setUserDetails, parent } = useUser();
  const [handleRoute] = useRouteControl(history);
  const handleError = useErrorHandler();

  // Main Fns

  const handleOnClickAgree = () => {
    updateAgreedUponTimeForParent(true);
    return updateConsent(true);
  };

  const handleAgreeNextPage = () => {
    if (!userDetails.assent) {
      history.push(DASHBOARD);
    } else {
      handleRoute(routeScenario);
    }
  };

  const handleOnClickDisagree = () => {
    updateAgreedUponTimeForParent(false);

    const user_identifier = parent ? parent.identifier : "";

    const { promise } = handleMainUserChildParticipation(
      userDetails,
      user_identifier
    );

    return promise;
  };

  const handleDisagreeNextPage = () => {
    history.push(DASHBOARD);
    console.log("leaving flow");
  };

  // Helper Fns
  const updateConsent = (consent: boolean) => {
    const childUser = userDetails;
    childUser.consent = consent;

    if (parent) {
      childUser.consentBy = [parent.identifier];
    }

    return updateAgreementUserChild(
      CookieStorage.get("token") as string,
      childUser
    );
  };

  const updateAgreedUponTimeForParent = (didParentConsent = false) => {
    //TODO: Must refactor this to handle current parent context

    if (parent === undefined) {
      return handleError({message:"Parent is undefined"});
    }

    const parentHolder = parent;

    parentHolder.hasAgreedBefore = true;
    parentHolder.agreementDate = didParentConsent ? getDateNow() : 0;

    // insert parent into userdetails
    const parentIndex = userDetails.related.findIndex(
      (userRelated) => userRelated.identifier === parentHolder.identifier
    );
    userDetails.related.splice(parentIndex, 1, parentHolder);

    console.log(userDetails);
    setUserDetails(userDetails);
  };

  return (
    <ParentConsent
      handleOnClickAgree={handleOnClickAgree}
      handleOnClickDisagree={handleOnClickDisagree}
      handleAgreeNextPage={handleAgreeNextPage}
      handleDisagreeNextPage={handleDisagreeNextPage}
    />
  );
};

export default ParentConsentChildView;
