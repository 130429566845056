import { useTranslation } from "react-i18next";
import Modal, { BaseModalProps } from "./Modal";
import Button from "../components/Button";

interface PhnLinkedModalProps extends BaseModalProps {
  onClickHandler: () => void;
}

const PhnLinked = ({ open, setOpen, onClickHandler }: PhnLinkedModalProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(false);
    onClickHandler();
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Content>
        <Modal.Title>{t("phnLinkedTitle")}</Modal.Title>
      </Modal.Content>
      <Modal.BtnContainer>
        <Button onClick={() => handleClick()} className="btn">
          {t("continue")}
        </Button>
      </Modal.BtnContainer>
    </Modal>
  );
};

export default PhnLinked;
