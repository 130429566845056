import { useEffect } from "react";
import { useUser } from "../../../context/UserContext/UserContext";
import { UserRelated } from "../../../context/UserContext/UserTypes";
import { useUtil } from "../../../context/UtilContext/UtilContext";
import { getParentByConsentedByID } from "../../../services/FilterParent";
import { hasAgreementExpired } from "../../../utils/formatTime";
import ExpiredAgreement from "../ExpiredAgreement";
import FullAgreement from "../FullAgreement";
import MissingAgreement from "../MissingAgreement";
import MissingConsent from "../MissingConsent";
import useDashboardCardController from "./useDashboardCardController";

/*
- React Hook to control what parent/child
 sees by reading state

- Update between:
   + Shared
    - Empty
    - Missing
*/

const HomeAuthChildController = () => {
  const {
    fullAgreement,
    setFullAgreement,
    setMissingConsent,
    missingConsent,
    setMissingAgreement,
    missingAgreement,
    expiredAgreement,
    setExpiredAgreement,
  } = useUtil();

  const { userDetails, parent } = useUser();
  const { InitializeChildController } = useDashboardCardController();

  useEffect(() => {
    setFullAgreement([]);
    setMissingConsent([]);
    setMissingAgreement([]);
    setExpiredAgreement([]);
    InitializeChildController(userDetails);
  }, [
    userDetails,
    setFullAgreement,
    setMissingConsent,
    setMissingAgreement,
    setExpiredAgreement,
    InitializeChildController,
  ]);

  const renderMissingAssent = () => {
    if (missingConsent.length > 0) {
      return missingConsent.map((person) => <MissingConsent key={person.name} childMainUser={person} />);
    }
  };

  const renderFullAgreement = () => {
    if (fullAgreement.length > 0) {

      const consentedParent = getParentByConsentedByID(userDetails.consentBy[0], userDetails)
      return <FullAgreement children={fullAgreement} parent={consentedParent as UserRelated} />;
    }
  };


  const renderMissingAgreement = () => {
    if (!parent) {
      return;
    }

    if (expiredAgreement.length > 0 || hasAgreementExpired(parent.agreementDate, parent.name)) {
      // If any user is expired, do not show any missing participants
      return;
    } else if (missingAgreement.length > 0) {
      return <MissingAgreement children={missingAgreement} areAllChildrenNotParticipating={true} />;
    }
  };
  const renderExpiredAgreement = () => {
    if (!parent) {
      return;
    }

    if (missingConsent.length > 0) {
      // if main user's parent is missing consent, do not show expired card
      return;
    } else if (expiredAgreement.length > 0) {
      return <ExpiredAgreement isParent={false} />;
    } else if (hasAgreementExpired(userDetails.agreementDate, userDetails.name)) {
      return <ExpiredAgreement isParent={false} />;
    }
  };

  return (
    <div>
      {renderMissingAssent()}
      {renderFullAgreement()}
      {renderMissingAgreement()}
      {renderExpiredAgreement()}
    </div>
  );
};

export default HomeAuthChildController;
