import { RouteComponentProps } from "react-router";
import { RouteTypes } from "../../types/RouteTypes";
import { CHILD_ASSENT_PARENT_VIEW, CHILD_ASSENT_CHILD_VIEW, HOME, PARENT_CONSENT_PARENT_VIEW } from "../../utils/RouteConstants";

export const caNeededController = (
  types: RouteTypes,
  history: RouteComponentProps["history"]
) => {
  if(types.d_child) history.push(CHILD_ASSENT_PARENT_VIEW);
  else if(types.d_baby) history.push(PARENT_CONSENT_PARENT_VIEW);
  else if(types.i_child) history.push(CHILD_ASSENT_CHILD_VIEW);
  else if(types.d_missingConsent) history.push(PARENT_CONSENT_PARENT_VIEW)
  else if(types.i_missingAssent) history.push(CHILD_ASSENT_CHILD_VIEW)
  else if(types.d_mailChild) history.push(PARENT_CONSENT_PARENT_VIEW)
  else if(types.i_mailParent) history.push(CHILD_ASSENT_CHILD_VIEW)

  else history.push(HOME)


};
