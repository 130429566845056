import { useHistory } from "react-router-dom";
import { updateAgreementUserChild } from "../../../../API/Endpoints";
import { CookieStorage } from "../../../../components/services/Storage";
import { useRoute } from "../../../../context/RouteContext/RouteContext";
import { useUser } from "../../../../context/UserContext/UserContext";
import { PhnConsentState } from "../../../../context/UserContext/UserTypes";
import useRouteControl from "../../../../hooks/RouteController/useRouteController";
import { handleMainUserChildParticipation } from "../../../../utils/handleParticipation";
import { getDateNow } from "../../../../utils/TimeStampInSeconds";
import { DASHBOARD } from "../../../utils/RouteConstants";
import ChildAssent from "./ChildAssent";

const ChildAssentChildView = () => {
  const { routeScenario } = useRoute();
  const history = useHistory();
  const { userDetails, phnAgreement } = useUser();

  const [handleRoute] = useRouteControl(history);

  const handleOnClickAgree = () => {
    return updateAgreement(true);
  };

  const handleAgreeNextPage = () => {
    UserDoneFlow();
  };

  const handleOnClickDisagree = () => {
    const { promise } = handleMainUserChildParticipation(userDetails, userDetails.identifier);
    return promise;
  };

  const handleDisagreeNextPage = () => {
    history.push(DASHBOARD);
  };

  const UserDoneFlow = () => {
    //  check phn-agreement state to see if there is any rejection.
    // if there is any rejection
    // then do not handle route but just end flow

    if (phnAgreement.phn_consent_state === PhnConsentState.REJECTED) {
      history.push(DASHBOARD);
    } else {
      handleRoute(routeScenario);
    }
  };

  const updateAgreement = (assent: boolean) => {
    const userHolder = userDetails;
    userHolder.assent = assent;
    userHolder.agreementDate = getDateNow();
    userHolder.hasAgreedBefore = true;

    return updateAgreementUserChild(
      CookieStorage.get("token") as string,
      userHolder
    );
  };

  return (
    <ChildAssent
      childName={userDetails.name}
      handleOnClickAgree={handleOnClickAgree}
      handleOnClickDisagree={handleOnClickDisagree}
      handleAgreeNextPage={handleAgreeNextPage}
      handleDisagreeNextPage={handleDisagreeNextPage}
      routeScenario={routeScenario}
    />
  );
};

export default ChildAssentChildView;
