import { UserRelated } from "../context/UserContext/UserTypes";

export const mergeChildrenObjects = (
  childrenWithoutAgreement: Array<UserRelated>,
  children: Array<UserRelated>
) => {
  let copy: Array<UserRelated> = new Array(...children);

  // we check if a name matches in 1 array and then other
  // then we add that matched name into an array, we must check if this name already exists into said array
  // then we add the rest the children array into said array
  // must have individual copies

  childrenWithoutAgreement.forEach((childWithAgreement) => {
    const index = children.findIndex((child) => {
      return child.name === childWithAgreement.name;
    });

    copy[index] = childWithAgreement;
  });

  return copy;
};
