import { useState } from "react";
import { UserRelated } from "../context/UserContext/UserTypes";
import Icon, { IconEnum } from "./Icon";

interface ChooseChildItemProps {
  child: UserRelated;
  setFilteredChildren:
    | ((children: Array<UserRelated>) => void)
    | ((missingAgreement: React.SetStateAction<UserRelated[]>) => {});
  filteredChildren: Array<UserRelated>;
}

const ChooseChildItem = ({
  child,
  setFilteredChildren,
  filteredChildren,
}: ChooseChildItemProps) => {
  const [childClicked, setChildClicked] = useState(
    filteredChildren.includes(child) ? true : false
  );
  // Add this obj if clicked, remove this obj if unclicked

  const handleClick = () => {
    setChildClicked(!childClicked);
    setFilteredChildren([child, ...filteredChildren]);

    if (childClicked) {
      const found = filteredChildren.find(
        (filteredChild) => filteredChild.name === child.name
      );

      if (found) {
        setFilteredChildren(
          filteredChildren.filter((filteredChild) => filteredChild.name !== found.name)
        );
      }
    }
  };

  return (
    <div
      onClick={() => handleClick()}
      data-testid="FlowPage-ChooseChildItem"
      className={`flowPage__ChooseChildItem ${
        childClicked ? "flowPage__ChooseChildItem--on" : ""
      }`}
    >
      <div className="ChooseChild">
        <p
          className={`ChooseChild__name ${
            childClicked ? "ChooseChild__name--on" : ""
          }`}
        >
          {child.name}
        </p>
        {childClicked ? (
          <Icon
            iconName={IconEnum.check}
            withIconClass={false}
            withSizeConstraint={true}
          />
        ) : (
          <Icon
            iconName={IconEnum.check}
            withIconClass={false}
            withSizeConstraint={true}
            hideIcon={true}
          />
        )}
      </div>
    </div>
  );
};

export default ChooseChildItem;
