import SpinnerImage from './SpinnerImage';

const Spinner = () => {
    return (
        <div className="spinner">
        <h1 className="spinner__title">Please wait while we get ready</h1>
        <SpinnerImage/>
        </div>
    )
}


export default Spinner