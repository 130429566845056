import { useEffect } from "react";
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback";

export const useLeadingDebounce = (callback: any, delay = 10000) => {
  const debounce = useDebouncedCallback(callback, delay, {maxWait: 2000, leading: true, trailing: false});

  useEffect(() => {
    debounce.cancel();
  }, [debounce])

    return debounce
  };