import { stopParticipatingInResearch } from "../API/Endpoints";
import { CookieStorage } from "../components/services/Storage";
import { UserRelated, UserTypes } from "../context/UserContext/UserTypes";
import { getAllRelatedParents } from "../services/FilterParent";
import { mergeChildrenObjects } from "./mergeChildrenObjs";

/**
 * A children handler for changing participation to false.
 * Fn will iterate through all children array, then flag assent, consent.
 * Then will update changes to the backend.
 * After iterating through all these changes, all children will be merged back into the original object.
 * @param fullAgreement is the array that contains all children who have fully agreed (consent+assent)
 * @param userDetails is the main user that has logged in
 * @param children is an Array of UserRelated
 * @param setChildren takes in array of UserRelated
 * @param user_identifier user's identifier who performed this action
 */
export const handleChildrenParticipation = (
  fullAgreement: Array<UserRelated>,
  userDetails: UserTypes,
  children: Array<UserRelated>,
  user_identifier: string
) => {
  const childrenWithoutAgreement = fullAgreement.map((child) => {
    return {
      ...child,
      assent: false,
      consent: false,
      hasAgreedBefore: true,
      consentBy: [user_identifier],
    };
  });

  const allChildrenObj = mergeChildrenObjects(childrenWithoutAgreement, children);

  // main parent user finished flow
  const hasAgreedBefore = { hasAgreedBefore: true };
  const mainParentWithoutAgreement: UserTypes = Object.assign(userDetails, hasAgreedBefore);

  // iterate through all related parents (i.e. 2nd parent) and flag them hasAgreedBefore to true
  const relatedParentsWithoutAgreement = getAllRelatedParents(userDetails).map((parent) => Object.assign(parent, hasAgreedBefore));
  const finalRelatedObj = [...allChildrenObj, ...relatedParentsWithoutAgreement];

  const promise = stopParticipatingInResearch(CookieStorage.get("token") as string, mainParentWithoutAgreement, finalRelatedObj);

  return { children: allChildrenObj, promise };
};

/**
 * A children handler for changing participation to false.
 * Fn will change the child as main user's consent & assent to false.
 * Then will update changes to the backend.
 * @param userDetails is the main user that has logged in, and is the current child as the main logged in user.
 * @param user_identifier user's identifier who performed this action
 */
export const handleMainUserChildParticipation = (userDetails: UserTypes, user_identifier: string) => {
  let user = {};
  const consent = { consent: false };
  const assent = { assent: false };
  const hasAgreedBefore = { hasAgreedBefore: true };
  const consentBy = { consentBy: [user_identifier] };

  Object.assign(user, userDetails, consent, assent, hasAgreedBefore, consentBy);

  const promise = stopParticipatingInResearch(CookieStorage.get("token") as string, user as UserTypes, getAllRelatedParents(userDetails));

  return { user, promise };
};
