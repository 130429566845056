import axios from "axios";
import { BASE_URL } from "../App";


export const sendEmail = async (user_identifier: string, receiver_email: string, token: string) => {

  console.log(`updated user with id of ${token}`);
  return axios({
    /*Add localhost URL to fetch local endpoint here here-> */
    url: `${BASE_URL}/user/email`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Md4r-Token": token
    },
    data: {
      fhir_identifier: user_identifier,
      receiver: receiver_email
    }
  });
};
