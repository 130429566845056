import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRoute } from "./context/RouteContext/RouteContext";
import { HOME } from "./navigation/utils/RouteConstants";
import { ProtectedRoute } from "./ProtectedRoute";

export interface Props {
  children: React.ReactNode;
  exact: boolean;
  path: string;
}

export const ConsentFlowRoute = ({ ...rest }: Props) => {
  const history = useHistory();
  const { routeScenario } = useRoute();

  // Protect against un-hydrated consent flow. I.e. user refreshes middle of consent flow
  useEffect(() => {
    if (!routeScenario) {
      history.push(HOME);
    }
  }, [history, routeScenario]);

  return <ProtectedRoute component={rest.children} {...rest} />
};
