import { useEffect } from "react";
import { useUser } from "../../../context/UserContext/UserContext";
import { UserRelated, UserRole } from "../../../context/UserContext/UserTypes";
import { useUtil } from "../../../context/UtilContext/UtilContext";
import { getParentByConsentedByID } from "../../../services/FilterParent";
import ExpiredAgreement from "../ExpiredAgreement";
import FullAgreement from "../FullAgreement";
import MissingAgreement from "../MissingAgreement";
import MissingAssent from "../MissingAssent";
import useDashboardCardController from "./useDashboardCardController";

/*
- React Hook to control what parent/child
 sees by reading state

- Update between:
   + Shared
    - Empty
    - Missing
*/

const HomeAuthParentController = () => {
  const {
    fullAgreement,
    setFullAgreement,
    missingAgreement,
    setMissingAssent,
    missingAssent,
    setMissingAgreement,
    expiredAgreement,
    setExpiredAgreement,
  } = useUtil();

  const { userDetails, children } = useUser();
  const { InitializeParentController } = useDashboardCardController();

  useEffect(() => {
    setFullAgreement([]);
    setMissingAssent([]);
    setMissingAgreement([]);
    setExpiredAgreement([]);
    if (UserRole.PARENT === userDetails.role) {
      InitializeParentController(children);
    }
  }, [
    children,
    userDetails,
    setFullAgreement,
    setMissingAssent,
    setMissingAgreement,
    setExpiredAgreement,
    InitializeParentController,
  ]);


  const renderMissingAssent = () => {
    if (missingAssent.length > 0) {
      return missingAssent.map((person) => <MissingAssent key={person.name} child={person} />);
    }
  };

  const renderFullAgreement = () => {
    if (fullAgreement.length > 0) {

      // TODO: Handle Multi consentedParent
      const consentedParent = getParentByConsentedByID(children[0].consentBy[0], userDetails)
      
      return <FullAgreement children={fullAgreement} parent={consentedParent as UserRelated} />;
    }
  };

  const renderMissingAgreement = () => {
    if (expiredAgreement.length > 0) {
      // If any user is expired, do not show any missing participants
      return;
    } else if (missingAgreement.length === children.length) {
      return <MissingAgreement areAllChildrenNotParticipating={true} />;
    } else if (missingAgreement.length > 0) {
      return <MissingAgreement children={missingAgreement} />;
    }
  };
  const renderExpiredAgreement = () => {
    if (expiredAgreement.length > 0) {
      return <ExpiredAgreement ChildrenOrParent={expiredAgreement} isParent={true} />;
    }
  };

  return (
    <div>
      {renderMissingAssent()}
      {renderFullAgreement()}
      {renderMissingAgreement()}
      {renderExpiredAgreement()}
    </div>
  );
};

export default HomeAuthParentController;
