import { useTranslation } from "react-i18next";
import { useUser } from "../context/UserContext/UserContext";
import { UserRole } from "../context/UserContext/UserTypes";
import Modal, { BaseModalProps } from "./Modal";
import Button from "../components/Button";


const EmailAssent = ({ open, setOpen}: BaseModalProps) => {
  const { t } = useTranslation();
  const { userDetails } = useUser();

  const handleClick = () => {
    setOpen(false);
  };

  const handleView = () => {
    if (userDetails.role === UserRole.PARENT) {
      return t("emailAssentTitle-parent")
    } else if (userDetails.role === UserRole.PATIENT) {
      return t("emailAssentTitle-child")
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Content>
        <Modal.Title>{handleView()}</Modal.Title>
      </Modal.Content>
      <Modal.BtnContainer>
        <Button onClick={() => handleClick()} className="btn">
          {t("continue")}
        </Button>
      </Modal.BtnContainer>
    </Modal>
  );
};

export default EmailAssent;
