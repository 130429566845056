import { useTranslation } from "react-i18next";
import { handleApostropheInName } from "../utils/handleApostropheInName";
import { FirstName } from "../utils/SplitName";
import Modal, { BaseModalProps } from "./Modal";
import Button from "../components/Button";

interface ParentStopModalProps extends BaseModalProps {
  onClickHandler: () => void;
  childName: string;
}

const ParentStop = ({ open, setOpen, onClickHandler, childName }: ParentStopModalProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(false);
    onClickHandler();
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Content>
        <Modal.Title>
          {t("ParentStopModalTitle", {
            child: FirstName(childName),
            s: handleApostropheInName(FirstName(childName)),
          })}
        </Modal.Title>

        <Modal.Desc>{t("ParentStopModalDesc")}</Modal.Desc>
      </Modal.Content>
      <Modal.BtnContainer reverse>
        <Button onClick={() => handleClick()} className="btn">
          {t("ParentStopModalBtn-Primary")}
        </Button>
        <Button onClick={() => setOpen(false)} className="btn btn--secondary">
          {t("ParentStopModalBtn-Secondary")}
        </Button>
      </Modal.BtnContainer>
    </Modal>
  );
};

/*
Fix spacing so this modal is ontop of last
*/

export default ParentStop;
