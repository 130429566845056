import LandingPage, { Caption, ContactUs, Footer, Header, Section } from "../LandingPage.wrapper";

import CTA from "../../../images/Landing_CTA.png";
import { useTranslation, Trans } from "react-i18next";
import Icon, { IconEnum } from "../../../components/Icon";
import { skipHomeUserIsLoggedIn } from "../../routing/Landing/homePage.logic";
import { useHistory } from "react-router-dom";
import RedirectLogin from "../../../modals/RedirectLogin";
import { useEffect, useState } from "react";
import { CookieStorage } from "../../../components/services/Storage";
import Button from "../../../components/Button";

/*
Home Page (md4r-home)
- Landing page will check localStorage to see if an id exists, if it does will push to
  Redirect page on onClick button.
- Else if the localStorage key is not a valid id, send a  mock wallet api request onClick
  button then redirect to redirect/id?=<id of user> with the returned query param


- Use 'PageLanding' wrapper (reusable component) for styling

onClick Button -> 'I want to be a part of this!' -> redirect to modal -> that logs user 
*/

const HomePage = () => {
  const [t] = useTranslation("common");
  const history = useHistory();

  const [redirectLogin, setRedirectLogin] = useState(false);

  useEffect(() => {
    // does session cookie exist?
    if (CookieStorage.get("token")) {
      skipHomeUserIsLoggedIn(history);
    }
  }, [history]);

  return (
    <LandingPage pageName={HomePage.name}>
      <Header title={t("title")} subtitle={t("subtitle")} withLoginButton loginButtonOnClickHandler={() => setRedirectLogin(true)} />
      <Section>
        <h2 className="homePage__title">{t("homePageTitle")}</h2>
        <img src={CTA} alt="" className="homePage__CTA" />
        <Caption>{t("homePageCaption")}</Caption>
      </Section>
      <Section>
        <h2 className="homePage__title">{t("homePageHow")}</h2>
        <ul className="iconGroup">
          <li className="iconItem">
            <Icon iconName={IconEnum.phone} />
            <Trans>
              <p>{t("homePageStep1")}</p>
            </Trans>
          </li>
          <li className="iconItem">
            <Icon iconName={IconEnum.beaker} />
            <Trans>
              <p>{t("homePageStep2")}</p>
            </Trans>
          </li>
          <li className="iconItem">
            <Icon iconName={IconEnum.document} />
            <Trans>
              <p>{t("homePageStep3")}</p>
            </Trans>
          </li>
        </ul>

        <Button aria-label="home-btn" onClick={() => setRedirectLogin(true)} className="btn homePage__btn btn--upper-margin">
          {t("homePageBtn")}
        </Button>

        <Button aria-label="Login button" onClick={() => setRedirectLogin(true)} className="btn btn--secondary btn--upper-margin">
          {t("homePageLoginBtn")}
        </Button>
      </Section>
      <Section>
        <h2 className="homePage__title">{t("homePageWho")}</h2>
        <Trans>
          <p className="homePage__p">{t("homePageWhoDesc1")}</p>
          <p className="homePage__p">{t("homePageWhoDesc2")}</p>
          <p className="homePage__p">{t("homePageWhoDesc3")}</p>
        </Trans>
      </Section>
      <Section>
        <ContactUs />
      </Section>
      <Footer />
      <RedirectLogin open={redirectLogin} setOpen={setRedirectLogin} />
    </LandingPage>
  );
};

export default HomePage;
