import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { logout as logoutEndpoint } from "../../API/Endpoints";
import { CookieStorage } from "../../components/services/Storage";
import { DASHBOARD } from "../../navigation/utils/RouteConstants";

interface props {
  logout?: boolean;
}

/**
 * Intercepts Back Button Functionality. Intended to be used in page components that uses history.
 * Use of a param depends on what the intercepted back button functionality does
 * @param logout: force user to logout out of app on backbutton
 */
export const useInterceptBackButton = ({  logout = false }: props) => {
  const history = useHistory();

  useEffect(() => {
    return () => {
      // && history.location.pathname === "any specific path")
      if (history.action === "POP") {
        if (logout) {
          window.location.assign(DASHBOARD)
          logoutEndpoint(CookieStorage.get("token") as string);
          CookieStorage.remove("token");
        } 
      }
    };
  }, [history, logout])
  

};
