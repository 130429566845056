import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: './i18n/{{lng}}.json',
  },

  fallbackLng: "default",
  debug: false,

    // have a common namespace used around the full app
    ns: ["common"],
    defaultNS: "common",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },

    react: {
      transEmptyNodeValue: "i18n empty", // what to return for empty Trans
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"], // don't convert to <1></1> if simple react elements
    },
  });

export default i18n;
