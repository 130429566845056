import { RouteComponentProps } from "react-router";
import { RouteTypes } from "../../types/RouteTypes";
import { CHILD_ASSENT_CHILD_VIEW, FLOW_EXPLAINED, HOME } from "../../utils/RouteConstants";

export const childLearnController = (
  types: RouteTypes,
  history: RouteComponentProps["history"]
) => {
  if (types.i_mailParent) history.push(FLOW_EXPLAINED);
  else if(types.i_missingAssent) history.push(FLOW_EXPLAINED);
  else history.push(HOME);
};

export const GoToAssent = (history: RouteComponentProps["history"]) => {
  history.push(CHILD_ASSENT_CHILD_VIEW);
};
