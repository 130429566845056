import { useTranslation } from "react-i18next";
import { useUser } from "../../context/UserContext/UserContext";
import { UserRelated } from "../../context/UserContext/UserTypes";
import { AgreementSection } from "../../navigation/pages/LandingPage.wrapper";
import { FirstName } from "../../utils/SplitName";
import Icon, { IconEnum } from "../Icon";
import Mailto from "../Mailto";

interface AgreementProps {
  child: UserRelated
} 


const MissingAssent = ({ child }: AgreementProps) => {
  const [t] = useTranslation("common");

  const {parent} = useUser();
  return (
    <AgreementSection>
      <Icon iconName={IconEnum.bell} className="homeAuth__icon" withIconClass={false} />
      <h2 className="homeAuth__missingAgreementTitle">{t("homeMissingAssentTitle", {child: child.name})}</h2>
      <p className="homeAuth__p homeAuth__p--spacing">{t("homeMissingAssentDesc")}</p>
      <Mailto
        withIcon={false}
        subject={t("parentSendEmailSubject")}
        body={t("parentSendEmailBody", {
          childName: FirstName(child.name),
          parentName: FirstName(parent?.name as string),
          url: window.location.origin,
        })}
      >
        {<p className="landingPage__link ">{t("homeResendInstructions")}</p>}
      </Mailto>
    </AgreementSection>
  );
};

export default MissingAssent;
