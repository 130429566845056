import React from "react";
import { useUtil } from "../context/UtilContext/UtilContext";
import TrackAnalytics from "./Analytics/TrackAnalytics";

interface SpanProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  className?: string;
}

/**
 * Custom button that powers analytics under the hood
 * @param children anything
 * @returns
 */
const Span = ({ children, ...props }: SpanProps) => {
  const { pageName } = useUtil();

  const overrideOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // button children are always used as strings
    TrackAnalytics(e.type, `${children}`, pageName.current);

    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <span {...props} onClick={overrideOnClick}>
      {children}
    </span>
  );
};

export default Span;
