import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button";
import { REVIEW_AGREEMENTS_PHN_PARENT_INFO } from "../../../utils/RouteConstants";
import FlowPage, {
  Body,
  Title,
  Desc,
  AlertContainer,
  AcceptAlertContainer,
  BtnContainer,
  Header,
  ContactUs,
} from "../../FlowPage.wrapper";

const ReviewAgreementsPhnChildInfo = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleNext = () => {
    history.push(REVIEW_AGREEMENTS_PHN_PARENT_INFO);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <FlowPage pageName={ReviewAgreementsPhnChildInfo.name}>
      <Header />
      <Body>
        <AcceptAlertContainer>
          {t("reviewAgreementAlertText")}
        </AcceptAlertContainer>
        <Desc>
        <Title>{t("phnAdultTitle")}</Title>
          <p className="flowPage__p">{t("phnChildDesc1")}</p>
          <p className="flowPage__p">
            <Trans>{t("phnChildDesc2-review-version")}</Trans>
          </p>
          <ContactUs textLeft />
          <AlertContainer>{t("phnChildAlert")}</AlertContainer>
        </Desc>
        <BtnContainer reverse>
          <Button className="btn" onClick={() => handleNext()}>
            {t("reviewAgreementBtnPrimary")}
          </Button>
          <Button className="btn" onClick={() => handleGoBack()}>
            {t("reviewAgreementBtnSecondary")}
          </Button>
        </BtnContainer>
      </Body>
    </FlowPage>
  );
};

export default ReviewAgreementsPhnChildInfo;
