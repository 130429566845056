import moment, { unix } from "moment";

export const formatDateAndAddOneYear = (unixTime: number) => {
  return moment.unix(unixTime).add(1, "years").format("YYYY-MM-DD");
};

export const formatDate = (unixTime: number) => {
  return moment.unix(unixTime).format("YYYY-MM-DD");
};

/**
 * Utility Fn that checks if agreement is expired by 1 year with the granularity of 1 day.
 * @param agreementDate is a number that represents the unix time in seconds
 * @param name is a string that determines if the user is null
 * @returns boolean | undefined
 */
export const hasAgreementExpired = (agreementDate: number, name: string) => {
  const expiryDate = unix(agreementDate).add(1, "years")
  // console.log(moment.unix(currentUnixTime).isSameOrAfter(moment.unix(expiryDate), "day"));
  //Check if name is set otherwise, the currentUnixTime is default and we want to return
  if(name === "") {
    return;
  }

  
  if (agreementDate === 0) {
    // to check if the currentUnixTime is null
    // the BE responses with a agreementDate: 0 if the user has stopped-participating
    // so technically this is to prevent expired card to show up
    return false;
  }

  // if today is same or greater than expiryDate (given a day granularity) return true
  if (moment().isSameOrAfter((expiryDate), "day")) {
    return true;
  } else {
    return false;
  }
};
