import { RouteComponentProps } from "react-router";
import {
  CHILD_LEARN, CHOOSE_PARENT, FLOW_EXPLAINED,
} from "../../utils/RouteConstants";



export const caIntroGoToChooseParent = (history: RouteComponentProps["history"]) => {
  history.push(CHOOSE_PARENT);
};
export const caIntroGoToTogetherRoute = (history: RouteComponentProps["history"]) => {
  history.push(FLOW_EXPLAINED);
};

export const caIntroGoToMailRoute = (history: RouteComponentProps["history"]) => {
  history.push(CHILD_LEARN);
};
