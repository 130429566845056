/**
 * Utility Fn to help with handling both views depending on who is logged in.
 * @param parentView is a JSX.Element that is the parent's view.
 * @param childView is a JSX.Element that is the child's view.
 * @param isParent (suggestion: use UserRole type) is a boolean value to determine if logged in user is a parent.
 * @returns JSX.Element
 */

export const handleView = (
  parentView: () => JSX.Element,
  childView: () => JSX.Element,
  adultView: () => JSX.Element,
  isParent: boolean,
  isAdult: boolean
) => {
  let view;

  if (isParent) {
    view = parentView();
  } else if(isAdult){
    view = adultView();
  }
   else {
    view = childView();
  }

  return view;
};
