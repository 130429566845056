/*
- Simple PHN Validator hook that displays an input with
  underscores, and as the user types the PHN it removes
  the underscores

handleValidate(PHN) -> Returns Input Box with validation
                                       and PHN Number 

+ Use regex to check 10 digit PHN Number.
*/

export const isThisPhnValid = (phn: string | null) => {
  if (phn === null) {
    return false;
  }

  const validation1 = phn?.split("").every((char) => {
    return typeof Number(char) === "number";
  });

  const validation2 = phn?.length === 12;

  return validation1 && validation2;
};
