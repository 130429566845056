import { RouteComponentProps } from "react-router";
import { RouteTypes } from "../../types/RouteTypes";
import { FLOW_NEEDED, FLOW_NEEDED_ADULT } from "../../utils/RouteConstants";

export const caExplainedAdultAndBabyController = (
  types: RouteTypes,
  history: RouteComponentProps["history"]
) => {
  if(types.i_adult) history.push(FLOW_NEEDED_ADULT);
  else if(types.d_baby) history.push(FLOW_NEEDED);

};
