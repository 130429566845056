import { useCallback } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useUser } from "../../../context/UserContext/UserContext";
import { UserRelated, UserTypes } from "../../../context/UserContext/UserTypes";
import { useUtil } from "../../../context/UtilContext/UtilContext";
import { hasAgreementExpired } from "../../../utils/formatTime";
import { checkWithBaby } from "../../../utils/handleChildAge";

const useDashboardCardController = () => {
  const {
    setFullAgreement,
    setMissingAssent,
    setMissingConsent,
    setMissingAgreement,
    setExpiredAgreement,
  } = useUtil();

  const { parent } = useUser();
  const handleError = useErrorHandler();

  if (parent === undefined) {
    throw handleError({message: "Consented Parent is undefined"});
  }
  const ChildMainUserConditions = useCallback(
    (user: UserTypes) => {
      if (user.consent && checkWithBaby(user.assent, user.age)) {
        if (hasAgreementExpired(user.agreementDate, user.name)) {
          setExpiredAgreement((prevAgreement) => [user, ...prevAgreement]);
        }

        if (
          !hasAgreementExpired(user.agreementDate, user.name) &&
          !hasAgreementExpired(parent.agreementDate, parent.name)
        ) {
          setFullAgreement((prevAgreement) => [user, ...prevAgreement]);
        }
      } else if (checkWithBaby(user.assent, user.age) && !user.consent) {
        setMissingConsent((prevAgreement) => [user, ...prevAgreement]);
      } else {
        setMissingAgreement((prevAgreement) => [user, ...prevAgreement]);
      }
    },
    [
      setExpiredAgreement,
      setFullAgreement,
      setMissingAgreement,
      setMissingConsent,
      parent,
    ]
  );

  const ParentMainUserConditions = useCallback(
    (children: UserRelated[]) => {
      children.forEach((child) => {
        // this handles multi-child use cases
        if (child.consent && checkWithBaby(child.assent, child.age)) {
          if (hasAgreementExpired(child.agreementDate, child.name)) {
            setExpiredAgreement((prevAgreement) => [child, ...prevAgreement]);
          }

          if (
            !hasAgreementExpired(child.agreementDate, child.name) &&
            !hasAgreementExpired(parent.agreementDate, parent.name)
          ) {
            setFullAgreement((prevAgreement) => [child, ...prevAgreement]);
          }
        } else if (checkWithBaby(!child.assent, child.age) && child.consent) {
          setMissingAssent((prevAgreement) => [child, ...prevAgreement]);
        } else {
          setMissingAgreement((prevAgreement) => [child, ...prevAgreement]);
        }
      });
      if (hasAgreementExpired(parent.agreementDate, parent.name)) {
        setExpiredAgreement((prevAgreement) => [parent, ...prevAgreement]);
      }
    },
    [
      setExpiredAgreement,
      setFullAgreement,
      setMissingAgreement,
      setMissingAssent,
      parent,
    ]
  );

  return {
    InitializeParentController: ParentMainUserConditions,
    InitializeChildController: ChildMainUserConditions,
  };
};

export default useDashboardCardController;
