import { useTranslation } from "react-i18next";
import { handleApostropheInName } from "../utils/handleApostropheInName";
import Modal, { BaseModalProps } from "./Modal";
import Button from "../components/Button";

interface NoAssentModalProps extends BaseModalProps {
  childName: string;
  onClickHandler: () => void;
}

const NoAssent = ({ open, setOpen, childName, onClickHandler }: NoAssentModalProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(false);
    onClickHandler();
  };
  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Content>
        <Modal.Title>
          {t("noAssentTitle", {
            child: childName,
            s: handleApostropheInName(childName),
          })}
        </Modal.Title>
        <Modal.Desc textLeft>
          <p className="flowPage__p">
            {t("noAssentDesc1", {
              child: childName,
              s: handleApostropheInName(childName),
            })}
          </p>
          <p className="flowPage__p">{t("noAssentDesc2")}</p>
        </Modal.Desc>
      </Modal.Content>
      <Modal.BtnContainer>
        <Button onClick={() => handleClick()} className="btn">
          {t("continue")}
        </Button>
      </Modal.BtnContainer>
    </Modal>
  );
};

export default NoAssent;
