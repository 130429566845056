import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import Mailto from "../../../components/Mailto";
import { useRoute } from "../../../context/RouteContext/RouteContext";
import { useUser } from "../../../context/UserContext/UserContext";
import { isBaby } from "../../../utils/handleChildAge";
import { caIntroGoToMailRoute } from "../../routing/Flow/intro.logic";
import { FLOW_EXPLAINED } from "../../utils/RouteConstants";
import FlowPage, { Body, BtnContainer, Desc, Header, Title } from "../FlowPage.wrapper";

function ConsentAssentIntro() {
  const { t } = useTranslation();
  const { setMailRefer, mailRefer, parent, children } = useUser();
  const { routeScenario } = useRoute();

  const history = useHistory();

  function handleNext() {
    setMailRefer(false);
    // handleRouteScenario()
    history.push(FLOW_EXPLAINED);
  }

  const mailChildren = () => {
    // handleRouteScenario();
    setMailRefer(true);
    console.log("email sent");

    caIntroGoToMailRoute(history);

    console.log(routeScenario, mailRefer, "check routeScenario & mailRefer");
  };

  useEffect(() => {
    setMailRefer(false);
  }, [setMailRefer]);

  // Child And Parent name
  const childName = children.length && children[0].name;
  const parentName = parent?.name;

  const isAnyChildaBaby = children.some((child) => isBaby(child.age));

  return (
    <FlowPage pageName={ConsentAssentIntro.name}>
      <Header />
      <Body>
        <Title>{t("caIntroTitle")}</Title>
        <Desc>
          <p className="flowPage__p">{t("caIntroDesc1")}</p>
          <p className="flowPage__p">
            <Trans>{t("caIntroDesc2")}</Trans>
          </p>
        </Desc>
        <BtnContainer>
          <Button className="btn" onClick={handleNext}>
            {t("togetherWithChild")}
          </Button>
          {!isAnyChildaBaby && (
            <Mailto
              onClick={mailChildren}
              className="btn btn--primary"
              subject={t("parentSendEmailSubject")}
              body={t("parentSendEmailBody", { parentName, childName, url: window.location.origin })}
              withCenter={true}
            >
              {t("mailToChild")}
            </Mailto>
          )}
        </BtnContainer>
      </Body>
    </FlowPage>
  );
}

export default ConsentAssentIntro;
