import { RouteComponentProps } from "react-router";
import { RouteTypes } from "../../types/RouteTypes";
import { FLOW_NEEDED, HOME } from "../../utils/RouteConstants";

export const caExplainedController = (
  types: RouteTypes,
  history: RouteComponentProps["history"]
) => {
  if(types.d_child) history.push(FLOW_NEEDED);
  else if(types.d_baby) history.push(FLOW_NEEDED);
  else if(types.i_child) history.push(FLOW_NEEDED);
  else if(types.d_missingConsent) history.push(FLOW_NEEDED)
  else if(types.i_missingAssent) history.push(FLOW_NEEDED);
  else if(types.d_mailChild) history.push(FLOW_NEEDED)
  else if(types.i_mailParent) history.push(FLOW_NEEDED)

  else history.push(HOME)


};
