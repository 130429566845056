import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  body: {
    paddingHorizontal: 40,
    paddingVertical: 20,
    fontSize: 11,
    fontFamily: "Helvetica",
  },
  headerImg: {
    width: "100%",
    objectFit: "contain",
  },
  title: {
    color: "#0673B7",
    fontSize: 18,
    marginBottom: 10,
    marginTop: 10,
    fontFamily: "Helvetica-Bold",
    textAlign: "left",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  pPolicy: {
    marginBottom: 15,
  },
  agreementText: {
    fontSize: 14,
  },
  list: {
    marginTop: 5,
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 2,
  },
  bulletItem: {
  paddingRight: 10
  },
  contactUs: {
    fontSize: 9,
    textAlign: "center",
    position: "absolute",
    bottom: 5,
    left: 0,
    right: 0,
  },
  link: {
    color: "#005EB8",
    fontFamily: "Helvetica-Bold",
    textDecoration: "underline",
  },
});
