import { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { FirstName, formatNameWithUnderscores } from "../../../utils/SplitName";
import { Trans, useTranslation } from "react-i18next";
import Icon, { IconEnum } from "../../../components/Icon";
import ConsentCollection from "../../../modals/ConsentCollection";
import FlowPage, {
  Body,
  Title,
  AlertContainer,
  BtnContainer,
  Header,
  AgreementContainer,
  CheckListItem,
  CheckList,
  PageSeperator,
  ContactUs,
  EmailAlert,
} from "../FlowPage.wrapper";
import { BlobProvider } from "@react-pdf/renderer";
import PDFDocument from "../../../components/PDF/PDFDocument";
import { handleView } from "../../../utils/handleView";
import { PHN_CHILD } from "../../utils/RouteConstants";
import ChildAssentViewManager from "../../../components/Managers/ChildAssentViewManager";
import ParentConsentViewManager from "../../../components/Managers/ParentConsentViewManager";
import Button from "../../../components/Button";
import Span from "../../../components/Span";

export type UserType = "parent" | "adult_patient" | "child" | "none";

const PDFViewer = () => {
  const [dataCollectionModal, setDataCollectionModal] = useState(false);
  const { hash } = useLocation();
  const { name, date, userType: userTypeQuery, hasChildAgreedPHN: hasChildAgreedPhnQuery } = queryString.parse(hash);

  const [t] = useTranslation("common");

  const hasChildAgreedPHN = hasChildAgreedPhnQuery === "true" ? true : false;
  const userType = userTypeQuery as UserType;

  const isParent = userType === "parent";
  const isChild = userType === "child";
  const isAdult = userType === "adult_patient";

  const dataCollectionModalHandler = () => {
    setDataCollectionModal(true);
  };

  type BlobType =
    | {
        hasBlobLoaded: true;
        blob: Blob | null;
      }
    | {
        hasBlobLoaded: false;
        blob: undefined;
      };

  type PDFButtonProps = {
    children: string;
  } & BlobType;

  const PDFButtonUI = ({ children, hasBlobLoaded, blob }: PDFButtonProps) => {
    const filename = `${formatNameWithUnderscores(name as string)}_Agreement.pdf`;

    const blobToDataUrlHandler = () => {
      hasBlobLoaded &&
        blob &&
        blobToDataURL(blob, (dataUrl: string) => {
          const base_application = `data:application/pdf;`;
          const base_pdf = "base64,";
          const link = document.createElement("a");
          link.href = `${base_application}${filename + ";"}${base_pdf}${dataUrl.slice(base_application.length + base_pdf.length)}`;
          link.download = filename;
          link.click();
        });
    };

    return (
      <div className="pdf__btnContainer">
        <Button className="btn btn--OneButton" onClick={blobToDataUrlHandler}>
          {children}
          <Icon iconName={IconEnum.download} withIconClass={false} className="pdf__btnIcon" />
        </Button>
      </div>
    );
  };

  function blobToDataURL(blob: Blob | null, callback: any) {
    if (!blob) {
      return;
    }

    var a = new FileReader();
    a.onload = function (e) {
      e.target && callback(e.target.result);
    };
    a.readAsDataURL(blob);
  }

  const downloadButtonHandler = () => (
    <BlobProvider
      document={<PDFDocument name={name as string} agreementDate={date as string} isParent={isParent} isChild={isChild} isAdult={isAdult} hasChildAgreedPHN={hasChildAgreedPHN} />}
    >
      {({ blob, url, loading, error }) => {
        if (loading) {
          return (
            <PDFButtonUI blob={undefined} hasBlobLoaded={false}>
              {t("loadingDocument")}
            </PDFButtonUI>
          );
        } else {
          return (
            <PDFButtonUI blob={blob as Blob} hasBlobLoaded={true}>
              {t("download")}
            </PDFButtonUI>
          );
        }
      }}
    </BlobProvider>
  );

  // Views on user-role
  const parentView = () => (
    <ParentConsentViewManager
      feature_type="PDF_VIEWER"
      parentName={name as string}
      agreementDate={date as string}
      phnInfoPage={hasChildAgreedPHN ? PhnParentInfoPage() : <></>}
      button_primary={downloadButtonHandler()}
      button_data_collection={
        <Trans
          components={{
            a: (
              <Span
                onClick={dataCollectionModalHandler}
                className="flowPage__links"
              />
            ),
          }}
        >
          {t("parentConsentDesc2")}
        </Trans>
      }
      modals={[<ConsentCollection open={dataCollectionModal} setOpen={setDataCollectionModal} />]}
    />
  );

  const childView = () => (
    <ChildAssentViewManager
      feature_type="PDF_VIEWER"
      childName={name as string}
      agreementDate={date as string}
      phnInfoPage={hasChildAgreedPHN ? PhnChildInfoPage() : <></>}
      button_primary={downloadButtonHandler()}
    />
  );

  const adultPatientView = () => (
    <FlowPage withOneButtonLayout={true} anchorButtonGroup={false} pageName={PDFViewer.name}>
      <Header />
      <Body>
        <AgreementContainer name={name as string} date={date as string} />
        <Title>
          {t("PrintReviewTitle", {
            name: FirstName(name as string),
          })}
        </Title>
        <p className="flowPage__p flowPage__p--policy">{t("adultConsentDesc1")}</p>
        <p className="flowPage__p flowPage__p--policy">
          <Trans
            components={{
              a: <Button onClick={dataCollectionModalHandler} className="flowPage__links" ></Button>,
            }}
          >
            {t("adultConsentDesc2")}
          </Trans>
        </p>
        <p className="flowPage__p flowPage__p--policy">
          <Trans>{t("adultConsentDesc3")}</Trans>
        </p>

        <CheckList>
          <CheckListItem>{t("adultConsentList1")}</CheckListItem>
          <CheckListItem>{t("adultConsentList2")}</CheckListItem>
          <CheckListItem>{t("parentConsentList3")}</CheckListItem>
          <CheckListItem>{t("adultConsentList4")}</CheckListItem>
          <CheckListItem>{t("parentConsentList5")}</CheckListItem>
          <CheckListItem>{t("parentConsentList7")}</CheckListItem>
          <CheckListItem>{t("parentConsentList8")}</CheckListItem>
          <CheckListItem>{t("parentConsentList9")}</CheckListItem>
          <CheckListItem>{t("parentConsentList10")}</CheckListItem>
        </CheckList>
        <EmailAlert />
        <ContactUs />
      </Body>

      {/* 1st page ends */}
      {hasChildAgreedPHN && (
        <>
          <PageSeperator />
          <Header pathOverride={PHN_CHILD} />
          <Body>
            <Title>{t("phnAdultTitle")}</Title>
            <p className="flowPage__p">{t("phnAdultDesc1")}</p>
            <p className="flowPage__p">{t("phnAdultDesc2")}</p>
            <p className="flowPage__p">{t("phnParentDesc3")}</p>
            <p className="flowPage__p">{t("phnAdultDesc4")}</p>
            <p className="flowPage__p">
              <Trans>{t("phnParentDesc5")}</Trans>
            </p>
            <ul>
              <li className="flowPage__p flowPage__bulletItems">{t("phnAdultDesc6")}</li>
              <li className="flowPage__p flowPage__bulletItems">{t("phnAdultDesc7")}</li>
            </ul>
            <ContactUs textLeft />
            <AlertContainer>{t("phnAdultAlert")}</AlertContainer>
            <EmailAlert />
            {/* 2nd page ends */}
          </Body>
        </>
      )}

      <Body>
        <BtnContainer className="pt-0">{downloadButtonHandler()}</BtnContainer>
      </Body>
      <ConsentCollection open={dataCollectionModal} setOpen={setDataCollectionModal} />
    </FlowPage>
  );

  const PhnChildInfoPage = () => {
    return isChild ? (
      <>
        <PageSeperator />
        <Header pathOverride={PHN_CHILD} />
        <Body>
          <Title>{t("phnAdultTitle")}</Title>
          <p className="flowPage__p">{t("phnChildDesc1")}</p>
          <p className="flowPage__p">
            <Trans>{t("phnChildDesc2-review-version")}</Trans>
          </p>
          <ContactUs textLeft />
          <AlertContainer>{t("phnChildAlert")}</AlertContainer>
          <EmailAlert />
          {/* 2nd page ends */}
        </Body>
      </>
    ) : (
      <></>
    );
  };

  // Phn-Info Page Views
  const PhnParentInfoPage = () => {
    return (
      <>
        <PageSeperator />
        <Header pathOverride={PHN_CHILD} />
        <Body>
          <Title>{t("phnParentTitle")}</Title>
          <p className="flowPage__p">{t("phnParentDesc1")}</p>
          <p className="flowPage__p">{t("phnParentDesc2")}</p>
          <p className="flowPage__p">{t("phnParentDesc3")}</p>
          <p className="flowPage__p">{t("phnParentDesc4")}</p>
          <p className="flowPage__p">
            <Trans>{t("phnParentDesc5")}</Trans>
          </p>
          <ul>
            <li className="flowPage__p flowPage__bulletItems">{t("phnParentDesc6")}</li>
            <li className="flowPage__p flowPage__bulletItems">{t("phnParentDesc7")}</li>
          </ul>
          <ContactUs textLeft />
          <AlertContainer>{t("phnParentAlert")}</AlertContainer>
          <EmailAlert />
          {/* 2nd page ends */}
        </Body>
      </>
    );
  };

  return handleView(parentView, childView, adultPatientView, isParent, isAdult);
};

export default PDFViewer;
