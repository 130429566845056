import { Page, Text, View, Image } from "@react-pdf/renderer";
import { Trans, useTranslation } from "react-i18next";
import FlowPage, {
  ProgressBar,
  Body,
  Desc,
  Title,
  CheckList,
  CheckListItem,
  AlertContainer,
  EmailAlert,
  BtnContainer,
  Header,
  ContactUs,
  AgreementContainer,
  AcceptAlertContainer,
} from "../../navigation/pages/FlowPage.wrapper";
import { ContactUs as ContactUsPDF, CheckListItem as CheckListItemPDF } from "../PDF/PDFDocument";
import { FirstName } from "../../utils/SplitName";
import { IconEnum } from "../Icon";
import { styles } from "../PDF/Styles";
import header from "../../images/flow/parent_consent.png";
import {
  CONSENT_FLOW,
  PDF_DOCUMENT,
  PDF_VIEWER,
  REVIEW_AGREEMENT,
} from "./FeatureTypes";

type ParentConsentFlow = {
  feature_type: CONSENT_FLOW;
  parentName: string;
  phnInfoPage?: JSX.Element;
  button_primary: JSX.Element;
  button_secondary: JSX.Element;
  button_data_collection: JSX.Element;
  requestLoading: boolean;
  modals: Array<JSX.Element>;
  agreementDate?: string;
};

type ParentPDFViewer = {
  feature_type: PDF_VIEWER;
  parentName: string;
  phnInfoPage: JSX.Element;
  button_primary: JSX.Element;
  button_secondary?: JSX.Element;
  button_data_collection: JSX.Element;
  requestLoading?: boolean;
  modals: Array<JSX.Element>;
  agreementDate: string;
};

type ParentPDFDocument = {
  feature_type: PDF_DOCUMENT;
  parentName: string;
  phnInfoPage?: JSX.Element;
  button_primary?: JSX.Element;
  button_secondary?: JSX.Element;
  button_data_collection?: JSX.Element;
  requestLoading?: boolean;
  modals?: Array<JSX.Element>;
  agreementDate: string;
};

type ParentReviewAgreement = {
  feature_type: REVIEW_AGREEMENT;
  parentName: string;
  phnInfoPage?: JSX.Element;
  button_primary: JSX.Element;
  button_secondary: JSX.Element;
  button_data_collection: JSX.Element;
  requestLoading?: boolean;
  modals: Array<JSX.Element>;
  agreementDate?: string;
};

type Props =
  | ParentConsentFlow
  | ParentPDFViewer
  | ParentPDFDocument
  | ParentReviewAgreement

const ParentConsentViewManager = ({
  feature_type,
  modals,
  parentName,
  requestLoading,
  button_primary,
  button_secondary,
  button_data_collection,
  agreementDate,
  phnInfoPage,
}: Props) => {
  const { t } = useTranslation();

  const ConsentFlow = () => (
    <FlowPage loading={requestLoading} anchorButtonGroup={false} pageName={FlowPage.name}>
      <Header />
      <ProgressBar
        level={4}
        description={t("progressBar3C")}
        icon={IconEnum.document}
      />
      <Body>
        <Desc>
          <Title>
            {t("parentConsentTitle", {
              parent: FirstName(parentName),
            })}
          </Title>
          <p className="flowPage__p flowPage__p--policy">
            {t("parentConsentDesc1")}
          </p>
          <p className="flowPage__p flowPage__p--policy">
            {button_data_collection}
          </p>
          <p className="flowPage__p flowPage__p--policy">
            <Trans>{t("parentConsentDesc3")}</Trans>
          </p>

          <CheckList>
            <CheckListItem>{t("parentConsentList1")}</CheckListItem>
            <CheckListItem>{t("parentConsentList2")}</CheckListItem>
            <CheckListItem>{t("parentConsentList3")}</CheckListItem>
            <CheckListItem>{t("parentConsentList4")}</CheckListItem>
            <CheckListItem>{t("parentConsentList5")}</CheckListItem>
            <CheckListItem>{t("parentConsentList7")}</CheckListItem>
            <CheckListItem>{t("parentConsentList8")}</CheckListItem>
            <CheckListItem>{t("parentConsentList9")}</CheckListItem>
            <CheckListItem>{t("parentConsentList10")}</CheckListItem>
          </CheckList>
          <EmailAlert />
          <ContactUs />
        </Desc>
        <BtnContainer reverse>
          {button_primary}
          {button_secondary}
        </BtnContainer>
      </Body>
      {modals?.map((modal) => {
        return modal;
      })}
    </FlowPage>
  );

  const PDFDocument = () => {
    const name = parentName;
    return (
      <Page wrap={false} size="LETTER" style={styles.page}>
      <View>
        <Image src={header} style={styles.headerImg} />
      </View>
      <View style={styles.body}>
        <Text style={styles.agreementText}>
          <Trans
            components={{
              strong: <Text style={styles.bold} />,
            }}
          >
            {t("agreedOn", { date: agreementDate })}
          </Trans>
        </Text>
        <Text style={styles.agreementText}>
          <Trans
            components={{
              strong: <Text style={styles.bold} />,
            }}
          >
            {t("agreedBy", { user: name })}
          </Trans>
        </Text>
        {/* Title */}
        <Text style={styles.title}>
          {t("PrintReviewTitle", { name: FirstName(name) })}
        </Text>
        {/* Policy Block */}
        <Text style={styles.pPolicy}>
          <Trans
            components={{
              strong: <Text style={styles.bold} />,
            }}
          >
            {t("parentConsentDesc1")}
          </Trans>
        </Text>
        <Text style={styles.pPolicy}>
            <Trans
              components={{
                strong: <Text style={styles.bold} />,
              }}
            >
              {t("parentConsentDesc2-NoLink")}
            </Trans>
          </Text>
        <Text style={styles.pPolicy}>{t("parentConsentDesc3")}</Text>
        {/* CheckList */}
        <View style={styles.list}>
          <CheckListItemPDF>{t("parentConsentList1")}</CheckListItemPDF>
          <CheckListItemPDF>{t("parentConsentList2")}</CheckListItemPDF>
          <CheckListItemPDF>{t("parentConsentList3")}</CheckListItemPDF>
          <CheckListItemPDF>{t("parentConsentList4")}</CheckListItemPDF>
          <CheckListItemPDF>{t("parentConsentList5")}</CheckListItemPDF>
          <CheckListItemPDF>{t("parentConsentList7")}</CheckListItemPDF>
          <CheckListItemPDF>{t("parentConsentList8")}</CheckListItemPDF>
          <CheckListItemPDF>{t("parentConsentList9")}</CheckListItemPDF>
          <CheckListItemPDF>{t("parentConsentList10")}</CheckListItemPDF>
        </View>
      </View>
      <ContactUsPDF t={t} />
    </Page>);
  };
  const PDFViewer = () => {
    const name = parentName;
    const date = agreementDate;
    return (
      <FlowPage withOneButtonLayout={true} anchorButtonGroup={false} pageName={PDFViewer.name}>
        <Header />
        <Body>
          <AgreementContainer name={name as string} date={date as string} />
          <Title>
            {t("PrintReviewTitle", {
              name: FirstName(name as string),
            })}
          </Title>
          <p className="flowPage__p flowPage__p--policy">
            {t("parentConsentDesc1")}
          </p>
          <p className="flowPage__p flowPage__p--policy">
            {button_data_collection}
          </p>
          <p className="flowPage__p flowPage__p--policy">
            <Trans>{t("parentConsentDesc3")}</Trans>
          </p>

          <CheckList>
            <CheckListItem>{t("parentConsentList1")}</CheckListItem>
            <CheckListItem>{t("parentConsentList2")}</CheckListItem>
            <CheckListItem>{t("parentConsentList3")}</CheckListItem>
            <CheckListItem>{t("parentConsentList4")}</CheckListItem>
            <CheckListItem>{t("parentConsentList5")}</CheckListItem>
            <CheckListItem>{t("parentConsentList7")}</CheckListItem>
            <CheckListItem>{t("parentConsentList8")}</CheckListItem>
            <CheckListItem>{t("parentConsentList9")}</CheckListItem>
            <CheckListItem>{t("parentConsentList10")}</CheckListItem>
          </CheckList>
          <AlertContainer>{t("childAssentAlert")}</AlertContainer>
          <EmailAlert />
          <ContactUs />
        </Body>
        {/* 1st page ends */}
        {phnInfoPage}

        <Body>
          <BtnContainer className="pt-0">{button_primary}</BtnContainer>
        </Body>
        {modals?.map((modal) => {
          return modal;
        })}
      </FlowPage>
    );
  };
  const ReviewAgremeent = () => {
    return (
      <FlowPage pageName={ReviewAgremeent.name}>
        <Header />
        <Body>
          <AcceptAlertContainer>
            {t("reviewAgreementAlertText")}
          </AcceptAlertContainer>
          <Desc>
            <Title>
              {t("parentConsentTitle", {
                parent: FirstName(parentName),
              })}
            </Title>
            <p className="flowPage__p flowPage__p--policy">
              {t("parentConsentDesc1")}
            </p>
            <p className="flowPage__p flowPage__p--policy">
              {button_data_collection}
            </p>
            <p className="flowPage__p flowPage__p--policy">
              <Trans>{t("parentConsentDesc3")}</Trans>
            </p>

            <CheckList>
              <CheckListItem>{t("parentConsentList1")}</CheckListItem>
              <CheckListItem>{t("parentConsentList2")}</CheckListItem>
              <CheckListItem>{t("parentConsentList3")}</CheckListItem>
              <CheckListItem>{t("parentConsentList4")}</CheckListItem>
              <CheckListItem>{t("parentConsentList5")}</CheckListItem>
              <CheckListItem>{t("parentConsentList7")}</CheckListItem>
              <CheckListItem>{t("parentConsentList8")}</CheckListItem>
              <CheckListItem>{t("parentConsentList9")}</CheckListItem>
              <CheckListItem>{t("parentConsentList10")}</CheckListItem>
            </CheckList>
            <AlertContainer>{t("childAssentAlert")}</AlertContainer>
            <EmailAlert />
            <ContactUs />
          </Desc>
          <BtnContainer reverse>
            {button_primary}
            {button_secondary}
          </BtnContainer>
        </Body>
        {modals?.map((modal) => {
          return modal;
        })}
      </FlowPage>
    );
  };
  switch (feature_type) {
    case "CONSENT_FLOW":
      return <ConsentFlow />;
    case "PDF_DOCUMENT":
      return <PDFDocument />;
    case "PDF_VIEWER":
      return <PDFViewer />;
    case "REVIEW_AGREEMENT":
      return <ReviewAgremeent />;
  }
};

export default ParentConsentViewManager;
