import { RouteComponentProps } from "react-router";
import { RouteTypes } from "../../types/RouteTypes";
import {
  FLOW_EXPLAINED,
  HOME,
  PARENT_CONSENT_PARENT_VIEW,
} from "../../utils/RouteConstants";

export const parentLearnController = (
  types: RouteTypes,
  history: RouteComponentProps["history"]
) => {
  if (types.d_mailChild) history.push(FLOW_EXPLAINED);
  else if (types.d_missingConsent) history.push(FLOW_EXPLAINED);
  else history.push(HOME);
};

export const GoToConsent = (history: RouteComponentProps["history"]) => {
  history.push(PARENT_CONSENT_PARENT_VIEW);
};
