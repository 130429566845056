import { useTranslation } from "react-i18next";
import Modal, { BaseModalProps } from "./Modal";
import Button from "../components/Button";

interface NoConsentModalProps extends BaseModalProps {
  onClickHandler: () => void;
}

const NoAdultConsent = ({ open, setOpen, onClickHandler }: NoConsentModalProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(false);
    onClickHandler();
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Content>
        <Modal.Title>{t("noAdultConsentTitle")}</Modal.Title>
        <Modal.Desc textLeft>
          <p className="flowPage__p">{t("noAdultConsentDesc1")}</p>
          <p className="flowPage__p">{t("noAdultConsentDesc2")}</p>
        </Modal.Desc>
      </Modal.Content>
      <Modal.BtnContainer>
        <Button onClick={() => handleClick()} className="btn">
          {t("continue")}
        </Button>
      </Modal.BtnContainer>
    </Modal>
  );
};

export default NoAdultConsent;
