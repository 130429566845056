export interface UserContextDefaults {
  userDetails: UserTypes;
  setUserDetails: (UserDetails: any) => void;
  userLoaded: boolean;
  setUserLoaded: (userLoaded: boolean) => void;
  children: Array<UserRelated>;
  setChildren: (children: Array<UserRelated> | []) => void;
  parent: undefined | UserTypes | UserRelated;
  setParent: (setParent: undefined | UserTypes | UserRelated) => void;
  mailRefer: boolean;
  setMailRefer: (mailRefer: boolean) => void;
  phnAgreement: PhnAgreementType;
  setPhnAgreement: (phnAgreement: any) => void;
}


export interface UserTypes {
  name: string;
  age: number;
  hasAgreedBefore: boolean;
  agreementDate: number;
  role: UserRole;
  related: Array<UserRelated>;
  consent: boolean;
  assent: boolean;
  consentBy: string[];
  phn: string | null;
  identifier: string;
  email: string;
  userGroup: string;
}

export type UserRelated = {
  name: string;
  age: number;
  hasAgreedBefore: boolean;
  agreementDate: number;
  role: UserRole;
  consent: boolean;
  assent: boolean;
  consentBy: string[];
  phn: string | null;
  identifier: string;
};

export type PhnAgreementType = {
  phn: string | null;
  performers: Array<string>;
  phn_consent_state: PhnConsentState;
}

export enum PhnConsentState {
  CONSENT = "CONSENT",
  CONSENT_ASSENT = "CONSENT_ASSENT",
  ASSENT = "ASSENT",
  REJECTED = "REJECTED",
  NONE = "NONE"
}


export enum UserRole {
  PATIENT = "patient",
  PARENT = "parent",
  NULL = "",
}
