import { fetchToken } from "../../API/TokenEndpoint";
import { useUser } from "../../context/UserContext/UserContext";
import { CookieStorage } from "../services/Storage";
import { ConsentNotNeededErrorPage } from "./ConsentNotNeededErrorPage";
import ErrorNoMatch from "./ErrorNoMatch";
import { ExpiredSessionErrorPage } from "./ExpiredSessionErrorPage";
import { GeneralErrorPage } from "./GeneralErrorPage";
import { NoProfileErrorPage } from "./NoProfileErrorPage";
import { NoSessionErrorPage } from "./NoSessionErrorPage";
import { AppError } from "./types/ErrorType";

interface ErrorHandlerProps {
  error: AppError;
}

const ErrorHandler = ({ error }: ErrorHandlerProps) => {
  const { errorCode } = error;
  const { userLoaded } = useUser();

  // Remove token from storage on any Error triggered.
  process.env.NODE_ENV === "production" && CookieStorage.remove("token");

  const ErrorUI = () => {
    switch (errorCode) {
      case "BAD_AUTHORIZATION":
        return <NoSessionErrorPage />;
      case "FRONTEND_SESSION_EXPIRED":
        if (!userLoaded) {
          return fetchToken();
        } else {
          return <ExpiredSessionErrorPage />;
        }
      case "PROFILE_NOT_FOUND":
        return <NoProfileErrorPage />;
      case "CONSENT_NOT_NEEDED":
        return <ConsentNotNeededErrorPage />;
      case "INTERNAL_SERVER_ERROR":
        return <GeneralErrorPage />;
      default:
        return <ErrorNoMatch error={error} />;
    }
  };

  console.log(error);
  return <div>{ErrorUI()}</div>;
};

export default ErrorHandler;
