import { RouteComponentProps } from "react-router-dom";
import { BASE_URL } from "../App";
import { CookieStorage } from "../components/services/Storage";

export const checkStorageAndRedirect = async (
  history: RouteComponentProps["history"]
) => {
  let token = CookieStorage.get("token");

  if (token) {
    history.push(`/redirect`, token);
  } else {
    fetchToken();
  }
}


export async function fetchToken() {

  window.location.assign(`${BASE_URL}/login`);
}
