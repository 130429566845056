import { RouteComponentProps } from "react-router";
import {
  PARENT_LEARN,
} from "../../utils/RouteConstants";



export const caIntroGoToMailRoute = (history: RouteComponentProps["history"]) => {
  history.push(PARENT_LEARN);
};
