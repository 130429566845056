import { Link } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types/style";
import React from "react";

interface PhoneTypes {
  phoneNumber: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  children?: React.ReactNode;
  forPDF?: boolean;
  PDFStyle?: Style | Style[];
}

const Phoneto = ({
  phoneNumber = "",
  className = "",
  onClick,
  children,
  forPDF = false,
  PDFStyle = undefined
}: PhoneTypes) => {
  if (forPDF) {
    return <Link style={PDFStyle} src={`tel:${phoneNumber}`}>{children}</Link>;
  } else {
    return (
      <a
        data-testid="phoneto"
        className={`${className} phone`}
        onClick={onClick}
        href={`tel:${phoneNumber}`}
      >
        {children}
      </a>
    );
  }
};

export default Phoneto;
