import { useTranslation } from "react-i18next";
import Modal, { BaseModalProps } from "./Modal";
import Button from "../components/Button";

const HomeDataCollection = ({ open, setOpen }: BaseModalProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Content>
        <Modal.Title>{t("homeDataCollectionTitle")}</Modal.Title>
        <Modal.Desc textLeft>
          <p className="flowPage__p">{t("homeDataCollectionDesc1")}</p>
          <ul className="flowPage__bullet">
            <li className="flowPage__bulletItems">{t("dataCollectionDesc4-1")}</li>
            <li className="flowPage__bulletItems">{t("dataCollectionDesc4-2")}</li>
          </ul>
        </Modal.Desc>
      </Modal.Content>
      <Modal.BtnContainer>
        <Button onClick={() => handleClick()} className="btn">
          {t("gotIt")}
        </Button>
      </Modal.BtnContainer>
    </Modal>
  );
};

export default HomeDataCollection;
