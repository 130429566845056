import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import { TFunction, Trans, useTranslation } from "react-i18next";

import header from "../../images/flow/parent_consent.png";
import { handleView } from "../../utils/handleView";
import { FirstName } from "../../utils/SplitName";
import Mailto from "../Mailto";
import Phoneto from "../Phoneto";
import { CheckMark } from "./Checkmark";
import PhnPageParent from "./PhnPageParent";
import PhnPageAdult from "./PhnPageAdult";
import { styles } from "./Styles";
import PhnPageChild from "./PhnPageChild";
import ParentConsentViewManager from "../Managers/ParentConsentViewManager";
import ChildAssentViewManager from "../Managers/ChildAssentViewManager";

interface PDFDocumentProps {
  name: string;
  agreementDate: string;
  isParent: boolean;
  isChild: boolean;
  isAdult: boolean;
  hasChildAgreedPHN: boolean;
}

interface ListItemProps {
  children: string;
}
interface ContactUsProps {
  t: TFunction<"common">;
}

export const CheckListItem = ({ children }: ListItemProps) => (
  <View style={styles.listItem}>
    <CheckMark />
    <Text style={styles.pPolicy}>
      <Trans
        components={{
          strong: <Text style={styles.bold} />,
        }}
      >
        {children}
      </Trans>
    </Text>
  </View>
);

export const ContactUs = ({ t }: ContactUsProps) => (
  <Text style={[styles.contactUs, styles.body]} fixed>
    <Trans
      components={{
        phone: (
          <Phoneto
            phoneNumber={t("researchTel")}
            PDFStyle={styles.link}
            forPDF={true}
          />
        ),
        email: (
          <Mailto
            email={t("researchEmail")}
            body=""
            subject={t("researchSubjectLine")}
            PDFStyle={styles.link}
            forPDF
          />
        ),
        strong: <Text style={styles.bold} />,
      }}
    >
      {t("ContactUs", {
        phoneNumber: t("researchTel"),
        emailAddress: t("researchEmail"),
      })}
    </Trans>
  </Text>
);

// Create Document Component
const PDFDocument = ({
  name,
  agreementDate,
  isParent,
  isChild,
  isAdult,
  hasChildAgreedPHN,
}: PDFDocumentProps) => {
  const { t } = useTranslation();

  const parentView = () => (
    <Document>
      <ParentConsentViewManager
        feature_type="PDF_DOCUMENT"
        agreementDate={agreementDate}
        parentName={name}
      />
      {hasChildAgreedPHN && (
        <>
          <PhnPageParent />
        </>
      )}
    </Document>
  );

  const childView = () => (
    <Document>
      <ChildAssentViewManager
        feature_type="PDF_DOCUMENT"
        agreementDate={agreementDate}
        childName={name}
      />
      {hasChildAgreedPHN && (
        <>
          <PhnPageChild isChild={isChild} />
        </>
      )}
    </Document>
  );
  const adultPatientView = () => (
    <Document>
      <Page wrap={false} size="LETTER" style={styles.page}>
        <View>
          <Image src={header} style={styles.headerImg} />
        </View>
        <View style={styles.body}>
          <Text style={styles.agreementText}>
            <Trans
              components={{
                strong: <Text style={styles.bold} />,
              }}
            >
              {t("agreedOn", { date: agreementDate })}
            </Trans>
          </Text>
          <Text style={styles.agreementText}>
            <Trans
              components={{
                strong: <Text style={styles.bold} />,
              }}
            >
              {t("agreedBy", { user: name })}
            </Trans>
          </Text>
          {/* Title */}
          <Text style={styles.title}>
            {t("PrintReviewTitle", { name: FirstName(name) })}
          </Text>
          {/* Policy Block */}
          <Text style={styles.pPolicy}>
            <Trans
              components={{
                strong: <Text style={styles.bold} />,
              }}
            >
              {t("adultConsentDesc1")}
            </Trans>
          </Text>
          <Text style={styles.pPolicy}>
            <Trans
              components={{
                strong: <Text style={styles.bold} />,
              }}
            >
              {t("adultConsentDesc2-NoLink")}
            </Trans>
          </Text>
          <Text style={styles.pPolicy}>{t("adultConsentDesc3")}</Text>
          {/* CheckList */}
          <View style={styles.list}>
            <CheckListItem>{t("adultConsentList1")}</CheckListItem>
            <CheckListItem>{t("adultConsentList2")}</CheckListItem>
            <CheckListItem>{t("parentConsentList3")}</CheckListItem>
            <CheckListItem>{t("adultConsentList4")}</CheckListItem>
            <CheckListItem>{t("parentConsentList5")}</CheckListItem>
            <CheckListItem>{t("parentConsentList7")}</CheckListItem>
            <CheckListItem>{t("parentConsentList8")}</CheckListItem>
            <CheckListItem>{t("parentConsentList9")}</CheckListItem>
            <CheckListItem>{t("parentConsentList10")}</CheckListItem>
          </View>
        </View>
        <ContactUs t={t} />
      </Page>
      {hasChildAgreedPHN && <PhnPageAdult />}
    </Document>
  );

  return handleView(parentView, childView, adultPatientView, isParent, isAdult);
};

export default PDFDocument;
