import { AxiosResponse } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import { PhnInput } from "../../../../components/PhnInput";
import { useRoute } from "../../../../context/RouteContext/RouteContext";
import { appCtxDefaultValue } from "../../../../context/UserContext/UserContext";
import {
  UserRelated,
  UserRole,
  UserTypes,
} from "../../../../context/UserContext/UserTypes";
import { routeScenarioEnum } from "../../../../hooks/RouteController/useRouteController";
import { useLeadingDebounce } from "../../../../hooks/utils/useDebounce";
import useRequestSpinner from "../../../../hooks/utils/useRequestSpinner";
import PhnLinked from "../../../../modals/PhnLinked";
import PhnLinkedPending from "../../../../modals/PhnLinkedPending";
import PhnNotLinked from "../../../../modals/PhnNotLinked";
import FlowPage, {
  Header,
  Body,
  Title,
  BtnContainer,
} from "../../FlowPage.wrapper";

interface PhnProps {
  handleOnClickAgree: () => Promise<AxiosResponse<any>>;
  handleOnClickDisagree: () => Promise<AxiosResponse<any>>;
  handleAgreeNextPage: () => void;
  handleDisagreeNextPage: () => void;
  filteredChildren?: UserRelated[];
  MainUserChild?: UserTypes;
  isValidPhn: boolean;
}

const Phn = ({
  handleOnClickAgree,
  handleOnClickDisagree,
  handleAgreeNextPage,
  handleDisagreeNextPage,
  filteredChildren = [],
  MainUserChild = appCtxDefaultValue.userDetails,
  isValidPhn,
}: PhnProps) => {
  const [linkPhnsModal, setLinkPhnsModal] = useState(false);
  const [dontLinkPhnsModal, setDontLinkPhnsModal] = useState(false);
  const [linkPendingPhnsModal, setLinkPendingPhnsModal] = useState(false);

  const {  routeScenario } = useRoute();
  const { handleRequestWithSpinner, requestLoading } = useRequestSpinner();
  const { t } = useTranslation();

  const handleSkipAgreement = () => {
    handleRequestWithSpinner(handleOnClickDisagree(), () => {
      setDontLinkPhnsModal(true);
    });
  };

  const handleNext = async () => {
    handleRequestWithSpinner(handleOnClickAgree(), () => {
      switch (routeScenario) {
        case routeScenarioEnum.d_mailChild:
          setLinkPendingPhnsModal(true);
          break;
        default:
          setLinkPhnsModal(true);
      }
    });
  };

  const ChildViewORParentView = () => {
    if (MainUserChild.age && MainUserChild.role === UserRole.PATIENT) {
      return <PhnInput isChildMainUser={true} />;
    } else {
      return filteredChildren.map((child) => {
        return <PhnInput key={child.name} name={child.name} />;
      });
    }
  };


  return (
    <FlowPage loading={requestLoading} pageName={Phn.name}>
      <Header />
      <Body>
        <Title>{t("phnTitle")}</Title>
        {ChildViewORParentView()}
        <BtnContainer reverse>
          <Button
            className={`btn ${!isValidPhn ? "btn--disabled" : ""}`}
            disabled={!isValidPhn}
            onClick={useLeadingDebounce(handleNext)}
          >
            {t("phnAgree")}
          </Button>
          <Button className="btn" onClick={useLeadingDebounce(handleSkipAgreement)}>
            {t("phnDisagree")}
          </Button>
        </BtnContainer>
      </Body>
      <PhnLinked
        open={linkPhnsModal}
        setOpen={setLinkPhnsModal}
        onClickHandler={handleAgreeNextPage}
      />
      <PhnNotLinked
        open={dontLinkPhnsModal}
        setOpen={setDontLinkPhnsModal}
        onClickHandler={handleDisagreeNextPage}
      />
      <PhnLinkedPending
        open={linkPendingPhnsModal}
        setOpen={setLinkPendingPhnsModal}
        onClickHandler={handleAgreeNextPage}
      />
    </FlowPage>
  );
};

export default Phn;
