import { UserRole } from "../context/UserContext/UserTypes";

export const isAdultPatient = (age: number, role: UserRole) => {
  if (role === UserRole.PARENT) {
    return false;
  }
  // An adult is 16 years or older
  if (age >= ADULT_AGE) {
    return true;
  } else {
    return false;
  }
};

const ADULT_AGE = 17;
const BABY_AGE = 7;

export const isChild = (age: number) => {
  if ( age >= BABY_AGE  && age < ADULT_AGE) {
    return true;
  } else {
    return false;
  }
};


export const isBaby = (age: number) => {
  return age < BABY_AGE ? true : false;
};

// Use this where assent shows up to process skipped assent for babies.
export const checkWithBaby = (assent: boolean, childAge: number) => {
  if (childAge < BABY_AGE) {
    // then skip assent
    return !assent;
  } else {
    return assent
  }
};
