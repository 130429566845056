import React from 'react';
import {Route, Redirect} from 'react-router-dom'
import { isAuthenticated } from './components/services/AuthService';
import { HOME } from './navigation/utils/RouteConstants';

export interface Props {
    component: any,
    exact: boolean,
    path: string,
  }
  

export const ProtectedRoute = ({
    component: Component,
        ...rest
    }: Props
    
    ) => (
    <Route {...rest} render={ (props) => {

            if(isAuthenticated()){
                return <Component {...props}/>
            } else {
                return <Redirect 
                        to= {{ pathname: HOME,
                        state: { from: props.location }
                    }}
                />
            }
        }
    } />       
);
