import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useRoute } from "../../context/RouteContext/RouteContext";
import { useUser } from "../../context/UserContext/UserContext";
import { ChildOrParent } from "../../context/UtilContext/UtilContext";
import useRouteControl, {
  routeScenarioEnum,
} from "../../hooks/RouteController/useRouteController";
import { AgreementSection } from "../../navigation/pages/LandingPage.wrapper";
import { isAdultPatient } from "../../utils/handleChildAge";
import { handleView } from "../../utils/handleView";
import Icon, { IconEnum } from "../Icon";
import Button from "../../components/Button";

interface AgreementProps {
  ChildrenOrParent?: Array<ChildOrParent>;
  isParent?: boolean;
}

const ExpiredAgreement = ({ ChildrenOrParent, isParent }: AgreementProps) => {
  const [t] = useTranslation("common");
  const { routeScenario } = useRoute();
  const { userDetails } = useUser();
  const history = useHistory();

  const [handleRoute] = useRouteControl(history);

  const onClickHandler = () => {
    handleRoute(routeScenario as routeScenarioEnum);

    console.log(routeScenario);
  };

  const parentView = () => (
    <AgreementSection>
      <Icon
        iconName={IconEnum.document}
        className="homeAuth__icon"
        withIconClass={false}
      />
      <h2 className="homeAuth__missingAgreementTitle">
        {t("homeExpiredAgreementTitle")}
      </h2>
      <p className="homeAuth__p ">{t("homeExpiredAgreementDesc1")}</p>
      {ChildrenOrParent?.length &&
        ChildrenOrParent.map((child, index, array) => {
          return (
            <p key={index} className={`homeAuth__p`}>
              {child.name}
            </p>
          );
        })}
      <p className="homeAuth__p homeAuth__p--spacing">
        {t("homeExpiredAgreementDesc2")}
      </p>
      <Button onClick={onClickHandler} className="btn btn--primary">
        {t("homeExpiredAgreementBtn")}
      </Button>
    </AgreementSection>
  );

  const childView = () => (
    <AgreementSection>
      <Icon
        iconName={IconEnum.document}
        className="homeAuth__icon"
        withIconClass={false}
      />
      <h2 className="homeAuth__missingAgreementTitle">
        {t("homeExpiredAgreementTitle")}
      </h2>
      <p className="homeAuth__p">
        <Trans>{t("homeExpiredAgreementDesc1-Child")}</Trans>
      </p>
      <p className="homeAuth__p homeAuth__p--spacing">
        {t("homeExpiredAgreementDesc2-Child")}
      </p>
      <Button onClick={onClickHandler} className="btn btn--primary">
        {t("homeExpiredAgreementBtn")}
      </Button>
    </AgreementSection>
  );

  return handleView(parentView, childView, childView, isParent as boolean, isAdultPatient(userDetails.age, userDetails.role));
};

export default ExpiredAgreement;
