import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { checkStorageAndRedirect } from "../API/TokenEndpoint";
import Icon, { IconEnum } from "../components/Icon";
import Modal, { BaseModalProps } from "./Modal";
import Button from "../components/Button";

// TODO: This Modal should now appear from the HomePage
const RedirectLogin = ({ open, setOpen }: BaseModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    checkStorageAndRedirect(history);
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
        <Modal.Close onClick={() => setOpen(false)} />
      <Modal.Content>
        <Icon iconName={IconEnum.phone} />
        <Modal.Title>{t("redirectModalTitle")}</Modal.Title>
        <Modal.Desc textLeft={false}>{t("redirectModalDesc")}</Modal.Desc>
      </Modal.Content>
      <Modal.BtnContainer>
        <Button aria-label="modal-next" 
          className="btn"
          onClick={() => handleClick()} 
        >
          {t("continueLogIn")}
        </Button>
      </Modal.BtnContainer>
    </Modal>
  );
};

/* TODO Fix spacing so this modal is ontop of last */

export default RedirectLogin;
