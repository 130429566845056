import { Page, Text, View, Image } from "@react-pdf/renderer";
import { Trans, useTranslation } from "react-i18next";

import header_phn from "../../images/flow/phn.png";
import { ContactUs } from "./PDFDocument";
import { styles } from "./Styles";


interface PhnPageChildProps {
  isChild: boolean;
}

const PhnPageChild = ({ isChild }: PhnPageChildProps) => {
  const { t } = useTranslation();
  return (
    isChild ? (
      <Page wrap={false} size="LETTER" style={styles.page}>
        <View>
          <Image src={header_phn} style={styles.headerImg} />
        </View>
        <View style={styles.body}>
          {/* Title */}
          <Text style={styles.title}>{t("phnAdultTitle")}</Text>
          {/* Policy Block */}

          <Text style={styles.pPolicy}>{t("phnChildDesc1")}</Text>
          <Text style={styles.pPolicy}>
            <Trans
              components={{
                strong: <Text style={styles.bold} />,
              }}
            >
              {t("phnChildDesc2-review-version")}
            </Trans>
          </Text>
        </View>
        <ContactUs t={t} />
      </Page>
    ) : <></>
  );
};

export default PhnPageChild;
