import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Button from "../../../components/Button";
import ChooseChildItem from "../../../components/ChooseChildItem";
import { useRoute } from "../../../context/RouteContext/RouteContext";
import { useUser } from "../../../context/UserContext/UserContext";
import { UserRelated, UserRole } from "../../../context/UserContext/UserTypes";

import useRouteControl, { routeScenarioEnum } from "../../../hooks/RouteController/useRouteController";
import { hasAgreementExpired } from "../../../utils/formatTime";

import FlowPage, { Body, BtnContainer, ChooseChildContainer, Desc, Header, Title } from "../FlowPage.wrapper";

const ChooseChild = () => {
  const { t } = useTranslation();
  const { userDetails, setChildren, children } = useUser();
  const [ParticipatingANDExpiredChildren, setParticipatingANDExpiredChildren] = useState<Array<UserRelated>>([]);
  const [isChooseArrayEmpty, setIsChooseArrayEmpty] = useState(false);
  const { routeScenario } = useRoute();
  const history = useHistory();

  const [handleRoute] = useRouteControl(history);

  const handleNext = () => {
    handleRoute(routeScenario as routeScenarioEnum);
    console.log(routeScenario);
    console.log(children);
  };
  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    // only show users who are children with no consent OR agreementExpired. 
    const array = userDetails.related.filter((child) => {
      return child.role === UserRole.PATIENT && (!child.consent || hasAgreementExpired(child.agreementDate, child.name));
    });
    setParticipatingANDExpiredChildren(array);
    setChildren(array);
  }, [ userDetails.related, setChildren]);

  useEffect(() => {
    if (children.length === 0) {
      setIsChooseArrayEmpty(true);
    } else if (children.length > 0) {
      setIsChooseArrayEmpty(false);
    }
  }, [children, setIsChooseArrayEmpty]);

  return (
    <>
      <FlowPage pageName={ChooseChild.name}>
        <Header />
        <Body>
          <Title>{t("chooseChildTitle")}</Title>
          <Trans>
            <Desc>
              <p className="flowPage__p--last">{t("chooseChildDesc")}</p>
            </Desc>
          </Trans>
          <ChooseChildContainer>
            {ParticipatingANDExpiredChildren.map((relatedPerson) => {
              return (
                <ChooseChildItem
                  key={relatedPerson.name}
                  child={relatedPerson}
                  setFilteredChildren={setChildren}
                  filteredChildren={children}
                />
              );
            })}
          </ChooseChildContainer>

          <BtnContainer reverse>
            <Button className={`btn ${isChooseArrayEmpty ? "btn--disabled" : ""}`} disabled={isChooseArrayEmpty} onClick={handleNext}>
              {t("next")}
            </Button>
            <Button onClick={handleBack} className="btn btn--secondary">
              {t("back")}
            </Button>
          </BtnContainer>
        </Body>
      </FlowPage>
    </>
  );
};

export default ChooseChild;
