import { useTranslation, Trans } from "react-i18next";
import FlowPage, { Body, Desc, BtnContainer, Title } from "../../navigation/pages/FlowPage.wrapper";

import errorSearchImage from "../../images/error-search-user.svg";
import { HOME } from "../../navigation/utils/RouteConstants";
import { BASE_URL } from "../../App";
import { AppError } from "./types/ErrorType";
import Button from "../Button";

interface ErrorNoMatchProps {
  error: AppError;
}

const ErrorNoMatch = ({error}: ErrorNoMatchProps) => {

  const {redirectUrl, errorCode, message, goBackText} = error;

  const { t } = useTranslation();



  const next = () => {
    if (redirectUrl) {
      window.location.href = `${BASE_URL}${redirectUrl}`;
    } else {
      window.location.assign(HOME)
    }
  };

  return (
    <FlowPage pageName={ErrorNoMatch.name}>
      <Body>
        <div data-testid="FlowPage-Header" className={` flowPage__headerImgError`}>
          <img src={errorSearchImage} alt="header" />
        </div>

        { errorCode
          ? <>
              <Title textLeft={false}>
                {message}
              </Title>
              <BtnContainer>
                <Button className="btn btn--primary" onClick={next}>
                  {goBackText}
                </Button>
              </BtnContainer>
            </>

          : <>
              <Title textLeft={false}>
                {t("errorNoMatchTitle")}
              </Title>
          
          
              <Desc textLeft>
                <p className="flowPage__p">{t("errorNoMatchDesc1")}</p>
                <Trans
                  components={{
                    a: (
                      <a href={window.config.url_faq} className="flowPage__links">
                        .
                      </a>
                    ),
                  }}
                >
                  {t("errorNoMatchDesc2")}
                </Trans>
              </Desc>
              <BtnContainer>
                <Button className="btn btn--primary" onClick={next}>
                  {t("errorNoMatchBtn")}
                </Button>
              </BtnContainer>
            </>
        }
        
      </Body>
    </FlowPage>
  );
};

export default ErrorNoMatch;
