import { AxiosResponse } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ChildAssentViewManager from "../../../../components/Managers/ChildAssentViewManager";
import { routeScenarioEnum } from "../../../../hooks/RouteController/useRouteController";
import { useLeadingDebounce } from "../../../../hooks/utils/useDebounce";
import useRequestSpinner from "../../../../hooks/utils/useRequestSpinner";
import ConsentPending from "../../../../modals/ConsentPending";
import NoAssent from "../../../../modals/NoAssent";
import NoConsentAssent from "../../../../modals/NoConsentAssent";
import SharedData from "../../../../modals/SharedData";
import { FirstName } from "../../../../utils/SplitName";
import Button from "../../../../components/Button";

interface ChildAssentProps {
  childName: string;
  handleOnClickAgree: () => Promise<AxiosResponse<any>>;
  handleOnClickDisagree: () => Promise<AxiosResponse<any>>;
  handleAgreeNextPage: () => void;
  handleDisagreeNextPage: () => void;
  routeScenario?: string;
}

const ChildAssent = ({
  childName,
  handleOnClickAgree,
  handleOnClickDisagree,
  handleAgreeNextPage,
  handleDisagreeNextPage,
  routeScenario,
}: ChildAssentProps) => {
  // State renders if user doesn't assent
  const [noAssentModal, setNoAssentModal] = useState(false);
  // States control modals that should only render on mailParent path
  const [consentPending, setConsentPending] = useState(false);
  const [consentAssentPending, setConsentAssentPending] = useState(false);
  const [sharedDataModal, setSharedDataModal] = useState(false);
  const { t } = useTranslation();

  const { handleRequestWithSpinner, requestLoading } = useRequestSpinner();

  const handleSkipAgreement = () => {
    handleRequestWithSpinner(handleOnClickDisagree(), () => {
      if (routeScenario === routeScenarioEnum.i_mailParent) {
        setConsentAssentPending(true);
      } else {
        setNoAssentModal(true);
      }
    });
  };

  const handleNext = () => {
    handleRequestWithSpinner(handleOnClickAgree(), () => {
      if (routeScenario === routeScenarioEnum.i_mailParent) {
        setConsentPending(true);
      } else if (routeScenario === routeScenarioEnum.i_missingAssent) {
        setSharedDataModal(true);
      } else {
        return handleAgreeNextPage();
      }
    });
  };


  return (
    <ChildAssentViewManager
      feature_type="CONSENT_FLOW"
      requestLoading={requestLoading}
      button_primary={
        <Button className="btn" onClick={  (useLeadingDebounce(handleNext) )}>
          {t("childAssentAgree")}
        </Button>
      }
      button_secondary={
        <Button className="btn" onClick={handleSkipAgreement}>
          {t("childAssentDisagree")}
        </Button>
      }
      childName={childName}
      modals={[
        <NoAssent
          open={noAssentModal}
          setOpen={setNoAssentModal}
          childName={FirstName(childName)}
          onClickHandler={handleDisagreeNextPage}
        />,
        <NoConsentAssent
          open={consentAssentPending}
          setOpen={setConsentAssentPending}
          onClickHandler={handleAgreeNextPage}
        />,
        <ConsentPending
          open={consentPending}
          setOpen={setConsentPending}
          onClickHandler={handleAgreeNextPage}
        />,
        <SharedData
          open={sharedDataModal}
          setOpen={setSharedDataModal}
          onClickHandler={handleAgreeNextPage}
        />,
      ]}
    />
  );
};

export default ChildAssent;

/*
    <FlowPage loading={requestLoading}>
      <Header />
      <ProgressBar
        level={3}
        description={t("progressBar3A")}
        icon={IconEnum.document}
      />
      <Body>
        <Desc>
          <Title>
            {t("childAssentTitle", {
              child: FirstName(childName),
            })}
          </Title>
          <p className="flowPage__p flowPage__p--policy">
            {t("childAssentDesc")}
          </p>
          <CheckList>
            <CheckListItem>{t("childAssentList1")}</CheckListItem>
            <CheckListItem>{t("childAssentList2")}</CheckListItem>
            <CheckListItem>{t("childAssentList4")}</CheckListItem>
            <CheckListItem>{t("childAssentList5")}</CheckListItem>
            <CheckListItem>{t("childAssentList6")}</CheckListItem>
            <CheckListItem>{t("childAssentList7")}</CheckListItem>
            <CheckListItem>{t("childAssentList8")}</CheckListItem>
            <CheckListItem>{t("childAssentList9")}</CheckListItem>
            <CheckListItem>{t("childAssentList10")}</CheckListItem>
          </CheckList>
          <AlertContainer>{t("childAssentAlert")}</AlertContainer>
          <EmailAlert />
          <ContactUs />
        </Desc>
        <BtnContainer reverse>
          <button className="btn" onClick={handleNext}>
            {t("childAssentAgree")}
          </button>
          <button className="btn" onClick={handleSkipAgreement}>
            {t("childAssentDisagree")}
          </button>
        </BtnContainer>
      </Body>
      <NoAssent
        open={noAssentModal}
        setOpen={setNoAssentModal}
        childName={FirstName(childName)}
        onClickHandler={handleDisagreeNextPage}
      />
      <NoConsentAssent
        open={consentAssentPending}
        setOpen={setConsentAssentPending}
        onClickHandler={handleAgreeNextPage}
      />
      <ConsentPending
        open={consentPending}
        setOpen={setConsentPending}
        onClickHandler={handleAgreeNextPage}
      />
      <SharedData
        open={sharedDataModal}
        setOpen={setSharedDataModal}
        onClickHandler={handleAgreeNextPage}
      />
    </FlowPage>
*/
