import AnalyticsService from "analytics-web";
/**
 * Helper Function that builds a tracking object to build into to.
 * @param type string, an event type
 * @param action string, a description for the action taking place. i.e. if clicking a button describe the button
 * @param page string, optional param, allows to check which page component the track is being done on.
 */

const TrackAnalytics = (type: string, action: string, page: string) => {
  console.log(type, action, page);
  AnalyticsService.track(type, {
    context: "Consent-Assent",
    page,
    actions: action,
    url: window.location.hostname + window.location.pathname,
  });
};


  export default TrackAnalytics;
