import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useUser } from "../../../../context/UserContext/UserContext";
import ConsentCollection from "../../../../modals/ConsentCollection";
import {
  DASHBOARD,
  REVIEW_AGREEMENTS_PHN_CHILD_INFO,
  REVIEW_AGREEMENTS_PHN_PARENT_INFO,
} from "../../../utils/RouteConstants";
import { isBaby } from "../../../../utils/handleChildAge";
import ParentConsentViewManager from "../../../../components/Managers/ParentConsentViewManager";
import Button from "../../../../components/Button";
import Span from "../../../../components/Span";

const ReviewAgreementsConsent = () => {
  const { parent, children } = useUser();
  const history = useHistory();
  const [dataCollectionModal, setDataCollectionModal] = useState(false);

  const { t } = useTranslation();

  const handleNext = () => {
    if (isPhnEmpty(children[0].phn)) {
      history.push(DASHBOARD);
    } else {
      handleRoute();
    }
  };

  const handleRoute = () => {
    if (isBaby(children[0].age)) {
      history.push(REVIEW_AGREEMENTS_PHN_PARENT_INFO);
    } else {
      history.push(REVIEW_AGREEMENTS_PHN_CHILD_INFO);
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const dataCollectionModalHandler = () => {
    setDataCollectionModal(true);
  };

  const isPhnEmpty = (phn: string | null) => {
    return phn === "" || phn === null;
  };

  return (
    <ParentConsentViewManager
      feature_type="REVIEW_AGREEMENT"
      parentName={parent?.name as string}
      button_primary={
        <Button className="btn" onClick={() => handleNext()}>
          {isPhnEmpty(children[0].phn)
            ? `${t("reviewAgreementBtnSecondaryReturnHome")}`
            : `${t("reviewAgreementBtnPrimary")}`}
        </Button>
      }
      button_secondary={
        <Button className="btn" onClick={() => handleGoBack()}>
          {t("reviewAgreementBtnSecondary")}
        </Button>
      }
      button_data_collection={
        <Trans
            components={{
              a: (
                <Span
                  onClick={dataCollectionModalHandler}
                  className="flowPage__links"
                />
              ),
            }}
        >
          {t("parentConsentDesc2")}
        </Trans>
      }
      modals={[
        <ConsentCollection
          open={dataCollectionModal}
          setOpen={setDataCollectionModal}
        />,
      ]}
    />
  );
};

export default ReviewAgreementsConsent;
