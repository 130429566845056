import { useState } from "react";
import { useHistory } from "react-router-dom";
import { updatePhnAgreement } from "../../../../API/Endpoints";
import { CookieStorage } from "../../../../components/services/Storage";
import { useRoute } from "../../../../context/RouteContext/RouteContext";
import { useUser } from "../../../../context/UserContext/UserContext";
import { PhnAgreementType, PhnConsentState } from "../../../../context/UserContext/UserTypes";
import { routeScenarioEnum } from "../../../../hooks/RouteController/useRouteController";
import { useDidUpdateEffect } from "../../../../hooks/utils/useDidUpdateEffect";
import { isThisPhnValid } from "../../../../utils/phnValidation";
import { DASHBOARD } from "../../../utils/RouteConstants";
import Phn from "./Phn";

const PhnChildView = () => {
  const { userDetails, children, parent } = useUser();
  const {  routeScenario } = useRoute();
  const [isValidPhn, setIsValidPhn] = useState(false);

  const history = useHistory();


  // Main Fns - Control onClick Buttons & onModalBtnClick Routing
  const handleOnClickAgree = () => {
   return updatePhnAgreement(
      CookieStorage.get("token") as string,
      handlePhnAgreementState()
    );

  };

  const handleAgreeNextPage = () => {
    history.push(DASHBOARD)

    console.log("leave phn screen, and go to auth landing");
  };

  const handleOnClickDisagree = () => {

    let performers: Array<string>;

    switch (routeScenario) {
      case routeScenarioEnum.i_adult:
        performers = [userDetails.identifier]
        break;
      default:
        performers = [children[0].identifier]
    }
  
    return updatePhnAgreement(
      CookieStorage.get("token") as string,
      {
        performers,
        phn_consent_state: PhnConsentState.REJECTED,
        phn: null
      }
    );

  };

  // Helper Fns

  const handlePhnAgreementState = () => {
    let phn_consent_state: PhnConsentState;
    let performers: Array<string>;

    switch (routeScenario) {
      case routeScenarioEnum.i_adult:
        phn_consent_state = PhnConsentState.CONSENT;
        performers = [userDetails.identifier]
        break;
      default:
        performers = [parent?.identifier as string, children[0].identifier]
        phn_consent_state = PhnConsentState.CONSENT_ASSENT;
    }

    return {
      phn: userDetails.phn as string,
      performers,
      phn_consent_state,
    } as PhnAgreementType;
  };

  const handleDisagreeNextPage = () => {
    history.push(DASHBOARD);

    console.log("leave phn screen, and go to auth landing");
  };

  useDidUpdateEffect(() => {
    setIsValidPhn(isThisPhnValid(userDetails.phn));
  }, [userDetails.phn]);

  return (
    <Phn
      handleOnClickAgree={handleOnClickAgree}
      handleOnClickDisagree={handleOnClickDisagree}
      handleAgreeNextPage={handleAgreeNextPage}
      handleDisagreeNextPage={handleDisagreeNextPage}
      isValidPhn={isValidPhn}
      MainUserChild={userDetails}
    />
  );
};

export default PhnChildView;
