import React, { useEffect, useState } from "react";
import { useUser } from "../context/UserContext/UserContext";
import { UserRelated } from "../context/UserContext/UserTypes";
import { isThisPhnValid } from "../utils/phnValidation";

interface PhnProps {
  name?: string;
  key?: any;
  isChildMainUser?: boolean;
}

export const PhnInput = ({ name, isChildMainUser = false }: PhnProps) => {
  const [value, setValue] = useState<string | null>(null);
  const [phnValid, setPhnValid] = useState(false);

  const { children, setChildren, userDetails } = useUser();

  let child: UserRelated;

  if (isChildMainUser) {
    child = userDetails;
  } else {
    child = children.filter((filteredChild) => {
      return filteredChild.name === name;
    })[0];
  }

  useEffect(() => {
    setValue("");
  }, []);

  useEffect(() => {
    setPhnValid(isThisPhnValid(value));
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value
      .replace(/[^\d0-9]/g, "")
      .replace(/(.{4})/, "$1 ")
      .replace(/(.{8})/, "$1 ");

    setValue(event.target.value);

    updateChildrenwithPHNs(event.target.value);
  };

  const updateChildrenwithPHNs = (phnValue: string) => {
    child.phn = phnValue;
    setChildren(
      children.map((childItem) => {
        if (child.name === childItem.name) {
          childItem.phn = child.phn;
          return childItem;
        } else {
          return childItem;
        }
      })
    );
  };

  const handleBackspace = (event: React.KeyboardEvent<HTMLInputElement>) => {

    if(value === null) {
      return;
    }

    const array = value.split("");

    if (event.key === "Backspace") {
      if (array[array.length - 1] === " ") {
        array.pop();
        setValue(array.join(""));
      }
    }
  };

  return (  (
      <>
        <label htmlFor={`phn-${child.name}`}>{child.name}</label>
        <input
          placeholder="___ ___ ___"
          maxLength={12}
          type="text"
          aria-label={child.name}
          id={`phn-${child.name}`}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => handleBackspace(e)}
          data-testid="FlowPage-PhnInput"
          className={`flowPage__phn ${phnValid ? "green" : ""}`}
          value={value ? value : ""}
        />
      </>
    )
  );
};
