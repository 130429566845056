import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useRoute } from "../../context/RouteContext/RouteContext";
import { UserRelated } from "../../context/UserContext/UserTypes";
import useRouteControl, {
  routeScenarioEnum,
} from "../../hooks/RouteController/useRouteController";
import { AgreementSection } from "../../navigation/pages/LandingPage.wrapper";
import Icon, { IconEnum } from "../Icon";
import Button from "../../components/Button";

interface MissingAgreementProps {
  children?: Array<UserRelated>;
  areAllChildrenNotParticipating?: boolean;
}

const MissingAgreement = ({
  children,
  areAllChildrenNotParticipating,
}: MissingAgreementProps) => {
  const [t] = useTranslation("common");
  const { routeScenario } = useRoute();
  const history = useHistory();

  const [handleRoute] = useRouteControl(history);

  const onClickHandler = () => {
    handleRoute(routeScenario as routeScenarioEnum);
  };



  const renderTitle = () => {
    if (areAllChildrenNotParticipating) {
      return t("homeMissingAgreementTitle-Main");
    }
    if (children?.length) {
      const word = children.length <= 1 ? "child" : "children";
      return t("homeMissingAgreementTitle", { child: word });
    } else {
      return t("homeMissingAgreementTitle-Main");
    }
  };

  return (
    <AgreementSection>
      <Icon
        iconName={IconEnum.research}
        className="homeAuth__icon"
        withIconClass={false}
      />
      <h2 className="homeAuth__missingAgreementTitle">{renderTitle()}</h2>
      <p className="homeAuth__p">{t("homeMissingAgreementDesc1")}</p>
      <p className="homeAuth__p">{t("homeMissingAgreementDesc2")}</p>
      <Button onClick={onClickHandler} className="btn ">
        {t("homeMissingAgreementBtn")}
      </Button>
    </AgreementSection>
  );
};

export default MissingAgreement;
