import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ChildAssentViewManager from "../../../../components/Managers/ChildAssentViewManager";
import { useUser } from "../../../../context/UserContext/UserContext";
import {
  REVIEW_AGREEMENTS_CONSENT,
} from "../../../utils/RouteConstants";
import Button from "../../../../components/Button";

const ReviewAgreementsAssent = () => {
  const { children } = useUser();
  const history = useHistory();
  const { t } = useTranslation();

  const handleNext = () => {
    history.push(REVIEW_AGREEMENTS_CONSENT);
  };

  const handleReturnHome = () => {
   history.goBack();
  };

  const child = children[0];
  return (
    <ChildAssentViewManager
      feature_type="REVIEW_AGREEMENT"
      childName={child.name}
      button_primary={
        <Button className="btn" onClick={() => handleNext()}>
          {t("reviewAgreementBtnPrimary")}
        </Button>
      }
      button_secondary={
        <Button className="btn" onClick={() => handleReturnHome()}>
          {t("reviewAgreementBtnSecondary")}
        </Button>
      }
    />
  );
};

export default ReviewAgreementsAssent;
