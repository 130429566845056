import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import { useRoute } from "../../../context/RouteContext/RouteContext";
import useRouteControl, {
  routeScenarioEnum,
} from "../../../hooks/RouteController/useRouteController";
import { GoToConsent } from "../../routing/Flow/parentLearn.logic";
import FlowPage, {
  Header,
  Body,
  Title,
  Desc,
  BtnContainer,
} from "../FlowPage.wrapper";

const ParentLearn = () => {
  const { t } = useTranslation();
  const { routeScenario } = useRoute();
  const history = useHistory();

  const [handleRoute] = useRouteControl(history);

  const goToLearning = () => {
    handleRoute(routeScenario as routeScenarioEnum);
  };

  const goToConsent = () => {
    GoToConsent(history);
  };


  return (
    <FlowPage pageName={ParentLearn.name}>
      <Header />
      <Body>
        <Title>{t("parentLearnTitle")}</Title>
        <Desc>
          <p className="flowPage__p">{t("parentLearnDesc1")}</p>
          <p className="flowPage__p">{t("parentLearnDesc2")}</p>
        </Desc>
        <BtnContainer reverse>
          <Button className="btn" onClick={goToLearning}>{t("parentLearnNext")}</Button>
          <Button className="btn btn--secondary" onClick={goToConsent}>{t("parentLearnSkip")}</Button>
        </BtnContainer>
      </Body>
    </FlowPage>
  );
};

export default ParentLearn;
