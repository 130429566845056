import { Page, Text, View, Image } from "@react-pdf/renderer";
import { Trans, useTranslation } from "react-i18next";
import FlowPage, {
  ProgressBar,
  Body,
  Desc,
  Title,
  CheckList,
  CheckListItem,
  AlertContainer,
  EmailAlert,
  BtnContainer,
  Header,
  ContactUs,
  AgreementContainer,
  AcceptAlertContainer,
} from "../../navigation/pages/FlowPage.wrapper";
import { ContactUs as ContactUsPDF, CheckListItem as CheckListItemPDF } from "../PDF/PDFDocument";
import { FirstName } from "../../utils/SplitName";
import { IconEnum } from "../Icon";
import { styles } from "../PDF/Styles";
import header from "../../images/flow/parent_consent.png";
import {
  CONSENT_FLOW,
  PDF_DOCUMENT,
  PDF_VIEWER,
  REVIEW_AGREEMENT,
} from "./FeatureTypes";

type ChildConsentFlow = {
  feature_type: CONSENT_FLOW;
  childName: string;
  phnInfoPage?: JSX.Element;
  button_primary: JSX.Element;
  button_secondary: JSX.Element;
  requestLoading: boolean;
  modals: Array<JSX.Element>;
  agreementDate?: string;
};

type ChildPDFViewer = {
  feature_type: PDF_VIEWER;
  childName: string;
  phnInfoPage: JSX.Element;
  button_primary: JSX.Element;
  button_secondary?: JSX.Element;
  requestLoading?: boolean;
  modals?: Array<JSX.Element>;
  agreementDate: string;
};

type ChildPDFDocument = {
  feature_type: PDF_DOCUMENT;
  childName: string;
  phnInfoPage?: JSX.Element;
  button_primary?: JSX.Element;
  button_secondary?: JSX.Element;
  requestLoading?: boolean;
  modals?: Array<JSX.Element>;
  agreementDate: string;
};

type ChildReviewAgreement = {
  feature_type: REVIEW_AGREEMENT;
  childName: string;
  phnInfoPage?: JSX.Element;
  button_primary: JSX.Element;
  button_secondary: JSX.Element;
  requestLoading?: boolean;
  modals?: Array<JSX.Element>;
  agreementDate?: string;
};

type Props =
  | ChildConsentFlow
  | ChildPDFViewer
  | ChildPDFDocument
  | ChildReviewAgreement;

const ChildAssentViewManager = ({
  feature_type,
  modals,
  childName,
  requestLoading,
  button_primary,
  button_secondary,
  agreementDate,
  phnInfoPage,
}: Props) => {
  const { t } = useTranslation();

  const ConsentFlow = () => (
    <FlowPage loading={requestLoading} anchorButtonGroup={false} pageName={ConsentFlow.name}>
      <Header />
      <ProgressBar
        level={3}
        description={t("progressBar3A")}
        icon={IconEnum.document}
      />
      <Body>
        <Desc>
          <Title>
            {t("childAssentTitle", {
              child: FirstName(childName),
            })}
          </Title>
          <p className="flowPage__p flowPage__p--policy">
            {t("childAssentDesc")}
          </p>
          <CheckList>
            <CheckListItem>{t("childAssentList1")}</CheckListItem>
            <CheckListItem>{t("childAssentList2")}</CheckListItem>
            <CheckListItem>{t("childAssentList4")}</CheckListItem>
            <CheckListItem>{t("childAssentList5")}</CheckListItem>
            <CheckListItem>{t("childAssentList6")}</CheckListItem>
            <CheckListItem>{t("childAssentList7")}</CheckListItem>
            <CheckListItem>{t("childAssentList8")}</CheckListItem>
            <CheckListItem>{t("childAssentList9")}</CheckListItem>
            <CheckListItem>{t("childAssentList10")}</CheckListItem>
          </CheckList>
          <AlertContainer>{t("childAssentAlert")}</AlertContainer>
          <EmailAlert />
          <ContactUs />
        </Desc>
        <BtnContainer reverse>
          {button_primary}
          {button_secondary}
        </BtnContainer>
      </Body>
      {modals?.map((modal) => {
        return modal;
      })}
    </FlowPage>
  );

  const PDFDocument = () => {
    const name = childName;
    return (
      <Page wrap={false} size="LETTER" style={styles.page}>
        <View>
          <Image src={header} style={styles.headerImg} />
        </View>
        <View style={styles.body}>
          <Text style={styles.agreementText}>
            <Trans
              components={{
                strong: <Text style={styles.bold} />,
              }}
            >
              {t("agreedOn", { date: agreementDate })}
            </Trans>
          </Text>
          <Text style={styles.agreementText}>
            <Trans
              components={{
                strong: <Text style={styles.bold} />,
              }}
            >
              {t("agreedBy", { user: name })}
            </Trans>
          </Text>
          <Text style={styles.title}>
            {t("PrintReviewTitle", { name: FirstName(name) })}
          </Text>
          <Text style={styles.pPolicy}>{t("childAssentDesc")}</Text>
          <View style={styles.list}>
            <CheckListItemPDF>{t("childAssentList1")}</CheckListItemPDF>
            <CheckListItemPDF>{t("childAssentList2")}</CheckListItemPDF>
            <CheckListItemPDF>{t("childAssentList4")}</CheckListItemPDF>
            <CheckListItemPDF>{t("childAssentList5")}</CheckListItemPDF>
            <CheckListItemPDF>{t("childAssentList6")}</CheckListItemPDF>
            <CheckListItemPDF>{t("childAssentList7")}</CheckListItemPDF>
            <CheckListItemPDF>{t("childAssentList8")}</CheckListItemPDF>
            <CheckListItemPDF>{t("childAssentList9")}</CheckListItemPDF>
            <CheckListItemPDF>{t("childAssentList10")}</CheckListItemPDF>
          </View>
        </View>
        <ContactUsPDF t={t} />
      </Page>
    );
  };
  const PDFViewer = () => {
    const name = childName;
    const date = agreementDate;
    return (
      <FlowPage withOneButtonLayout anchorButtonGroup={false} pageName={PDFViewer.name}>
        <Header />
        <Body>
          <AgreementContainer name={name as string} date={date as string} />
          <Title>
            {t("PrintReviewTitle", {
              name: FirstName(name as string),
            })}
          </Title>
          <p className="flowPage__p flowPage__p--policy">
            {t("childAssentDesc")}
          </p>
          <CheckList>
            <CheckListItem>{t("childAssentList1")}</CheckListItem>
            <CheckListItem>{t("childAssentList2")}</CheckListItem>
            <CheckListItem>{t("childAssentList4")}</CheckListItem>
            <CheckListItem>{t("childAssentList5")}</CheckListItem>
            <CheckListItem>{t("childAssentList6")}</CheckListItem>
            <CheckListItem>{t("childAssentList7")}</CheckListItem>
            <CheckListItem>{t("childAssentList8")}</CheckListItem>
            <CheckListItem>{t("childAssentList9")}</CheckListItem>
            <CheckListItem>{t("childAssentList10")}</CheckListItem>
          </CheckList>
          <AlertContainer>{t("childAssentAlert")}</AlertContainer>
          <EmailAlert />
          <ContactUs />
        </Body>
        {/* 1st page ends */}
        {phnInfoPage}

        <Body>
          <BtnContainer className="pt-0">{button_primary}</BtnContainer>
        </Body>
      </FlowPage>
    );
  };
  const ReviewAgremeent = () => {
    return (
      <FlowPage pageName={ReviewAgremeent.name}>
        <Header />
        <Body>
          <AcceptAlertContainer>
            {t("reviewAgreementAlertText")}
          </AcceptAlertContainer>
          <Desc>
            <Title>
              {t("childAssentTitle", {
                child: FirstName(childName),
              })}
            </Title>
            <p className="flowPage__p flowPage__p--policy">
              {t("childAssentDesc")}
            </p>
            <CheckList>
              <CheckListItem>{t("childAssentList1")}</CheckListItem>
              <CheckListItem>{t("childAssentList2")}</CheckListItem>
              <CheckListItem>{t("childAssentList4")}</CheckListItem>
              <CheckListItem>{t("childAssentList5")}</CheckListItem>
              <CheckListItem>{t("childAssentList6")}</CheckListItem>
              <CheckListItem>{t("childAssentList7")}</CheckListItem>
              <CheckListItem>{t("childAssentList8")}</CheckListItem>
              <CheckListItem>{t("childAssentList9")}</CheckListItem>
              <CheckListItem>{t("childAssentList10")}</CheckListItem>
            </CheckList>
            <AlertContainer>{t("childAssentAlert")}</AlertContainer>
            <EmailAlert />
            <ContactUs />
          </Desc>
          <BtnContainer reverse>
            {button_primary}
            {button_secondary}
          </BtnContainer>
        </Body>
      </FlowPage>
    );
  };
  switch (feature_type) {
    case "CONSENT_FLOW":
      return <ConsentFlow />;
    case "PDF_DOCUMENT":
      return <PDFDocument />;
    case "PDF_VIEWER":
      return <PDFViewer />;
    case "REVIEW_AGREEMENT":
      return <ReviewAgremeent />;
  }
};

export default ChildAssentViewManager;
