import { useTranslation } from "react-i18next";
import FlowPage, { Body, BtnContainer, Desc, Title } from "../../navigation/pages/FlowPage.wrapper";

import errorSearchImage from "../../images/error-search-user.svg";

import { HOME } from "../../navigation/utils/RouteConstants";
import { Button } from "@material-ui/core";


export const GeneralErrorPage = () => {
  const { t } = useTranslation();

  const next = () => {
    window.location.assign(HOME)
  };

  return (
    <FlowPage pageName={GeneralErrorPage.name}>
      <Body>
        <div data-testid="FlowPage-Header" className={` flowPage__headerImgError`}>
          <img src={errorSearchImage} alt="header" />
        </div>

        <>
          <Title textLeft={false}>
            {t("errorGeneralTitle")}
          </Title>

          <Desc textLeft>
            <p className="flowPage__p">
              {t("errorGeneralDesc1")}
            </p>
          </Desc>

          <BtnContainer>
            <Button className="btn btn--primary" onClick={next}>
              {t("errorGeneralBtn")}
            </Button>
          </BtnContainer>
        </>
        
      </Body>
    </FlowPage>
  );
};
