import React from "react";
import banner1 from "../images/landing/home-banner-1.png";
import banner2 from "../images/landing/home-banner-2.png";
import banner3 from "../images/landing/home-banner-3.png";

export interface ResearchHeadlineProps {
  id: string;
  headline: string;
  link: string;
}

interface ResearchCardProps {
  headline: ResearchHeadlineProps;
}

const getImageByHeadlineId = (id: string) => {
  let pathToImage;

  switch (id) {
    case "1":
      pathToImage = banner1;
      break;
    case "2":
      pathToImage = banner2;
      break;
    case "3":
      pathToImage = banner3;
      break;
  }

  return pathToImage;
};

const ResearchCard = ({ headline }: ResearchCardProps) => {
  return (
    <a href={headline.link}>
    <div className="headline">
      <img className="headline__img" src={getImageByHeadlineId(headline.id)} alt="" />
      <div className="headline__banner">
        <p className="headline__text">{headline.headline}</p>
      </div>
    </div>
    </a>
  );
};

export default ResearchCard;
