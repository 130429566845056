import { useEffect, useState } from "react";
import HomeAuthParentController from "../../../components/HomeController/DashboardCardController/ParentController";
import { useTranslation } from "react-i18next";
import LandingPage, {
  Header,
  Section,
  Footer,
  ContactUs,
} from "../LandingPage.wrapper";
import Icon, { IconEnum } from "../../../components/Icon";
import ResearchCard, {
  ResearchHeadlineProps,
} from "../../../components/ResearchCard";
import axios from "axios";
import { BASE_URL } from "../../../App";
import { useUser } from "../../../context/UserContext/UserContext";
import { useUtil } from "../../../context/UtilContext/UtilContext";
import { UserRole } from "../../../context/UserContext/UserTypes";
import HomeAuthChildController from "../../../components/HomeController/DashboardCardController/ChildController";
import { isAdultPatient, isChild } from "../../../utils/handleChildAge";
import AdultPatientView from "../../../components/HomeController/DashboardCardController/AdultPatient/AdultPatientView";
import { AdultPatientCardProvider } from "../../../components/HomeController/DashboardCardController/AdultPatient/AdultPatientCardController";
import { isParent } from "../../../utils/isUser";
import useUserService from "../../../hooks/useUserService";
import { useFetchUser } from "../../../context/UserContext/UserFetchContext";
import { CookieStorage } from "../../../components/services/Storage";
import Spinner from "../../../components/Spinner";
import { useInterceptBackButton } from "../../../hooks/utils/useInterceptBackButton";

const Dashboard = () => {
  const [researchHeadlines, setResearchHeadlines] =
    useState<Array<ResearchHeadlineProps>>();
  const [t] = useTranslation("common");
  const { setMailRefer, userDetails } = useUser();
  const { fullAgreement } = useUtil();
  const {isUserReady, refreshUserDetails } = useFetchUser();

  useUserService();
  useInterceptBackButton({logout: true});

  useEffect(() => {
    refreshUserDetails(CookieStorage.get("token") as string)
  }, [refreshUserDetails])


  const adultPatientFullAgreement =
    isAdultPatient(userDetails.age, userDetails.role) && userDetails.consent;

  const Title = () => {
    if (fullAgreement.length || adultPatientFullAgreement) {
      return ParentOrChildText();
    } else {
      return t("homeAuthTitle-Not");
    }
  };

  const ParentOrChildText = () => {
    if (isParent(userDetails.role)) {
      return t("homeAuthTitle-Parent");
    } else {
      return t("homeAuthTitle-Child");
    }
  };

  const ChildOrParent = () => {
    if (UserRole.PARENT === userDetails.role) {
      return <HomeAuthParentController />;
    } else if (isAdultPatient(userDetails.age, userDetails.role)) {
      return (
        <AdultPatientCardProvider>
          <AdultPatientView />
        </AdultPatientCardProvider>
      );
    } else if (UserRole.PATIENT === userDetails.role) {
      return <HomeAuthChildController />;
    }
  };


  useEffect(() => {
    setMailRefer(false);
  }, [setMailRefer]);

  useEffect(() => {
    let cleanup = false;

    // Use token to fetch a user then redirect user based on the data returned.
    async function fetchData() {
      console.log(`${BASE_URL}/users/researchHeadlines`);
      let res = await axios({
        /*Add localhost URL to fetch local endpoint here here-> */

        url: "./config/config.json",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      if (!cleanup) {
        setResearchHeadlines(res.data.researchHeadlines);
      }
    }

    fetchData();

    return () => {
      cleanup = true;
    };
  }, []);

  const handleResearchSubtext = () => {
    if (isChild(userDetails.age)) {
      return t("homeAuthDesc-Research");
    } else if (isParent(userDetails.role)) {
      return t("homeAuthDesc-Research-parent");
    } else {
      return t("homeAuthDesc-Research-adult")
    }
  };

  return (
    <LandingPage pageName={Dashboard.name}>
      {isUserReady ? (
        <>
          <Header title={Title()} subtitle={""} withLogoutButton={true} />
          <Section oddOrder={true}>{ChildOrParent()}</Section>
          <Section oddOrder={true}>
            <div className="homeAuth__titleWithIcon">
              <Icon iconName={IconEnum.research} withIconClass={false} />
              <h2>{t("homeAuthTitle-Research")}</h2>
            </div>
            <p className="homeAuth__p homeAuth__p--left">{handleResearchSubtext()}</p>

            {researchHeadlines?.map((item) => (
              <ResearchCard key={item.id} headline={item} />
            ))}
          </Section>
          <Section oddOrder={true}>
            <ContactUs />
          </Section>

          <Footer />
        </>
      ) : (
        <Spinner />
      )}
    </LandingPage>
  );
};

export default Dashboard;
