import { useEffect } from "react";
import { useRoute } from "../context/RouteContext/RouteContext";
import { useUser } from "../context/UserContext/UserContext";
import { routeScenarioEnum } from "./RouteController/useRouteController";
import useRouteConditions from "./useRouteConditions";

/*
ParentScenarios Hook
The purpose of this hook is to seperate all related Parent route operations into 1 hook.
We use RouteConditions to set a unique string identifier(routeScenario) to that set path. 
As there's a fork in the path we set a new unique routeScenario string.

Call handleRouteScenario inside a page component to establish a snapshot of User's Route Conditions
Commonly used on a next button, or useEffect to see if there was a branch in the user's conditions

Unique Strings:
d_missingConsent,
d_child,
d_mailChild,
d_baby
*/

const useRouteScenario = () => {
  const { mailRefer, userLoaded } = useUser();
  const { setRouteScenario, setIsRouteScenariosReady, setSkipFlow } = useRoute();
  const [routeConditions] = useRouteConditions();

  useEffect(() => {
    setIsRouteScenariosReady(false);
  }, [setIsRouteScenariosReady])
  

  /**
   * --- PARENT Route Scenarios ---
   */
  const ParentTogetherORMailReferred = () => {
    if (mailRefer) {
      setRouteScenario(routeScenarioEnum.d_mailChild);
    } else {
      setRouteScenario(routeScenarioEnum.d_child);
    }
  };

  const parentMissingConsent = () => {
    setRouteScenario(routeScenarioEnum.d_missingConsent);
  };

  const ParentFlow = () => {
    if (!routeConditions?.isParentDoingFlow) {
      setSkipFlow(true);
    }

    if (routeConditions?.isParentMissingConsent) {
      parentMissingConsent();
    } else {
      ParentTogetherORMailReferred();
    }
  };
  /**
   * --- Baby Route Scenarios ---
   */
  const BabyFlow = () => {
    if (!routeConditions?.isParentDoingFlow) {
      setSkipFlow(true);
    }

    setRouteScenario(routeScenarioEnum.d_baby);
  };

  /**
   * --- CHILD Route Scenarios ---
   */
  const ChildTogetherORMailReferred = () => {
    if (mailRefer) {
      setRouteScenario(routeScenarioEnum.i_mailParent);
    } else {
      setRouteScenario(routeScenarioEnum.i_child);
    }
  };

  const patientMissingAssent = () => {
    setRouteScenario(routeScenarioEnum.i_missingAssent);
  };

  const ChildFlow = () => {
    if (!routeConditions?.isChildDoingFlow) {
      setSkipFlow(true);
    }
    if (routeConditions?.isChildMissingAssent) {
      patientMissingAssent();
    } else {
      ChildTogetherORMailReferred();
    }
  };

  // need to have the ability to skip flow but also maintain routescenario
  //

  /**
   * --- ADULT Route Scenarios ---
   */

  const AdultFlow = () => {
    if (!routeConditions?.isAdultDoingFlow) {
      setSkipFlow(true);
    }
    setRouteScenario(routeScenarioEnum.i_adult);
  };

  const handleRouteScenario = () => {
    if (userLoaded) {
      if (routeConditions?.isParentWithChild) ParentFlow();
      else if (routeConditions?.isParentWithBaby) BabyFlow();
      else if (routeConditions?.isChildWithParent) ChildFlow();
      else if (routeConditions?.isAdult) AdultFlow();

      setIsRouteScenariosReady(true);
    }
  };

  return { handleRouteScenario };
};

export default useRouteScenario;
