import { RouteComponentProps } from "react-router";
import { ABOUT, REDIRECT } from "../../utils/RouteConstants";


export const homePushNext = (history: RouteComponentProps["history"]) => {
  history.push(ABOUT);
};

export const skipHomeUserIsLoggedIn = (history: RouteComponentProps["history"]) => {
  history.push(REDIRECT);
};