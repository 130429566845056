import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import { IconEnum } from "../../../components/Icon";
import { useRoute } from "../../../context/RouteContext/RouteContext";
import useRouteControl, {
  routeScenarioEnum,
} from "../../../hooks/RouteController/useRouteController";

import FlowPage, {
  Header,
  Body,
  Title,
  Desc,
  BtnContainer,
  ProgressBar,
  CaptionContainer,
} from "../FlowPage.wrapper";

const ConsentAssentNeeded = () => {
  const { t } = useTranslation();
  const { routeScenario } = useRoute();
  const history = useHistory();

  const [handleRoute] = useRouteControl(history);

  const handleNext = () => {
    handleRoute(routeScenario as routeScenarioEnum);
  };
  const handleBackBtn = () => {
    history.goBack();
  };

  return (
    <FlowPage pageName={ConsentAssentNeeded.name}>
      <Header />
      <ProgressBar level={2} description={t("progressBar2")} icon={IconEnum.beaker} />
      <Body>
        <Title>{t("caNeededTitle")}</Title>
        <Desc>
        <p className="flowPage__p">{t("caNeededDesc1")}</p>
          <p className="flowPage__p"><Trans>{t("caNeededDesc2")}</Trans></p>
          <p className="flowPage__p">{t("caNeededDesc3")}</p>
        <CaptionContainer>
        <p className="flowPage__caption">{t("caNeededCaption")}</p>
        </CaptionContainer>
        </Desc>
        <BtnContainer reverse>
        <Button className="btn" onClick={handleNext}>{t("seeAgreement")}</Button>
          <Button onClick={handleBackBtn} className="btn btn--secondary">{t("back")}</Button>
        </BtnContainer>
      </Body>
    </FlowPage>
  );
};

export default ConsentAssentNeeded;
