import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import DataCollection from "../../../modals/DataCollection";
import FlowPage, { Body, Title, Desc, BtnContainer, Header, AcceptAlertContainer } from "../FlowPage.wrapper";
import useRouteControl, {
  routeScenarioEnum,
} from "../../../hooks/RouteController/useRouteController";
import { useRoute } from "../../../context/RouteContext/RouteContext";
import { useUser } from "../../../context/UserContext/UserContext";
import {
  goToReviewAgremeents,
} from "../../../navigation/routing/redirectPage.logic";

// TODO: Update this to use handleRoute Hook. HandleRoute will trigger the conditions based on the user logging in.
const AboutPage = () => {
  const [dataCollection, setDataCollection] = useState(false);
  const [t] = useTranslation();
  const history = useHistory();
  const { routeScenario } = useRoute();
  const { userDetails } = useUser();
  const [handleRoute] = useRouteControl(history);
  const [displayAgreement, setDisplayAgreement] = useState(false)
  const hash = history.location.hash.split('consentReview=')[1];

  useEffect(() => { setDisplayAgreement(hash === 'review') }, [hash])

  const handleRedirect = () => {
    if (hash === 'review') goToReviewAgremeents(history, userDetails.userGroup || '');
    else handleRoute(routeScenario as routeScenarioEnum);
  };

  const handleLearnMore = () => {
    setDataCollection(true);
  };

  const handleBack = () => {
    history.goBack();
  };
  return (
    <FlowPage anchorButtonGroup={false} pageName={AboutPage.name}>
      <Header />
      <Body>
        {
          displayAgreement && (
            <AcceptAlertContainer>
              {t("reviewAgreementAlertText")}
            </AcceptAlertContainer>
          )
        }
        <Title>{t("aboutTitle")}</Title>
        <Desc>
          <h2 className="flowPage__subtitle">{t("aboutSubtitle1")}</h2>
          <p className="flowPage__p--last">{t("aboutDesc1")}</p>
          <h2 className="flowPage__subtitle">{t("aboutSubtitle2")}</h2>
          <p className="flowPage__p--last">{t("aboutDesc2")}</p>
          <h2 className="flowPage__subtitle">{t("aboutSubtitle3")}</h2>
          <p className="flowPage__p">{t("aboutDesc3A")}</p>
          <p className="flowPage__p--last">{t("aboutDesc3B")}</p>
          <h2 className="flowPage__subtitle">{t("aboutSubtitle4")}</h2>
          <p className="flowPage__p">{t("aboutDesc4A")}</p>
          <ul className="flowPage__bullet">
            <li className="flowPage__bulletItems">{t("aboutDesc4B-1")}</li>
            <li className="flowPage__bulletItems">{t("aboutDesc4B-2")}</li>
            <li className="flowPage__bulletItems">{t("aboutDesc4B-3")}</li>
          </ul>
          <p className="flowPage__p">{t("aboutDesc4C")}</p>
          <p className="flowPage__p">{t("aboutDesc4D")}</p>
          <Button className="aboutPage__links" onClick={handleLearnMore}>
            {t("aboutDesc4C-Link")}
          </Button>
          <p></p>
          <h2 className="flowPage__subtitle">{t("aboutSubtitle5")}</h2>
          <p className="flowPage__p">{t("aboutDesc5A")}</p>
          <p className="flowPage__p">{t("aboutDesc5B")}</p>
          <p className="flowPage__p">{t("aboutDesc6")}</p>
        </Desc>
        <BtnContainer reverse>
          <Button className="btn" onClick={handleRedirect}>
          {displayAgreement ? t("next") : t("getStarted")}
          </Button>
          <Button className="btn" onClick={handleBack}>
          {displayAgreement ? t("reviewAgreementBtnSecondaryReturnHome") : t("takeMeBack")}
          </Button>
        </BtnContainer>
      </Body>
      <DataCollection open={dataCollection} setOpen={setDataCollection} />
    </FlowPage>
  );
};

export default AboutPage;
