import { RouteComponentProps } from "react-router";
import { RouteTypes } from "../../types/RouteTypes";
import {
  CHILD_FLOW_INTRO, CHILD_LEARN, CHOOSECHILD, FLOW_INTRO, PARENT_LEARN, FLOW_EXPLAINED_ADULT_AND_BABY, ABOUTREVIEW
} from "../../utils/RouteConstants";
import { UserRelated } from "../../../context/UserContext/UserTypes";

let thisChildren: UserRelated[] = [];
export const handleMultiChildInRoute = (children: UserRelated[]) => {
  thisChildren = children;
};

export const aboutController = (
  types: RouteTypes,
  history: RouteComponentProps["history"]
) => {
  const hash = history.location.hash.split('consentReview=')[1];
  if (hash === 'consent') {
    const { d_child, d_missingConsent, d_baby, i_child, i_missingAssent, i_adult } = types;
    if (d_child) {
      const childrenLength = thisChildren.length;
  
      if (childrenLength > 1) {
        history.push(CHOOSECHILD);
      } else {
        history.push(FLOW_INTRO);
      }
    }
    else if (d_missingConsent) history.push(PARENT_LEARN);
    else if (d_baby) history.push(FLOW_EXPLAINED_ADULT_AND_BABY);
    else if (i_child) history.push(CHILD_FLOW_INTRO);
    else if (i_missingAssent) history.push(CHILD_LEARN);
    else if (i_adult) history.push(FLOW_EXPLAINED_ADULT_AND_BABY);
  }
};

export const goToAboutPageReview = (
  history: RouteComponentProps["history"]
) => {
  history.push(ABOUTREVIEW);
};
