import { RouteComponentProps } from "react-router-dom";
import { childAssentController } from "../../navigation/routing/Flow/childAssent.logic";
import { childLearnController } from "../../navigation/routing/Flow/childLearn.logic";
import { chooseChildController } from "../../navigation/routing/Flow/chooseChild.logic";
import { chooseParentController } from "../../navigation/routing/Flow/chooseParent.logic";
import { caExplainedController } from "../../navigation/routing/Flow/explained.logic";
import { caNeededController } from "../../navigation/routing/Flow/needed.logic";
import { parentConsentController } from "../../navigation/routing/Flow/parentConsent.logic";
import { parentLearnController } from "../../navigation/routing/Flow/parentLearn.logic";
import { phnParentController } from "../../navigation/routing/Flow/phnParent.logic";
import { phnChildController } from "../../navigation/routing/Flow/phnChild.logic";
import { aboutController } from "../../navigation/routing/Flow/about.logic";
import { redirectController } from "../../navigation/routing/redirectPage.logic";
import { RouteTypes } from "../../navigation/types/RouteTypes";
import {
  CHOOSECHILD,
  CHOOSE_PARENT,
  FLOW_EXPLAINED,
  FLOW_NEEDED,
  CHILD_ASSENT_PARENT_VIEW,
  PARENT_CONSENT_PARENT_VIEW,
  PHN_PARENT,
  PARENT_LEARN,
  REDIRECT,
  CHILD_LEARN,
  CHILD_ASSENT_CHILD_VIEW,
  PARENT_CONSENT_CHILD_VIEW,
  PHN_CHILD,
  PHN_ADULT,
  DASHBOARD,
  FLOW_EXPLAINED_ADULT_AND_BABY,
  ABOUT
} from "../../navigation/utils/RouteConstants";
import { routeScenarioEnum } from "./useRouteController";
import { caExplainedAdultAndBabyController } from "../../navigation/routing/Flow/explainedAdultAndBaby.logic";

// Used in Buttons, submissions, forms, etc
export const routeLogicByPath = (
  path: string,
  routeScenario: routeScenarioEnum,
  routeTypes: (arg: routeScenarioEnum) => RouteTypes,
  history: RouteComponentProps["history"]
) => {
  const types = routeTypes(routeScenario);
  console.log(path, routeScenario);

  if (path === CHOOSECHILD) chooseChildController(types, history);
  else if (path === CHOOSE_PARENT) chooseParentController(types, history);
  else if (path === FLOW_EXPLAINED) caExplainedController(types, history);
  else if (path === FLOW_EXPLAINED_ADULT_AND_BABY) caExplainedAdultAndBabyController(types, history);
  else if (path === FLOW_NEEDED) caNeededController(types, history);
  else if (path === CHILD_ASSENT_PARENT_VIEW) childAssentController(types, history);
  else if (path === CHILD_ASSENT_CHILD_VIEW) childAssentController(types, history);
  else if (path === PARENT_CONSENT_PARENT_VIEW) parentConsentController(types, history);
  else if (path === PARENT_CONSENT_CHILD_VIEW) parentConsentController(types, history);
  else if (path === PHN_PARENT) phnParentController(types, history);
  else if (path === PHN_ADULT) phnParentController(types, history);
  else if (path === PHN_CHILD) phnChildController(types, history);
  else if (path === PARENT_LEARN) parentLearnController(types, history);
  else if (path === CHILD_LEARN) childLearnController(types, history);
  else if (path === REDIRECT) redirectController(types, history);
  else if (path === DASHBOARD) redirectController(types, history);
  else if (path === ABOUT) aboutController(types, history);

  // TODO: Create a RouteController here.
  // * User path === ABOUT_PAGE and create a new redirectController.
  
};
