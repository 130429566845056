import { UserRole } from "../context/UserContext/UserTypes";

export const isParent = (role: UserRole) => {
    if(role === UserRole.PARENT) {
        return true;
    } else {
        return false
    }
}

export const isPatient = (role: UserRole) => {
    if(role === UserRole.PATIENT) {
        return true;
    } else {
        return false
    }
}