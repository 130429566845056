import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { updatePhnAgreement } from "../../../API/Endpoints";
import Button from "../../../components/Button";
import { CookieStorage } from "../../../components/services/Storage";
import { useRoute } from "../../../context/RouteContext/RouteContext";
import { useUser } from "../../../context/UserContext/UserContext";
import { PhnConsentState } from "../../../context/UserContext/UserTypes";
import useRouteControl from "../../../hooks/RouteController/useRouteController";
import { useLeadingDebounce } from "../../../hooks/utils/useDebounce";
import useRequestSpinner from "../../../hooks/utils/useRequestSpinner";
import PhnNotLinked from "../../../modals/PhnNotLinked";
import { DASHBOARD } from "../../utils/RouteConstants";
import FlowPage, { Header, Body, Title, Desc, BtnContainer, AlertContainer, ContactUs, EmailAlert } from "../FlowPage.wrapper";

const PhnParent = () => {
  const [dontLinkPhnsModal, setDontLinkPhnsModal] = useState(false);

  const { t } = useTranslation();
  const { routeScenario} = useRoute();
  const { parent } = useUser();
  const history = useHistory();

  const [handleRoute] = useRouteControl(history);
  const { handleRequestWithSpinner, requestLoading } = useRequestSpinner();

  const handleOnClickAgree = () => {
    handleRoute(routeScenario);
  };

  const handleOnClickDisagree = () => {

    const promise = updatePhnAgreement(
      CookieStorage.get("token") as string,
      {
        phn: null,
        performers: [parent?.identifier as string],
        phn_consent_state: PhnConsentState.REJECTED,
      }
    )
    handleRequestWithSpinner(promise, () => {
      setDontLinkPhnsModal(true);
    })

  }
    

  const handleDisagreeNextPage = () => {
    // done doing flow, redirect to dashboard
    history.push(DASHBOARD);
  }


  return (
    <FlowPage loading={requestLoading} anchorButtonGroup={false} pageName={PhnParent.name}>
      <Header />
      <Body>
        <Title>{t("phnParentTitle")}</Title>
        <Desc>
          <p className="flowPage__p">{t("phnParentDesc1")}</p>
          <p className="flowPage__p">{t("phnParentDesc2")}</p>
          <p className="flowPage__p">{t("phnParentDesc3")}</p>
          <p className="flowPage__p">{t("phnParentDesc4")}</p>
          <p className="flowPage__p">
            <strong><Trans>{t("phnAdultDesc8")}</Trans></strong>
          </p>
          <ul>
            <li className="flowPage__p flowPage__bulletItems">{t("phnParentDesc6")}</li>
            <li className="flowPage__p flowPage__bulletItems">{t("phnParentDesc7")}</li>
          </ul>
          <ContactUs textLeft />
          <AlertContainer>{t("phnParentAlert")}</AlertContainer>
          <EmailAlert />
        </Desc>
        <BtnContainer reverse>
          <Button className="btn" onClick={useLeadingDebounce(handleOnClickAgree)}>
            {t("phnParentAgree")}
          </Button>
          <Button className="btn" onClick={useLeadingDebounce(handleOnClickDisagree)}>
            {t("phnParentDisagree")}
          </Button>
        </BtnContainer>
      </Body>
      <PhnNotLinked open={dontLinkPhnsModal} setOpen={setDontLinkPhnsModal} onClickHandler={handleDisagreeNextPage} />
    </FlowPage>
  );
};

export default PhnParent;
