import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  updateAgreedBefore,
  updatePhnAgreement,
} from "../../../../API/Endpoints";
import { CookieStorage } from "../../../../components/services/Storage";
import { useRoute } from "../../../../context/RouteContext/RouteContext";
import { useUser } from "../../../../context/UserContext/UserContext";
import {
  PhnAgreementType,
  PhnConsentState,
  UserRelated,
} from "../../../../context/UserContext/UserTypes";
import { routeScenarioEnum } from "../../../../hooks/RouteController/useRouteController";
import { useDidUpdateEffect } from "../../../../hooks/utils/useDidUpdateEffect";
import { isBaby } from "../../../../utils/handleChildAge";
import { isThisPhnValid } from "../../../../utils/phnValidation";
import { DASHBOARD } from "../../../utils/RouteConstants";
import Phn from "./Phn";

const PhnParentView = () => {
  const { userDetails, children, parent } = useUser();
  const { routeScenario } = useRoute();
  const history = useHistory();

  const [isValidPhn, setIsValidPhn] = useState(false);

  // Main Fns - Control onClick Buttons & onModalBtnClick Routing
  const handleOnClickAgree = () => {
    return updatePhnAgreement(
      CookieStorage.get("token") as string,
      handlePhnAgreementState()
    );
  };

  const handleAgreeNextPage = () => {
    history.push(DASHBOARD)
    console.log("leave phn screen, and go to auth landing");
  };

  const handleOnClickDisagree = () => {
    updateAgreedBefore(
      true,
      CookieStorage.get("token") as string,
      userDetails
    );
  
   return updatePhnAgreement(
      CookieStorage.get("token") as string,
      {
        phn: null,
        performers: [parent?.identifier as string],
        phn_consent_state: PhnConsentState.REJECTED,
      }
    );
  };

  const handleDisagreeNextPage = () => {
    history.push(DASHBOARD)
    console.log("leave phn screen, and go to auth landing");
  };

  // Helper Fns

  const handlePhnAgreementState = () => {
    let phn_consent_state: PhnConsentState;
    let performers: Array<string>;

    switch (routeScenario) {
      case routeScenarioEnum.d_mailChild:
        phn_consent_state = PhnConsentState.CONSENT;
        performers = [userDetails.identifier]
        break;
      case routeScenarioEnum.d_missingConsent:
        phn_consent_state = PhnConsentState.CONSENT_ASSENT;
        performers = [userDetails.identifier]
        break;
        // TODO: Add d_baby when that RouteScenario is ready
      default:
        performers = [userDetails.identifier, children[0].identifier]
        phn_consent_state = PhnConsentState.CONSENT_ASSENT;
        break;
    }


    return {
      phn: children[0].phn,
      performers: performers,
      phn_consent_state,
    } as PhnAgreementType;
  };

  useDidUpdateEffect(() => {
    const isAllPHNsValid = () => {
      // return FilterChildrenWithAssent(children).every((child) => isThisPhnValid(child.phn));
      return isThisPhnValid(children[0].phn);
    };

    setIsValidPhn(isAllPHNsValid());
  }, [children]);

  const FilterChildrenWithAssent = (children: UserRelated[]) => {
    return children.filter((child) => {
      if (routeScenario === routeScenarioEnum.d_child) {
        const skipBabyAssent = isBaby(child.age) && !child.assent;
        return skipBabyAssent || child.assent;
      } else {
        return true;
      }
    });
  };

  return (
    <Phn
      handleOnClickAgree={handleOnClickAgree}
      handleOnClickDisagree={handleOnClickDisagree}
      handleAgreeNextPage={handleAgreeNextPage}
      handleDisagreeNextPage={handleDisagreeNextPage}
      filteredChildren={FilterChildrenWithAssent(children)}
      isValidPhn={isValidPhn}
    />
  );
};

export default PhnParentView;
