import { useEffect, useState } from "react";
import { useRoute } from "../context/RouteContext/RouteContext";
import { useUser } from "../context/UserContext/UserContext";
import { useFetchUser } from "../context/UserContext/UserFetchContext";
import { UserRole } from "../context/UserContext/UserTypes";
import { isAdultPatient, isBaby, isChild } from "../utils/handleChildAge";

/*
RouteConditions Util Hook
This hook is used to break down every routing condition with the Parent as the main user
or the Patient as the main user. 
The purpose of this Hook is to minimize large boolean comparisons into 1 easily readable variable.
*/

interface RouteConditionTypes {
  isParentWithChild: boolean;
  isParentDoingFlow: boolean;
  isParentMissingConsent: boolean;
  isParentWithBaby: boolean;
  isChildWithParent: boolean;
  isChildDoingFlow: boolean;
  isChildMissingAssent: boolean;
  isAdult: boolean;
  isAdultDoingFlow: boolean;
}

const useRouteConditions = (): Array<typeof routeConditions> => {
  const { userDetails, children } = useUser();
  const { isFetchSuccessful } = useFetchUser();
  const { setIsRouteConditionsReady, routeScenario } = useRoute();

  const [routeConditions, setRouteConditions] = useState<RouteConditionTypes>();

  useEffect(() => {
    const isParentUser = UserRole.PARENT === userDetails.role;
    const isChildUser = UserRole.PATIENT === userDetails.role && isChild(userDetails.age);

    setRouteConditions({
      isParentWithChild:
        isParentUser &&
        children.every((child) => {
          return child.role === UserRole.PATIENT && isChild(child.age);
        }),

      isParentWithBaby: isParentUser && children[0].role === UserRole.PATIENT && isBaby(children[0].age),

      isParentDoingFlow: isParentUser && (!userDetails.hasAgreedBefore) && children.every((child) => child.consent === false),

      isParentMissingConsent:
        isParentUser &&
        userDetails.related.some((child) => {
          return child.consent === false && child.assent === true;
        }),

      isChildWithParent:
        isChildUser &&
        userDetails.related.some((parent) => {
          return parent.role === UserRole.PARENT;
        }),

      isChildDoingFlow: isChildUser && (!userDetails.hasAgreedBefore) && userDetails.assent === false,

      isChildMissingAssent: isChildUser && userDetails.assent === false && userDetails.consent === true,

      isAdult: isAdultPatient(userDetails.age, userDetails.role),

      isAdultDoingFlow: isAdultPatient(userDetails.age, userDetails.role) && (!userDetails.hasAgreedBefore),
    });
  }, [children, userDetails]);

  useEffect(() => {
    if (routeConditions && isFetchSuccessful) {
      setIsRouteConditionsReady(true);
    }
  }, [isFetchSuccessful, routeConditions, setIsRouteConditionsReady, routeScenario]);

  return [routeConditions];
};

export default useRouteConditions;
