import { useEffect, useState } from "react";
// Routing
import { useHistory } from "react-router-dom";
// Utils
import { Trans, useTranslation } from "react-i18next";
import { useUser } from "../../../context/UserContext/UserContext";
import { useRoute } from "../../../context/RouteContext/RouteContext";
import useRouteControl from "../../../hooks/RouteController/useRouteController";
import FlowPage, { 
  Body, 
  BtnContainer, 
  ChooseChildContainer, 
  Desc, 
  Header, 
  Title 
} from "../FlowPage.wrapper";
import ChooseParentItem from "../../../components/ChooseParentItem";
import { getAllRelatedParents } from "../../../services/FilterParent";
import Button from "../../../components/Button";


const ChooseParent = () => {
  const { t } = useTranslation();
  const { userDetails, parent } = useUser();
  const { routeScenario } = useRoute();
  const history = useHistory();
  const [handleRoute] = useRouteControl(history);

  const [isChoiceEmpty, setIsChoiceEmpty] = useState(false);

  const handleNext = () => {
    handleRoute(routeScenario);
  };

  useEffect(() => {
    if (parent) {
      setIsChoiceEmpty(false);
    } else {
      setIsChoiceEmpty(true);
    }
  }, [parent, setIsChoiceEmpty]);


  const handleBack = () => {
    history.goBack();
  };

  return (
    <>
      <FlowPage pageName={ChooseParent.name}>
        <Header />
        <Body>
        <Title>{t("chooseParentTitle")}</Title>
          <Trans>
            <Desc>
              <p className="flowPage__p--last">
                {t("chooseParentDesc")}
              </p>
            </Desc>
          </Trans>
          <ChooseChildContainer>
            {getAllRelatedParents(userDetails).map((mappedParent) => {
              return (
                <ChooseParentItem
                  key={mappedParent.name}
                  mappedParent={mappedParent}
                />
              );
            })}
          </ChooseChildContainer>

          <BtnContainer reverse>
            <Button
              className={`btn ${isChoiceEmpty ? "btn--disabled" : ""}`}
              disabled={isChoiceEmpty}
              onClick={handleNext}
            >
              {t("next")}
            </Button>
            <Button onClick={handleBack} className="btn btn--secondary">
              {t("back")}
            </Button>
          </BtnContainer>
        </Body>
      </FlowPage>
    </>
  );
};

export default ChooseParent;
