import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { FirstName } from "../../../../utils/SplitName";
import { useUser } from "../../../../context/UserContext/UserContext";
import ConsentCollection from "../../../../modals/ConsentCollection";
import { DASHBOARD, REVIEW_AGREEMENTS_PHN_ADULT_INFO } from "../../../utils/RouteConstants";
import FlowPage, {
  Body,
  Title,
  Desc,
  CheckList,
  CheckListItem,
  AcceptAlertContainer,
  BtnContainer,
  Header,
  ContactUs,
  EmailAlert,
} from "../../FlowPage.wrapper";
import Button from "../../../../components/Button";

const ReviewAgreementsAdultConsent = () => {
  const { userDetails } = useUser();
  const history = useHistory();
  const [dataCollectionModal, setDataCollectionModal] = useState(false);

  const { t } = useTranslation();

  const handleNext = () => {
    if (isPhnEmpty(userDetails.phn)) {
      history.push(DASHBOARD);
    } else {
      history.push(REVIEW_AGREEMENTS_PHN_ADULT_INFO);
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const dataCollectionModalHandler = () => {
    setDataCollectionModal(true);
  };

  const isPhnEmpty = (phn: string | null) => {
    return phn === "" || phn === null;
  };

  return (
    <FlowPage pageName={ReviewAgreementsAdultConsent.name}>
      <Header />
      <Body>
          <AcceptAlertContainer>{t("reviewAgreementAlertText")}</AcceptAlertContainer>
          <Desc>
          <Title>
            {t("adultConsentTitle", {
              adult: FirstName(userDetails.name as string),
            })}
          </Title>
          <p className="flowPage__p flowPage__p--policy">
            {t("adultConsentDesc1")}
          </p>
          <p className="flowPage__p flowPage__p--policy">
            <Trans
              components={{
                a: (
                  <button
                    onClick={dataCollectionModalHandler}
                    className="flowPage__links"
                  />
                ),
              }}
            >
              {t("adultConsentDesc2")}
            </Trans>
          </p>
          <p className="flowPage__p flowPage__p--policy">
            <Trans>{t("adultConsentDesc3")}</Trans>
          </p>

          <CheckList>
            <CheckListItem>{t("adultConsentList1")}</CheckListItem>
            <CheckListItem>{t("adultConsentList2")}</CheckListItem>
            <CheckListItem>{t("parentConsentList3")}</CheckListItem>
            <CheckListItem>{t("adultConsentList4")}</CheckListItem>
            <CheckListItem>{t("parentConsentList5")}</CheckListItem>
            <CheckListItem>{t("parentConsentList7")}</CheckListItem>
            <CheckListItem>{t("parentConsentList8")}</CheckListItem>
            <CheckListItem>{t("parentConsentList9")}</CheckListItem>
            <CheckListItem>{t("parentConsentList10")}</CheckListItem>
          </CheckList>
          <EmailAlert />
          <ContactUs />
        </Desc>
        <BtnContainer reverse>
          <Button className="btn" onClick={() => handleNext()}>
          {isPhnEmpty(userDetails.phn) ? `${t("reviewAgreementBtnSecondaryReturnHome")}` : `${t("reviewAgreementBtnPrimary")}`}
          </Button>
          <Button className="btn" onClick={() => handleGoBack()}>
            {t("reviewAgreementBtnSecondary")}
          </Button>
        </BtnContainer>
      </Body>
      <ConsentCollection open={dataCollectionModal} setOpen={setDataCollectionModal} />
    </FlowPage>
  );
};

export default ReviewAgreementsAdultConsent;
