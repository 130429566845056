import { RouteComponentProps } from "react-router";
import { RouteTypes } from "../../types/RouteTypes";
import {
  HOME,
  PARENT_CONSENT_CHILD_VIEW,
  PARENT_CONSENT_PARENT_VIEW,
  PHN_CHILD,
  REDIRECT,
} from "../../utils/RouteConstants";

export const childAssentController = (
  types: RouteTypes,
  history: RouteComponentProps["history"]
) => {
  if (types.d_child) history.push(PARENT_CONSENT_PARENT_VIEW);
  else if (types.i_child) history.push(PARENT_CONSENT_CHILD_VIEW);
  else if (types.i_missingAssent) history.push(PHN_CHILD);
  else if (types.i_mailParent) history.push(PHN_CHILD);
  else history.push(HOME);
};

export const FinishedAssent = (history: RouteComponentProps["history"]) => {
  history.replace(REDIRECT);
};
